import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartDataSets } from 'chart.js';

function MixedChart(props: Readonly<{
  labels: string[],
  data: {
    line: {
      title: string,
      color: string,
      serie: undefined | Array<number | undefined>,
      type: string,
    },
    bar2: {
      title: string,
      color: string,
      serie: undefined | Array<number | undefined>,
      type: string,
    },
  }
}>) {

  const datasets: ChartDataSets[] = [
    {
      label: props.data.line.title,
      fill: false,
      lineTension: 0.5,
      backgroundColor: props.data.line.color,
      borderColor: props.data.line.color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: props.data.line.color,
      pointBackgroundColor: props.data.line.color,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 10,
      pointHitRadius: 10,
      data: props.data.line.serie,
      type: props.data.line.type,
      yAxisID: 'y-axis-1'
    },
    {
      type: props.data.bar2.type,
      label: props.data.bar2.title,
      data: props.data.bar2.serie,
      fill: false,
      backgroundColor: props.data.bar2.color,
      borderColor: props.data.bar2.color,
      hoverBackgroundColor: '#71B37C',
      hoverBorderColor: '#71B37C',
      yAxisID: 'y-axis-1'
    },
  ];

  return (
    <>
      <Bar
        data={{
          datasets: datasets
        }}
        options={{
          responsive: true,
          tooltips: {
            mode: 'label'
          },
          elements: {
            line: {
              fill: false
            }
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false
                },
                scaleLabel: {
                  display: false,
                },
                labels: props.labels,
                // tslint:disable-next-line: no-any
              } as any
            ],
            yAxes: [
              {
                type: 'linear',
                display: true,
                ticks: {
                  suggestedMin: 0
                },
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                  display: false
                },
                scaleLabel: {
                  display: true,
                  labelString: '%'
                },
                labels: {
                  show: true
                }
                // tslint:disable-next-line: no-any
              } as any,
            ]
          }
        }}
      />
    </>
  );

}

export default MixedChart;
