import React, { useReducer, useState, Suspense, useEffect, CSSProperties } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { compare } from 'compare-versions';
import 'bulma/css/bulma.min.css';
import './App.css';

import eppClient from './utilities/epp-client';
import * as logger from "./utilities/logger";

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import NavBar from './components/Navbar';
import HomeView from './components/HomeView';
import Login, { getClientConfiguration } from './components/Login';
import Settings from './components/Settings';
import Official from './components/Official';
import Alarms, { AlarmsState } from './components/Alarms';
import FirstInstaller from './components/FirstInstaller';
import { getResetTimes } from './components/TimeRange';
import * as actionTypes from './Actions/Actions';
import VechileManagement from './components/vechileManagement';

// Reducers and contexts
import RouteContext from './contexts/RouteContext';
import * as authenticationReducer from './reducers/authenticated/authenticatedReducer';
import * as filterSelectionsReducer from './reducers/filterSelections';
import timerangeReducer from './reducers/timerange/Reducer';
import distanceReducer from './reducers/distance/reducer';
import initialDistanceState from './reducers/distance/initialState';
import initialFleetUtilityState from './reducers/fleetUtility/initialState';
import fleetUtilityReducer from './reducers/fleetUtility/fleetUtility';
import { authenticatedFilteredAxiosGet, constructCancelObject } from './utilities/generic';
import { constructArrayOfFilterSelections } from './components/FilterSelectors';
import { UAParser } from 'ua-parser-js';
import HomeViewMobile from './components/HomeViewMobile';

let eppClientInstance: ReturnType<typeof eppClient> | undefined;
let loaderCount = 0;

function App() {

    const [updateRequired, setUpdateRequired] = useState<boolean>(false);

    const parser = new UAParser();
    const uaParserResult = parser.getResult();
    const [isMobileViewState, setIsMobileViewState] = useState(uaParserResult.device.type === "tablet" || uaParserResult.device.type === "mobile" ? true : false);

    const { i18n } = useTranslation();

    // Initiate useReducer -hooks
    const [authState, authenticatedDispatch] = useReducer(authenticationReducer.reducer, authenticationReducer.initialState);
    const [filterSelections, filterSelectionsDispatch] = useReducer(filterSelectionsReducer.reducer, filterSelectionsReducer.initialState);
    const [timerangeReduction, timerangeDispatch] = useReducer(timerangeReducer, { startDate: new Date(), endDate: undefined });
    const [alarmsTimerangeReduction, alarmsTimerangeDispatch] = useReducer(timerangeReducer, { startDate: new Date(), endDate: undefined });
    const [distanceReduction, distanceReductionDispatch] = useReducer(distanceReducer, initialDistanceState);
    const [fleetUtilityReduction, fleetUtilityDispatch] = useReducer(fleetUtilityReducer, initialFleetUtilityState);
    const [filtersVisibility, setFiltersVisibility] = useState(!authState.groups.installer && !authState.groups.installerManager);

    const [alarms, setAlarms] = useState<AlarmsState>({
        alarms: [],
        updatedTimestamp: new Date()
    });

    useEffect(
        () => {
            getAlarms();
        },
        constructArrayOfFilterSelections(filterSelections).concat([
            authState.isAuthenticated,
            alarmsTimerangeReduction.startDate,
            alarmsTimerangeReduction.endDate,
        ])
    );

    const [cancelObject] = useState(constructCancelObject());

    async function getAlarms() {
        if (authState.groups.admin || authState.groups.rd) {
            try {
                if (alarmsTimerangeReduction.startDate) {
                    const startDate = alarmsTimerangeReduction.startDate.toISOString();
                    const endDate = alarmsTimerangeReduction.endDate ? alarmsTimerangeReduction.endDate.toISOString() : '';
                    const faultsResponse = await authenticatedFilteredAxiosGet(`/v10/alarms/${startDate}/${endDate}`, authState.isAuthenticated, filterSelections, cancelObject);
                    const alarmsFromDb = faultsResponse.data || [];

                    setAlarms({
                        alarms: alarmsFromDb,
                        updatedTimestamp: new Date(),
                    });
                }
            } catch (err) {
                if (!err.isCancel) {
                    logger.err(err.message || err);
                }
            }
        }
    }

    const [showBgImage, setShowBgImage] = useState(false);

    const [loading, setLoading] = useState(false);

    const [reloadFiltersId, setReloadFiltersId] = useState(0);

    function increaseLoaderCount() {
        loaderCount += 1;
        if (loaderCount === 1) {
            setLoading(true);
        }
    }

    function decreaseLoaderCount() {
        if (loaderCount > 0) {
            loaderCount -= 1;
            if (loaderCount === 0) {
                setLoading(false);
            }
        }
    }

    Axios.interceptors.request.use(config => {
        increaseLoaderCount();
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    function checkExpectedClientMinimumVersionHeader(expectedClientMinimumVersion: string | undefined) {
        if (expectedClientMinimumVersion && process.env.REACT_APP_VERSION && compare(expectedClientMinimumVersion, process.env.REACT_APP_VERSION, ">")) {
            setUpdateRequired(true);
        }
    }

    const expectedClientMinimumVersionHeader = "x-procare-client-minimum-version";

    Axios.interceptors.response.use(response => {
        checkExpectedClientMinimumVersionHeader(response.headers[expectedClientMinimumVersionHeader]);
        decreaseLoaderCount();
        return response;
    }, function (error) {
        checkExpectedClientMinimumVersionHeader(error.response?.headers[expectedClientMinimumVersionHeader]);
        decreaseLoaderCount();
        return Promise.reject(error);
    });

    const [state, setState] = useState(
        {
            currentUser: {} as { language: string }
        }
    );

    async function logoutHandler() {
        try {
            await Axios.get('/v10/logout?redirectUrl=/');
        } catch (err) {
            // No-op
        } finally {
            // TODO investigate on how to clear values to ensure clean login after logout
            // authenticatedDispatch({ type: actionTypes.LOGOUT });
            // const obj = { ...bgImage };
            // obj.showBgImage = true;
            // setBgImage(obj);
            // props.history.push('/');
            window.location.href = "/";
        }
    }

    useEffect(() => {
        if (!authState.isAuthenticated) {

            getClientConfiguration(authenticatedDispatch).catch(() => { /* No-op */ });
            setShowBgImage(true);
            if (eppClientInstance) {
                eppClientInstance.shutdown();
            }

        } else {

            eppClientInstance = eppClient({eppMapScriptUrl: authState.eppMapScriptUrl });
            eppClientInstance.init(
                () => {
                    const ec = eppClientInstance;
                    if (ec && (authState.groups.admin || authState.groups.rd) && authState.eppRulesToListen) {
                        authState.eppRulesToListen.forEach(eppRuleToListen => {
                            ec.listenAlarm(
                                eppRuleToListen,
                                alarmEvent => {
                                    setAlarms(oldState => {
                                        return {
                                            alarms: [alarmEvent, ...oldState.alarms],
                                            updatedTimestamp: new Date(),
                                        };
                                    });
                                },
                                ackEvent => {
                                    setAlarms(oldState => {
                                        for (const oldEvent of oldState.alarms) {
                                            if (oldEvent.eventId === ackEvent.eventId) {
                                                oldEvent.ackTime = new Date(ackEvent.ackTime).toISOString();
                                                oldEvent.ackUserId = ackEvent.ackUserId;
                                                break;
                                            }
                                        }
                                        return {
                                            alarms: [...oldState.alarms],
                                            updatedTimestamp: new Date(),
                                        };
                                    });
                                }
                            );
                        });
                    }
                },
                () => {
                    setUpdateRequired(true);
                },
            );

            const { startDate, endDate} = getResetTimes(true);
            timerangeDispatch({
                type: actionTypes.SET_TIMERANGE,
                startDate,
                endDate,
            });
            const { startDate: alarmsDefaultStartDate } = getResetTimes(false);
            alarmsTimerangeDispatch({
                type: actionTypes.SET_TIMERANGE,
                startDate: alarmsDefaultStartDate,
                endDate: undefined
            });

            setShowBgImage(false);
            i18n.changeLanguage(state.currentUser.language);

        }
    }, [authState.isAuthenticated]);

    function handleLangFormChange(event: { target: { value: string; }; }) {
        const obj = { ...state };
        obj.currentUser.language = event.target.value;
        setState(obj);
        i18n.changeLanguage(state.currentUser.language);
    }

    function triggerFiltersRefresh() {
        setReloadFiltersId(new Date().getTime());
    }

    function changeToDesktopOrMobileView() {
        setIsMobileViewState(!isMobileViewState);
    }

    const mainStyle: CSSProperties = {
        backgroundImage: 'url("/proventia_procare_drive_log_in_bg_052019_low_reso.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
        padding: '20px'
    };

    return (
        <div>
            {!authState.isAuthenticated &&
                <AppHeader
                    handleLangFormChange={(e) => handleLangFormChange(e)}
                    currentUser={state.currentUser}
                    updateRequired={updateRequired}
                />
            }
            <div
                style={
                    showBgImage ? mainStyle
                         : { backgroundColor: '#BBD3E5' }}>
                {(isMobileViewState && authState.isAuthenticated) &&
                <div className={"mobileContent"}>
                        {
                        <RouteContext.Provider value={{
                                            eppClientInstance: eppClientInstance,
                                            authenticatedDispatch: authenticatedDispatch,
                                            filterSelectionsDispatch: filterSelectionsDispatch,
                                            timerangeDispatch: timerangeDispatch,
                                            alarmsTimerangeDispatch: alarmsTimerangeDispatch,
                                            distanceReductionDispatch: distanceReductionDispatch,
                                            fleetUtilityDispatch: fleetUtilityDispatch,
                                            distanceReduction: distanceReduction
                                        }}>
                            <HomeViewMobile
                                authState={authState}
                                filterSelections={filterSelections}
                                startDate={timerangeReduction.startDate}
                                endDate={timerangeReduction.endDate}
                                changeToDesktopOrMobileView={changeToDesktopOrMobileView}
                                logoutHandler={logoutHandler}
                                />
                        </RouteContext.Provider>
                        }
                    </div>
                }
                {!isMobileViewState &&
                    <section
                        className="section is-full">
                        <div className="container">
                            <div className="columns is-full">
                            <div className="column is-full is-fullheight is-mobile">
                                    {authState.isAuthenticated &&
                                        <RouteContext.Provider value={{
                                            eppClientInstance: eppClientInstance,
                                            authenticatedDispatch: authenticatedDispatch,
                                            filterSelectionsDispatch: filterSelectionsDispatch,
                                            timerangeDispatch: timerangeDispatch,
                                            alarmsTimerangeDispatch: alarmsTimerangeDispatch,
                                            distanceReductionDispatch: distanceReductionDispatch,
                                            fleetUtilityDispatch: fleetUtilityDispatch,
                                            distanceReduction: distanceReduction
                                        }}>
                                            <NavBar
                                                updateRequired={updateRequired}
                                                authState={authState}
                                                filterSelections={filterSelections}
                                                logoutHandler={logoutHandler}
                                                loading={loading}
                                                startDate={timerangeReduction.startDate}
                                                endDate={timerangeReduction.endDate}
                                                alarmStartDate={alarmsTimerangeReduction.startDate}
                                                alarmEndDate={alarmsTimerangeReduction.endDate}
                                                newAlarmCount={alarms.alarms.filter(a => !a.ackTime).length}
                                                filtersVisibility={filtersVisibility}
                                                setFiltersVisibility={setFiltersVisibility}
                                                reloadFiltersId={reloadFiltersId}
                                            />
                                            <Switch>
                                                {!authState.groups.official && !(authState.groups.installer || authState.groups.installerManager) &&
                                                    <Route exact path='/' render={() => (
                                                        <Suspense fallback={<progress className="progress is-large is-info" max="100"></progress>}>
                                                            <HomeView
                                                                startDate={timerangeReduction.startDate}
                                                                endDate={timerangeReduction.endDate}
                                                                distanceReduction={distanceReduction}
                                                                vehicleCount={fleetUtilityReduction.vehicleCount}
                                                                authState={authState}
                                                                filterSelections={filterSelections}
                                                                increaseLoaderCount={increaseLoaderCount}
                                                                decreaseLoaderCount={decreaseLoaderCount}
                                                            />
                                                        </Suspense>
                                                    )} />
                                                }
                                                {authState.groups.official &&
                                                    <Route exact path='/' render={() => (
                                                        <Official authState={authState}/>
                                                    )} />
                                                }
                                                {(authState.groups.rd || authState.groups.admin) &&
                                                    <Route exact path='/reports' render={() => (
                                                        <Official authState={authState}/>
                                                    )} />
                                                }
                                                {(authState.groups.rd || authState.groups.admin) &&
                                                    <Route exact path='/alarms' render={() => (
                                                        <Alarms
                                                            authState={authState}
                                                            alarms={alarms}
                                                            setAlarms={setAlarms}
                                                        />
                                                    )} />
                                                }
                                                { (authState.groups.installer || authState.groups.installerManager) &&
                                                    <Route exact path='/' render={() => (
                                                        <FirstInstaller
                                                            authState={authState}
                                                            filterSelections={filterSelections}
                                                            setFiltersVisibility={setFiltersVisibility}
                                                        />
                                                    )} />
                                                }
                                                { (authState.groups.admin || authState.groups.rd) &&
                                                    <Route exact path='/installations' render={() => (
                                                        <FirstInstaller
                                                            authState={authState}
                                                            filterSelections={filterSelections}
                                                            setFiltersVisibility={setFiltersVisibility}
                                                        />
                                                    )} />
                                                }
                                                <Route exact path='/settings' render={() => (
                                                    authState.userName &&
                                                    <Settings
                                                        groups={authState.groups}
                                                        filterSelections={filterSelections}
                                                        loggedInUser={authState.userName}
                                                        onDataUpdated={triggerFiltersRefresh}
                                                    />
                                                )} />
                                                {(authState.groups.rd || authState.groups.admin || authState.groups.expert) &&
                                                    <Route
                                                        exact
                                                        path='/vehicles'
                                                        render={() => (
                                                            <div className="box">
                                                                <VechileManagement
                                                                    authState={authState}
                                                                    filterSelections={filterSelections}
                                                                    setFiltersVisibility={setFiltersVisibility}
                                                                    onDataUpdated={triggerFiltersRefresh}
                                                                />
                                                            </div>
                                                        )} />
                                                }
                                            </Switch>
                                        </RouteContext.Provider>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {!authState.isAuthenticating && !authState.isAuthenticated &&
                    <div className="columns is-mobile is-centered">
                        <div className={!isMobileViewState ? "column is-one-third desktop-login" : "column" }>
                            <Login
                                authenticatedDispatch={authenticatedDispatch}
                            />
                        </div>
                    </div>
                }
            </div>
            { !isMobileViewState &&
                <AppFooter
                    authState={authState}
                    packageJsonClientVersion={process.env.REACT_APP_VERSION || ''}
                />
            }
        </div>
    );
}

export default withRouter(App);
