import React, { CSSProperties } from 'react';

function HomeViewSection(
    props: Readonly<{
        child: JSX.Element
        columnsStyle?: CSSProperties | undefined,
        backgroundStyle?: CSSProperties | undefined,
        contentStyle?: CSSProperties | undefined,
    }>,
    ref: ((instance: HTMLDivElement) => void) | React.RefObject<HTMLDivElement> | null | undefined,
) {
    // <div className="box" style={props.columnsStyle}>
    return (
        <div ref={ref} className="columns" style={props.columnsStyle}>
            <div className="column is-full">
                    <div className="box" style={props.backgroundStyle}>
                        <div className="media-content">
                            <div className="content" style={props.contentStyle}>
                                {props.child}
                            </div>
                        </div>
                    </div>

            </div>
        </div>
    );
}

export default React.forwardRef(HomeViewSection);
