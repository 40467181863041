import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import arrowLeft from '../resources/left_arrow.png';
import arrowRight from '../resources/right_arrow.png';
import zoomIn from '../resources/zoomIn.png';
import zoomOut from '../resources/zoomOut.png';
import { getDateFormat, getTimeFormat } from '../utilities/generic';

const menuControlStyle = { display: "inline-block", marginRight: 10 };
export const timeRangeMinDate = new Date("2000-01-01");
export const timeRangeMaxDate = new Date();
timeRangeMaxDate.setDate(timeRangeMaxDate.getDate() + 180);

function TimeRange(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    setDirty?: (bool: boolean) => void,
    hourlyPrecision: boolean,
    submitHandler: (startDate: Date, endDate: Date | undefined) => void
}>,
) {

    const { t } = useTranslation();

    const timeFormat = getTimeFormat(!props.hourlyPrecision);
    const dateFormat = getDateFormat(props.hourlyPrecision ? 'datetime' : 'datetimeWithSeconds', 'dateFnsFormat');

    const [state, setState] = useState<{
        startDate: Date,
        startDateFromProps: Date | undefined,
        endDate: Date | undefined,
        endDateFromProps: Date | undefined,
        outDated: boolean,
    }>(
        {
            startDate: new Date(),
            startDateFromProps: undefined as Date | undefined,
            endDate: new Date(),
            endDateFromProps: undefined as Date | undefined,
            outDated: false
        }
    );

    if (props.startDate && (state.startDateFromProps !== props.startDate || state.endDateFromProps !== props.endDate)) {
        let newStartDate = props.startDate;
        let newEndDate = props.endDate;

        if (props.hourlyPrecision && newEndDate) {
            newStartDate = setDateOnHourlyPrecision(newStartDate);
            newEndDate = setDateOnHourlyPrecision(newEndDate, true);
        }

        setState({
            startDate: newStartDate,
            startDateFromProps: props.startDate,
            endDate: newEndDate,
            endDateFromProps: props.endDate,
            outDated: false,
        });
    }

    function handleEndDateChange(date: Date | null) {
        let d = date ? date : undefined;
        if (props.hourlyPrecision && d) {
            d = setDateOnHourlyPrecision(d, true);
        }
        const obj = { ...state };
        obj.endDate = d;
        obj.outDated = true;
        setState(obj);
        if (props.setDirty) {
            props.setDirty(true);
        }
    }

    function handleStartDateChange(date: Date | null) {
        let d = date ? date : getResetTimes(props.hourlyPrecision).startDate;
        if (props.hourlyPrecision) {
            d = setDateOnHourlyPrecision(d);
        }
        const obj = { ...state };
        obj.startDate = d;
        obj.outDated = true;
        setState(obj);
        if (props.setDirty) {
            props.setDirty(true);
        }
    }

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        props.submitHandler(state.startDate, state.endDate);
        const obj = { ...state };
        obj.outDated = false;
        setState(obj);
    }

    type QuickSelectIntervals = 'hour' | 'day' | 'week' | 'month';

    function quickSetStart(timeSpan: QuickSelectIntervals) {
        const newStartDate = moment(state.endDate).subtract(1, timeSpan).toDate();
        if (newStartDate > timeRangeMinDate) {
            handleStartDateChange(newStartDate);
        }
    }

    function quickSetEnd(timeSpan: QuickSelectIntervals) {
        const newEndDate = moment(state.startDate).add(1, timeSpan).toDate();
        if (newEndDate < timeRangeMaxDate) {
            handleEndDateChange(newEndDate);
        }
    }

    function resetTime() {

        const { startDate, endDate } = getResetTimes(props.hourlyPrecision);

        setState({
            startDate,
            startDateFromProps: state.startDateFromProps,
            endDate,
            endDateFromProps: state.endDateFromProps,
            outDated: false,
        });

    }

    function quickSetTimeRange(selection: 'zoomOut' | 'zoomIn') {
        const { newStartDate, newEndDate } = calculateZoomTimeRange(state.startDate, state.endDate, selection, props.hourlyPrecision);
        if (newStartDate > timeRangeMinDate && newEndDate < timeRangeMaxDate) {
            setState({
                startDate: newStartDate,
                startDateFromProps: state.startDateFromProps,
                endDate: newEndDate,
                endDateFromProps: state.endDateFromProps,
                outDated: false,
            });
        }
    }

    function pan(multiplier: number) {
        const diff = moment(state.endDate).diff(state.startDate) * multiplier;
        const newStartDate = moment(state.startDate).add(diff).toDate();
        const newEndDate = moment(state.endDate).add(diff).toDate();
        if (newStartDate > timeRangeMinDate && newEndDate < timeRangeMaxDate) {
            setState({
                startDate: newStartDate,
                startDateFromProps: state.startDateFromProps,
                endDate: newEndDate,
                endDateFromProps: state.endDateFromProps,
                outDated: false,
            });
        }

    }

    return (
        <div>
            <form onSubmit={(event) => handleSubmit(event)}>
                <button style={menuControlStyle} onClick={() => pan(-1)}>
                    <img className="icon is-small" src={arrowLeft} alt={t('<')} />
                </button>
                <div style={menuControlStyle}>
                    <DatePicker
                        showTimeSelect
                        timeFormat={timeFormat}
                        timeIntervals={60}
                        minDate={timeRangeMinDate}
                        maxDate={state.endDate}
                        dateFormat={dateFormat}
                        selected={state.startDate}
                        onChange={handleStartDateChange}
                        name="startDate"
                    >
                        <div>
                            <button
                                onClick={() => quickSetStart('hour')}
                            >{t('Hour')}</button>
                            <button
                                onClick={() => quickSetStart('day')}
                            >{t('Day')}</button>
                            <button
                                onClick={() => quickSetStart('week')}
                            >{t('Week')}</button>
                            <button
                                onClick={() => quickSetStart('month')}
                            >{t('Month')}</button>
                            &nbsp;from end
                        </div>
                    </DatePicker>
                </div>
                <div style={menuControlStyle}>
                    -
                </div>
                <div style={menuControlStyle}>
                    <DatePicker
                        placeholderText={t('Now')}
                        showTimeSelect
                        timeFormat={timeFormat}
                        timeIntervals={60}
                        minDate={state.startDate}
                        maxDate={timeRangeMaxDate}
                        dateFormat={dateFormat}
                        selected={state.endDate}
                        onChange={handleEndDateChange}
                        name="endDate"
                    >
                        <div>
                            <button
                                onClick={() => quickSetEnd('hour')}
                            >{t('Hour')}</button>
                            <button
                                onClick={() => quickSetEnd('day')}
                            >{t('Day')}</button>
                            <button
                                onClick={() => quickSetEnd('week')}
                            >{t('Week')}</button>
                            <button
                                onClick={() => quickSetEnd('month')}
                            >{t('Month')}</button>
                            &nbsp;from start
                        </div>
                    </DatePicker>
                </div>
                <button style={menuControlStyle} onClick={() => pan(1)}>
                    <img className="icon is-small" src={arrowRight} alt={t('>')} />
                </button>
                <button style={menuControlStyle} onClick={() => quickSetTimeRange('zoomOut')}>
                    <img className="" src={zoomOut} alt='zoomOut' />
                </button>
                <button style={menuControlStyle} onClick={() => quickSetTimeRange('zoomIn')}>
                    <img className="" src={zoomIn} alt='zoomIn' />
                </button>
                <button style={menuControlStyle} onClick={() => resetTime()}>
                    {t('Reset time')}
                </button>
                <div style={menuControlStyle} className="is-pulled-right">
                    <input
                        type="submit"
                        className="button is-small is-rounded"
                        value={t('Submit') as string}
                        style={{ backgroundColor: '#015B9F', color: 'white' }}
                    />
                </div>
            </form>
            {(!props.setDirty && state.outDated) &&
                <p style={{ color: 'red' }}>{t('Please click "Submit" to update charts according to time selection.')}</p>
            }
        </div>
    );
}

export default TimeRange;

const defaultRangeDays = 7;

export function calculateZoomTimeRange(
    startDate: Date,
    endDate: Date | undefined,
    selection: 'zoomOut' | 'zoomIn',
    hourlyPrecision: boolean,
    zoomFactor = 2.0,
) {
    const rangeWidth = moment(endDate).diff(moment(startDate));
    const newRangeWidth = rangeWidth * (selection === 'zoomOut' ? zoomFactor : 1.0 / zoomFactor);
    const centerTime = moment(startDate).add(rangeWidth / 2, 'milliseconds');
    let newStartDate = moment(centerTime).subtract(newRangeWidth / 2, 'milliseconds').toDate();
    let newEndDate = moment(centerTime).add((newRangeWidth / 2), 'milliseconds').toDate();
    const newDuratiom = moment(newEndDate).diff(moment(newStartDate));
    if (hourlyPrecision) {
        newStartDate = setDateOnHourlyPrecision(newStartDate);
        newEndDate = setDateOnHourlyPrecision(newEndDate, true);
        if (newDuratiom < 60 * 60 * 1000) { // One hour
            newStartDate = startDate;
            newEndDate = endDate || new Date();
        }
    } else {
        if (newDuratiom < 1000) { // One second
            newStartDate = startDate;
            newEndDate = endDate || new Date();
        }
    }
    return { newStartDate, newEndDate };
}

export function getResetTimes(hourlyPrecision: boolean) {
    let startDate = new Date();
    let endDate = new Date();

    startDate.setDate(startDate.getDate() - defaultRangeDays);

    if (hourlyPrecision) {
        startDate = setDateOnHourlyPrecision(startDate);
        endDate = setDateOnHourlyPrecision(endDate, true);
    }
    return { startDate, endDate };
}

function setDateOnHourlyPrecision(date: Date, up = false) {
    if (date.getMinutes() || date.getSeconds() || date.getMilliseconds()) {
        date = moment(date).add(1, 'hour').toDate();
    }
    return moment(date).startOf('hour').toDate();
}
