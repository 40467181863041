import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import * as logger from "../utilities/logger";
import HomeViewSection from './HomeViewSection';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { authenticatedFilteredAxiosGet, constructCancelObject, groupBy, getSectionBackgroundColor, formatDate, useSelectionAutoScroll } from '../utilities/generic';
import * as actionTypes from '../Actions/Actions';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import RouteContext from '../contexts/RouteContext';
import { FilterSelections } from '../reducers/filterSelections';
import ScaleSelectButtons, { ScaleOptions, generateTimestamps, formatTimestamps } from './ScaleSelectButtons';
import SectionPanel, { StateState } from './SectionPanel';
import FaultTableSection from './FaultTableSection';
import { constructFaultSelectionQueryParam, constructFaultTypeSelectionQueryParam } from '../dal/FaultTableHook';

interface FaultCountArrayItem {
    n: number;
    vehicleCount: number;
}
interface FaultArrayItem extends FaultCountArrayItem {
    description: string;
}

interface FaultArrayItemByTime extends FaultArrayItem {
    startTimestamp: string;
}

interface TopVehicle {
    vehicleIdAndDescription: string;
    description: string;
    n: number;
}

type BarHeightByOptions = 'faultType' | 'topVehicles' | 'time';

function FaultChartSection(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    authState: AuthState,
    filterSelections: FilterSelections,
    selectedVehicleIds: number[] | undefined,
    onSelectionFaultCodeTimestamp: (timestamp: Date | undefined) => void,
}>) {

    const { t } = useTranslation();

    const context = useContext(RouteContext);

    const [scale, setScale] = useState<ScaleOptions>("day");

    const [xAxisLabels, setXAxisLabels] = useState<string[]>();
    const [xAxisIds, setXAxisIds] = useState<Array<string | number | moment.Moment>>();

    type DatasetsArray = Array<{
        label: string,
        data: number[],
        faultCounts: number[],
        vehicleCounts: number[],
        backgroundColor: string | string[],
        minBarLength?: number,
    }>;

    type VehicleArray = Array<{
        id: number,
        description: string,
        bodyManufacturerName: string,
        operatorName: string,
        n: number,
        lastFaultTime: string,
        faultActive: boolean,
    }>;

    const [datasets, setDatasets] = useState<DatasetsArray>();
    const [vehicles, setVehicles] = useState<VehicleArray>();

    const [mode, setMode] = useState<BarHeightByOptions>('faultType');
    const [showFaultCodes, setShowFaultCodes] = useState<boolean>(false);
    const [fixedFaultTypeSelection, setFixedFaultTypeSelection] = useState<string>();
    const [fixedFaultCodeSelection, setFixedFaultCodeSelection] = useState<string>();

    const propertiesDepList = [
        props.startDate,
        props.endDate,
        props.authState.isAuthenticated,
    ];

    const faultTypeSelectionQueryParam = showFaultCodes ? constructFaultTypeSelectionQueryParam(fixedFaultTypeSelection) : '';

    useEffect(
        () => { getFaults(); },
        constructArrayOfFilterSelections(props.filterSelections).concat(propertiesDepList).concat([
            mode,
            scale,
            showFaultCodes,
            faultTypeSelectionQueryParam,
        ])
    );

    useEffect(
        () => { getVehicles(); },
        constructArrayOfFilterSelections(props.filterSelections, false).concat(propertiesDepList).concat([
            mode,
            scale,
            showFaultCodes,
            fixedFaultTypeSelection,
            fixedFaultCodeSelection,
        ])
    );

    useEffect(
        () => {
            applyFixedSelectionAndSetDatasets(fixedFaultTypeSelection, fixedFaultCodeSelection, datasets, xAxisIds);
        },
        [
            fixedFaultTypeSelection,
            fixedFaultCodeSelection,
        ]
    );

    useEffect(
        () => {
            if (!showFaultCodes) {
                setFixedFaultCodeSelection(undefined);
            }
        },
        [
            showFaultCodes,
        ]
    );

    const [showLegend, setShowLegend] = useState<boolean>(true);
    const showLegendDefaultValueLimit = 12;

    const [getFaultsCancelObject] = useState(constructCancelObject());
    const [getVehiclesCancelObject] = useState(constructCancelObject());
    const [faultLoadingState, setFaultLoadingState] = useState<StateState>("ok");
    const [vehiclesLoadingState, setVehiclesLoadingState] = useState<StateState>("ok");

    async function getFaults() {
        try {
            setFaultLoadingState("busy");
            if (props.startDate && props.endDate) {
                let newDatasets: DatasetsArray;
                let newXAxisIds;
                if (mode === 'faultType') {
                    const apiResponse = await authenticatedFilteredAxiosGet(`/v10/faults/${props.startDate.toISOString()}/${props.endDate.toISOString()}?groupByTime=false&faultTypeGroups=${!showFaultCodes}`, props.authState.isAuthenticated, props.filterSelections, getFaultsCancelObject);
                    const responseData: FaultArrayItem[] = apiResponse.data || [];
                    // const groupedByFaultDescription = groupBy(responseData, 'description');
                    const descriptions = responseData.map(x => x.description);
                    setXAxisLabels(descriptions);
                    newXAxisIds = descriptions;
                    const faultCounts = responseData.map(f => f.n);
                    const vehicleCounts = responseData.map(f => f.vehicleCount);
                    newDatasets = [{
                        label: '',
                        data: showFaultCodes ? faultCounts : vehicleCounts,
                        faultCounts: faultCounts,
                        vehicleCounts: vehicleCounts,
                        backgroundColor: descriptions.map(description => getColor(description)),
                        minBarLength: 10,
                    }];
                    setShowFaultCodes(false);
                    setFixedFaultCodeSelection(undefined);
                } else if (mode === 'time') {
                    const apiResponse = await authenticatedFilteredAxiosGet(`/v10/faults/${props.startDate.toISOString()}/${props.endDate.toISOString()}/${scale}?groupByTime=true&faultTypeGroups=${!showFaultCodes}&${faultTypeSelectionQueryParam}`, props.authState.isAuthenticated, props.filterSelections, getFaultsCancelObject);
                    const responseData: FaultArrayItemByTime[] = apiResponse.data || [];
                    const groupedByFaultDescription = groupBy(responseData, 'description');
                    const startTimestamps = generateTimestamps({ startDate: props.startDate, endDate: props.endDate, scale });
                    setXAxisLabels(formatTimestamps({ startTimestamps, scale }));
                    newXAxisIds = startTimestamps;
                    newDatasets = Object.entries(groupedByFaultDescription).map(
                        ([faultDescription, faultsOfOneDescription], i) => {
                            if (faultsOfOneDescription.length > 500) {
                                throw new Error("Internal error: Server returned too much data to render to screen");
                            }
                            const faultsOfOneDescriptionGroupedByTime = groupBy(faultsOfOneDescription, 'startTimestamp');
                            const counts = startTimestamps.map(ts => ts.toISOString()).map(isoDateStr => faultsOfOneDescriptionGroupedByTime[isoDateStr] ? faultsOfOneDescriptionGroupedByTime[isoDateStr][0] : { n: 0, vehicleCount: 0 });
                            const faultCounts = counts.map(f => f.n);
                            const vehicleCounts = counts.map(f => f.vehicleCount);
                            return {
                                label: faultDescription,
                                data: showFaultCodes ? faultCounts : vehicleCounts,
                                faultCounts: faultCounts,
                                vehicleCounts: vehicleCounts,
                                backgroundColor: getColor(faultDescription),
                            };
                        }
                    );
                } else if (mode === 'topVehicles') {
                    const vehicleLimit = 10;
                    const apiResponse = await authenticatedFilteredAxiosGet(`/v10/faultsOfTopVehicles/${props.startDate.toISOString()}/${props.endDate.toISOString()}/${vehicleLimit}?faultTypeGroups=${!showFaultCodes}&${faultTypeSelectionQueryParam}`, props.authState.isAuthenticated, props.filterSelections, getFaultsCancelObject);
                    const responseData: TopVehicle[] = apiResponse.data || [];
                    const groupedByFaultDescription = groupBy(responseData, 'description');
                    const vehicleIdAndDescriptions = responseData.map(v => v.vehicleIdAndDescription).filter((v, i, arr) => i === 0 || v !== arr[i - 1]);
                    const parsedVehicleIdAndDescriptions = vehicleIdAndDescriptions.map(v => {
                        const indexOfFirstSeparator = v.indexOf('-');
                        return {
                            id: parseInt(v.substring(0, indexOfFirstSeparator)),
                            description: v.substring(indexOfFirstSeparator + 1),
                        };
                    });
                    setXAxisLabels(parsedVehicleIdAndDescriptions.map(x => x.description));
                    newXAxisIds = parsedVehicleIdAndDescriptions.map(x => x.id);
                    newDatasets = Object.entries(groupedByFaultDescription).map(
                        ([faultDescription, faultsOfOneDescription], i) => {
                            if (faultsOfOneDescription.length > 500) { // TODO: Ensure in server-side that this doesn't happen
                                throw new Error("Internal error: Server returned too much data to render to screen");
                            }
                            const faultsOfOneDescriptionGroupedByVehicle = groupBy(faultsOfOneDescription, 'vehicleIdAndDescription');
                            const faultCounts = vehicleIdAndDescriptions.map(vehicleIdAndDescription =>
                                faultsOfOneDescriptionGroupedByVehicle[vehicleIdAndDescription] ?
                                faultsOfOneDescriptionGroupedByVehicle[vehicleIdAndDescription][0].n :
                                0);
                            return {
                                label: faultDescription,
                                data: showFaultCodes ? faultCounts : faultCounts.map(fc => fc > 0 ? 1 : 0),
                                faultCounts: faultCounts,
                                vehicleCounts: Array(faultCounts.length).fill(1),
                                backgroundColor: getColor(faultDescription),
                            };
                        }
                    );
                } else {
                    throw new Error("Internal error, unknown mode: " + mode);
                }
                setShowLegend(mode !== 'faultType' && newDatasets.length < showLegendDefaultValueLimit);
                applyFixedSelectionAndSetDatasets(fixedFaultTypeSelection, fixedFaultCodeSelection, newDatasets, newXAxisIds);
            }
            setFaultLoadingState("ok");
        } catch (err) {
            if (!err.isCancel) {
                setFaultLoadingState("error");
                logger.err(err.message || err);
            }
        }
    }

    async function getVehicles() {
        try {
            setVehiclesLoadingState("busy");
            if (props.startDate && props.endDate) {
                const apiResponse = await authenticatedFilteredAxiosGet(
                    `/v10/vehiclesWithFaults/${props.startDate.toISOString()}/${props.endDate.toISOString()}?${constructFaultSelectionQueryParam(fixedFaultTypeSelection, fixedFaultCodeSelection)}`,
                    props.authState.isAuthenticated,
                    props.filterSelections,
                    getVehiclesCancelObject,
                    true
                );
                setVehicles(apiResponse.data || []);
            }
            setVehiclesLoadingState("ok");
        } catch (err) {
            if (!err.isCancel) {
                setVehiclesLoadingState("error");
                logger.err(err.message || err);
            }
        }
    }

    function applyFixedSelectionAndSetDatasets(
        selectedFaultTypeDescription: string | undefined,
        selectedFaultCodeDescription: string | undefined,
        newDatasets: typeof datasets,
        newXAxisIds: typeof xAxisIds
    ) {
        if (newDatasets && newXAxisIds) {
            setXAxisIds(newXAxisIds);
            if (mode === 'faultType') {
                setDatasets(newDatasets.map(dataset => ({...dataset })).map(newDataset => {
                    const descriptions = newXAxisIds as string[];
                    newDataset.backgroundColor = descriptions.map(description =>
                        typeof selectedFaultTypeDescription === 'undefined' || description === selectedFaultTypeDescription ? getColor(description) : "gray"
                    );
                    return newDataset;
                }));
            } else if (mode === 'time' || mode === 'topVehicles') {
                const selectedLabel = showFaultCodes ? selectedFaultCodeDescription : selectedFaultTypeDescription;
                setDatasets(newDatasets.map(dataset => ({...dataset })).map(newDataset => {
                    newDataset.backgroundColor =
                        typeof selectedLabel === 'undefined' || newDataset.label === selectedLabel ? getColor(newDataset.label) : "gray";
                    return newDataset;
                }));
            } else {
                throw new Error("Internal error, unknown mode: " + mode);
            }
        }
    }

    function handleGraphClick(e) {
        if (e && datasets && xAxisIds) {
            const [ clickedElement ] = e;
            if (clickedElement && typeof clickedElement._datasetIndex === 'number' && typeof clickedElement._index === 'number') {
                if (mode === 'faultType') {
                    const descriptions = xAxisIds as string[];
                    const selectedDescription = descriptions[clickedElement._index];
                    setFixedFaultTypeSelection(selectedDescription);
                    setFixedFaultCodeSelection(undefined);
                } else if (mode === 'time' || mode === 'topVehicles') {
                    const selectedLabel = datasets[clickedElement._datasetIndex].label;
                    if (showFaultCodes) {
                        setFixedFaultCodeSelection(selectedLabel);
                    } else {
                        setFixedFaultTypeSelection(selectedLabel);
                        setFixedFaultCodeSelection(undefined);
                    }
                } else {
                    throw new Error("Internal error, unknown mode: " + mode);
                }
            }
        }
        return false;
    }

    function handleLegendClick(e, legendItem) {
        if (legendItem && datasets && xAxisIds) {
            if (typeof legendItem.datasetIndex === 'number') {
                if (mode === 'faultType') {
                    // Not currently supported
                } else if (mode === 'time' || mode === 'topVehicles') {
                    const selectedLabel = legendItem.text;
                    if (showFaultCodes) {
                        setFixedFaultCodeSelection(selectedLabel);
                    } else {
                        setFixedFaultTypeSelection(selectedLabel);
                        setFixedFaultCodeSelection(undefined);
                    }
                } else {
                    throw new Error("Internal error, unknown mode: " + mode);
                }
            }
        }
        return false;
    }

    const fixedVehicleSelections = props.filterSelections.fixedVehiclesSelection ? props.filterSelections.fixedVehiclesSelection.map(v => v.id) : undefined;

    function isSelected(vehicle) {
        return fixedVehicleSelections && fixedVehicleSelections.some(sv => sv === vehicle.id);
    }

    function shouldShowOperatorColumn() {
        return props.authState.groups.admin || props.authState.groups.rd || props.authState.groups.regionalEngineer;
    }

    const cellStyle: CSSProperties = {textAlign: 'left', verticalAlign: 'middle'};

    const { scrollableDivRef, tableBodyRef, selectedRowRef } = useSelectionAutoScroll([
        props.filterSelections.fixedVehiclesSelection
    ]);

    return (<HomeViewSection
            backgroundStyle={{position: 'relative'}}
        child={
            <>
            <div>
                <div className="columns">
                    <div className="column">
                        <strong>{t('Faults')}</strong>
                    </div>
                </div>
                <div className="columns" style={{position: "relative"}}>
                    <div className="column is-full">
                        <div className="column is-12 is-paddingless is-centered">
                            <div className="column is-mobile is-centered" style={{minHeight: '60px'}}>
                                <button
                                    onClick={e => {
                                        setMode('faultType');
                                        e.preventDefault();
                                    }}
                                    className={mode === "faultType" ? "button primary is-focused" : "button primary"}
                                >{t('By Fault Type')}</button>
                                <button
                                    onClick={e => {
                                        setMode('topVehicles');
                                        e.preventDefault();
                                    }}
                                    className={mode === "topVehicles" ? "button primary is-focused" : "button primary"}
                                >{t('By Top Vehicles')}</button>
                                <button
                                    style={{ marginRight: "12px" }}
                                    onClick={e => {
                                        setMode('time');
                                        e.preventDefault();
                                    }}
                                    className={mode === "time" ? "button primary is-focused" : "button primary"}
                                >{t('By Time')}</button>
                                { (mode === "time") && <ScaleSelectButtons
                                    selectedScale={scale}
                                    onScaleSelect={setScale}
                                    disabledButtons={false}
                                />}
                                { mode !== 'faultType' &&
                                    <div style={{ float: 'right', padding: "5px 12px" }}>
                                        <input
                                            id="showFaultCodesCheckbox"
                                            type="checkbox"
                                            checked={showFaultCodes}
                                            onChange={newValue => setShowFaultCodes(newValue.target.checked)}
                                        />
                                        &nbsp;
                                        <label htmlFor="showFaultCodesCheckbox">{t('Show Fault Codes')}</label>
                                        <br/>
                                        <span style={{ padding: "5px 2px", marginTop: "5px" }}>
                                            <input
                                                id="showLegendCheckbox"
                                                type="checkbox"
                                                checked={showLegend}
                                                onChange={newValue => setShowLegend(newValue.target.checked)}
                                            />
                                            &nbsp;
                                            <label htmlFor="showLegendCheckbox">{t('Show Legend')}</label>
                                        </span>
                                    </div>
                                }
                                <div style={{ padding: "5px 2px", marginTop: "5px" }}>
                                    { (fixedFaultTypeSelection || fixedFaultCodeSelection) && (
                                        <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                                            {t('Selected') + ':'}
                                        </span>
                                    ) }
                                    { fixedFaultTypeSelection &&
                                    <>
                                        <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{fixedFaultTypeSelection}</span>
                                        &nbsp;&nbsp;
                                        <button className="delete" aria-label="close" onClick={e => {
                                            e.preventDefault();
                                            setFixedFaultTypeSelection(undefined);
                                        }}></button>
                                    </>
                                    }
                                    { fixedFaultCodeSelection &&
                                        <>
                                            <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{fixedFaultCodeSelection}</span>
                                            &nbsp;&nbsp;
                                            <button className="delete" aria-label="close" onClick={e => {
                                                e.preventDefault();
                                                setFixedFaultCodeSelection(undefined);
                                            }}></button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "30vh", position: "relative", backgroundColor: getSectionBackgroundColor(faultLoadingState === "error", faultLoadingState === "busy") }}>
                            <SectionPanel
                                state={faultLoadingState}
                                child={
                                    Array.isArray(xAxisLabels) && xAxisLabels.length > 0 ?
                                    <Bar
                                        // tslint:disable-next-line: no-any
                                        // ref={chartjsRef as any}
                                        data={{
                                            datasets: datasets
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            // tslint:disable-next-line: no-any
                                            hover: {mode: null} as any,
                                            scales: {
                                                xAxes: [{
                                                    stacked: true,
                                                    labels: xAxisLabels,
                                                    // tslint:disable-next-line: no-any
                                                } as any],
                                                yAxes: [
                                                    {
                                                        stacked: true,
                                                        ticks: {
                                                            beginAtZero: true,
                                                            precision: 0,
                                                            // tslint:disable-next-line: no-any
                                                        } as any,
                                                        type: 'linear',
                                                        id: 'faultCount',
                                                        scaleLabel: {
                                                            display: true,
                                                            labelString: t('Count')
                                                        }
                                                    },
                                                ]
                                            },
                                            legend: {
                                                display: mode !== 'faultType' && showLegend,
                                                onClick: handleLegendClick,
                                            },
                                            tooltips: {
                                                callbacks: {
                                                    label: function (tooltipItem, data) {
                                                        if (datasets && typeof tooltipItem.datasetIndex === 'number' && typeof tooltipItem.index === 'number') {
                                                            const ds = datasets[tooltipItem.datasetIndex];
                                                            const faultCount = ds.faultCounts[tooltipItem.index];
                                                            const vehicleCount = ds.vehicleCounts[tooltipItem.index];
                                                            return (ds.label ? ds.label + ", " : "") +
                                                                (
                                                                    typeof faultCount !== 'undefined' && typeof vehicleCount !== 'undefined' ?
                                                                    t('Count') + ": " + faultCount + ", " + t('Number of Vehicles') + ": " + vehicleCount  :
                                                                    ""
                                                                );
                                                        } else {
                                                            return '';
                                                        }
                                                    },
                                                },
                                            }
                                        }}
                                        getElementAtEvent={handleGraphClick}
                                    />
                                    :
                                    <div className="column is-12 is-paddingless is-centered">
                                        {t('No Fault Codes for the selected time range')}
                                    </div>
                                    }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'relative',
                    maxHeight: '300px', overflow: 'auto', marginTop: "5px",
                    backgroundColor: getSectionBackgroundColor(vehiclesLoadingState === "error", vehiclesLoadingState === "busy")
                }}
                ref={scrollableDivRef}
            >
                {
                    vehicles && vehicles.length > 0 &&
                    <SectionPanel
                        state={vehiclesLoadingState}
                        child={
                            <table className="table is-hoverable is-fullwidth is-narrow is-bordered sticky" style={{background: 'inherit'}}>
                                <thead>
                                    <tr>
                                        <th>{t('Vehicle ID')}</th>
                                        <th>{t('Chassis')}</th>
                                        {shouldShowOperatorColumn() &&
                                            <th>{t('Bus Operator')}</th>
                                        }
                                        <th>{t('Faults')}</th>
                                        <th>{t('Last')}</th>
                                        <th>{t('Active fault codes')}</th>
                                    </tr>
                                </thead>
                                <tbody ref={tableBodyRef}>
                                {
                                    vehicles.map((vehicle, i) => {
                                        const selected = isSelected(vehicle);
                                        return (
                                            <tr
                                                key={i}
                                                className={ selected ? "is-selected" : undefined }
                                                ref={elem => {
                                                    if (selected) {
                                                        selectedRowRef(elem);
                                                    }
                                                }}
                                            >
                                                <td style={cellStyle}>
                                                    <p><button className="islink"
                                                        onClick={() => {
                                                            if (context) {
                                                                setMode('time');
                                                                setShowFaultCodes(true);
                                                                context.filterSelectionsDispatch(
                                                                    {
                                                                        type: actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION,
                                                                        fixedVehiclesSelection: [ { id: vehicle.id } ]
                                                                    }
                                                                );
                                                            }
                                                            return false;
                                                        }
                                                    }>
                                                        {vehicle.description}
                                                    </button></p>
                                                </td>
                                                <td>{vehicle.bodyManufacturerName}</td>
                                                {shouldShowOperatorColumn() &&
                                                    <td>{vehicle.operatorName}</td>
                                                }
                                                <td>{vehicle.n}</td>
                                                <td>{vehicle.lastFaultTime ? formatDate(vehicle.lastFaultTime, "datetimeWithSeconds") : undefined}</td>
                                                <td>{vehicle.faultActive ? t('Yes') : t('No')}</td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        }
                    />
                }
            </div>
            {
                props.selectedVehicleIds && props.selectedVehicleIds.length === 1 &&
                <>
                    <div style={{ marginTop: "10px" }}></div>
                    <FaultTableSection
                        isAuthenticated={props.authState.isAuthenticated}
                        vehicle={props.selectedVehicleIds[0]}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        fixedFaultTypeSelection={fixedFaultTypeSelection}
                        fixedFaultCodelection={fixedFaultCodeSelection}
                        onSelectionFaultCodeTimestamp={props.onSelectionFaultCodeTimestamp}
                    />
                </>
            }
            </>
        }
    />);
}

export default FaultChartSection;

function getStringHash(s: string) {
    let hash = 0;
    let char;
    if (s.length === 0) {
        return hash;
    }
    for (let i = 0; i < s.length; i++) {
        char = s.charCodeAt(i);
        // tslint:disable-next-line: no-bitwise
        hash = ((hash << 5) & 0xfffffff) - hash + char;
        // tslint:disable-next-line:no-bitwise
        hash = hash & 0xfffffff; // Convert to 32bit integer
    }
    return hash;
}

const minimumShadeValue = 100;
const numberOfColorShadesPerColorComponent = 6.0;
const shadeValueStep = Math.floor((255 - minimumShadeValue) / numberOfColorShadesPerColorComponent);

function getColor(s: string) {
    // If using 6 shades for red, green and blue we get 6*6*6 = 216 different colors. That should be enough for Fault codes.
    const x = getStringHash(s);
    const redShade = minimumShadeValue + shadeValueStep * (Math.floor(x / numberOfColorShadesPerColorComponent / numberOfColorShadesPerColorComponent) % numberOfColorShadesPerColorComponent);
    const greenShade = minimumShadeValue + shadeValueStep * (Math.floor(x / numberOfColorShadesPerColorComponent) % numberOfColorShadesPerColorComponent);
    const blueShade = minimumShadeValue + shadeValueStep * (x % numberOfColorShadesPerColorComponent);
    return '#' + redShade.toString(16).padStart(2, '0') + greenShade.toString(16).padStart(2, '0') + blueShade.toString(16).padStart(2, '0');
}
