import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from "./NavBar.module.css";
import { useTranslation } from 'react-i18next';

// Resources
import BusIcon from '../resources/bus-articulated-front.png';
// import Earth from '../resources/map_white.png';
import Logout from '../resources/logout_white.png';
// import Graphs from '../resources/chart-bar-white.png';
import Reports from '../resources/clipboard-arrow-down-outline-white.png';
import AlarmsIcon from '../resources/alarms-white.png';
import Settings from '../resources/settings-white.png';
import Home from '../resources/home_white.png';
import FilterSelectors from './FilterSelectors';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { FilterSelections } from '../reducers/filterSelections';
import { onHamburgerMenuClick } from '../utilities/menu-helper';

function NavBar(props: Readonly<{
    updateRequired: boolean,
    authState: AuthState,
    filterSelections: FilterSelections,
    startDate: Date | undefined,
    endDate: Date | undefined,
    alarmStartDate: Date | undefined,
    alarmEndDate: Date | undefined,
    loading: boolean,
    logoutHandler: () => Promise<void>,
    newAlarmCount: number,
    filtersVisibility: boolean,
    setFiltersVisibility: (visibility: boolean) => void,
    reloadFiltersId: number,
}>) {

    const { t } = useTranslation();

    function isInstallerButNotAdmin() {
        return (props.authState.groups.installer  || props.authState.groups.installerManager) && !props.authState.groups.admin;
    }

    function shouldShowFilters() {
        if (!props.filtersVisibility) {
            return false;
        }
        return !isInstallerButNotAdmin();
    }

    function shouldShowVehiclesView() {
        return props.authState.groups.rd || props.authState.groups.admin || props.authState.groups.executive || props.authState.groups.expert;
    }

    function shouldShowInstallationsView() {
        // installer has a messy way to show it as 'home' instead, dunno about install manager
        return props.authState.groups.rd || props.authState.groups.admin;
    }

    function shouldShowReportsView() {
        return props.authState.groups.rd || props.authState.groups.admin;
    }

    function shouldShowAlarmsView() {
        return props.authState.groups.rd || props.authState.groups.admin;
    }

    const [showHomeViewTimeRangeSelector, setShowHomeViewTimeRangeSelector] = useState(true);

    const rootPath = '/';
    const vehiclesPath = '/vehicles';
    const installationsPath = '/installations';
    const reportsPath = '/reports';
    const alarmsPath = '/alarms';
    const settingsPath = '/settings';

    const location = useLocation();

    useEffect(
        () => {
            switch (location.pathname) {
                case rootPath:
                    if (props.authState.groups.installer || props.authState.groups.installerManager) {
                        props.setFiltersVisibility(false);
                        setShowHomeViewTimeRangeSelector(false);
                    } else {
                        props.setFiltersVisibility(true);
                        setShowHomeViewTimeRangeSelector(true);
                    }
                    break;
                case vehiclesPath:
                    props.setFiltersVisibility(true);
                    setShowHomeViewTimeRangeSelector(true);
                    /*
                    TODO: Relates to issue #324: Setting showHomeViewTimeRangeSelector = false; shows the time range selectors dedicated for Alarms,
                    so instead of removing that from FilterSelectors component and doing the thing below, code must be refactored more:
                    setShowHomeViewTimeRangeSelector(true);
                    */
                    break;
                case reportsPath:
                    props.setFiltersVisibility(false);
                    setShowHomeViewTimeRangeSelector(false);
                    break;
                case installationsPath:
                    props.setFiltersVisibility(false);
                    setShowHomeViewTimeRangeSelector(false);
                    break;
                case alarmsPath:
                    props.setFiltersVisibility(true);
                    setShowHomeViewTimeRangeSelector(false);
                    break;
                case settingsPath:
                    props.setFiltersVisibility(false);
                    setShowHomeViewTimeRangeSelector(false);
                    break;
                default:
                    throw new Error("Internal error");
            }
        },
        [ location.pathname ]
    );

    return (
        <>
            { props.authState.isAuthenticated &&
                <nav className="navbar is-light is-fixed-top"
                    role="navigation"
                    aria-label="main navigation"
                    style={{ backgroundColor: '#015B9F' }}
                >
                    <section className="container">
                        {props.loading &&
                            <div className="loader-wrapper" style={{position: 'absolute', top: '25px', left: '50%'}}>
                                <div className="loader is-loading"></div>
                            </div>
                        }
                        <div className="navbar-brand">
                        <div className="spacer" style={{width: '16px'}}>&nbsp;</div>
                            <NavLink exact to={rootPath} className="navbar-item" style={{padding: '0px', margin: '0px'}}>
                                <div style={{backgroundColor: 'rgb(255,157,0)', paddingTop: '8px', paddingRight: '20px', paddingBottom: '5px', paddingLeft: '10px',
                                    borderTop: 'rgb(255,188,64) 2px solid', borderBottom: 'rgb(208,150,0) 2px solid'}}>
                                    <h2 style={{color: 'color: rgb(0,57,118)', lineHeight: '16px', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Fira Sans, sans-serif'}}>
                                        Proventia<br />PROCARE™ Drive
                                    </h2>
                                    <h3 style={{color: '#FFFFFF', fontSize: '8px', fontFamily: 'Fira Sans, sans-serif', fontWeight: 'bold', marginTop: '5px'}}>
                                        NO<sub>x</sub> emissions monitoring system
                                    </h3>
                                </div>
                            </NavLink>
                            <span
                                className="nav-toggle navbar-burger has-text-white"
                                onClick={onHamburgerMenuClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </div>
                        <div className="navbar-menu" style={{backgroundColor: '#015B9F' }}>
                            <div className="navbar-start">
                                <NavLink exact to={rootPath} activeClassName={styles.current} className="navbar-item" >
                                    <div className="columns is-multiline">
                                        <div className="column is-12 is-paddingless">
                                            <div className="is-pulled-left" style={{marginRight: "5px", marginBottom: '-5px'}}><img className={styles.icon} src={Home} alt="" /></div>
                                            <p className={styles.icon}>{t('Home')}</p>
                                        </div>
                                    </div>
                                </NavLink>
                                { shouldShowVehiclesView() &&
                                    <NavLink exact to={vehiclesPath} activeClassName={styles.current} className="navbar-item" >
                                        <div className="columns is-multiline">
                                            <div className="column is-12 is-paddingless">
                                                <div className="is-pulled-left" style={{marginRight: "5px", marginBottom: '-5px'}}><img className={styles.icon} src={BusIcon} alt="" /></div>
                                                <p className={styles.icon}>{t('Vehicles')}</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                }
                                { shouldShowInstallationsView() &&
                                    <NavLink exact to={installationsPath} activeClassName={styles.current} className="navbar-item has-text-white" >
                                        <div className="columns is-multiline">
                                            <div className="column is-12 is-paddingless">
                                                <div className="is-pulled-left" style={{marginRight: "5px", marginBottom: '-5px'}}><img className={styles.icon} src={BusIcon} alt="" /></div>
                                                <p className={styles.icon}>{t('Installations')}</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                }
                                { shouldShowReportsView() &&
                                    <NavLink exact to={reportsPath} activeClassName={styles.current} className="navbar-item" >
                                        <div className="columns is-multiline">
                                            <div className="column is-12 is-paddingless">
                                                <div className="is-pulled-left" style={{marginRight: "5px", marginBottom: '-5px'}}><img className={styles.icon} src={Reports} alt="" /></div>
                                                <p className={styles.icon}>{t('Reports')}</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                }
                                { shouldShowAlarmsView() &&
                                    <NavLink exact to={alarmsPath} activeClassName={styles.current} className="navbar-item" >
                                        <div className="columns is-multiline">
                                            <div className="column is-12 is-paddingless" >
                                                <div className="is-pulled-left" style={{marginRight: "5px", marginBottom: '-5px'}}>
                                                    <img className={styles.icon} src={AlarmsIcon} alt="" />
                                                    {
                                                        props.newAlarmCount > 0 &&
                                                        <div
                                                            className={styles.icon + ' ' + styles.notificationCountPumping}
                                                            style={{
                                                                background: 'red',
                                                                position: 'absolute',
                                                                right: 5,
                                                                top: 5,
                                                                padding: 5,
                                                            }}>
                                                                {props.newAlarmCount}
                                                        </div>
                                                    }
                                                </div>
                                                <p className={styles.icon}>{t('Alarms')}</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                }
                                <NavLink exact to={settingsPath} activeClassName={styles.current} className="navbar-item" >
                                    <div className="columns is-multiline">
                                        <div className="column is-12 is-paddingless">
                                            <div className="is-pulled-left" style={{marginRight: "5px", marginBottom: '-5px'}}><img className={styles.icon} src={Settings} alt="" /></div>
                                            <p className={styles.icon}>{t('Settings')}</p>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="navbar-end" style={{padding: '.5rem .75rem'}}>
                                { props.updateRequired &&
                                    <div className="is-pulled-left" style={{marginRight: "5px", cursor: 'pointer'}} onClick={() => { window.location.reload(); }}>
                                        <p className={styles.logout} style={{ color: '#ff8080'}}>{t('Update')}</p>
                                    </div>
                                }
                                <div className="columns is-multiline" style={{cursor: 'pointer'}} onClick={props.logoutHandler}>
                                    <div className="column is-12 is-paddingless">
                                        <div className="is-pulled-left" style={{marginRight: "5px"}}><img className={styles.logout} src={Logout} alt="" /></div>
                                        <p className={styles.logout}>{t('Log Out')} {props.authState.userName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </nav>
            }
            { props.authState &&
              props.authState.groups &&
              !props.authState.groups.official &&
              <div className="container">
                    <div style={{ position: 'fixed', top: 55, zIndex: 10}}>
                        <div className="columns is-fullheight">
                            <div className="column is-fullwidth">
                                <div className="container">
                                    <div className="columns">
                                        <div className="column is-fullwidth">
                                            <FilterSelectors
                                                showHomeViewTimeRangeSelector={showHomeViewTimeRangeSelector}
                                                authState={props.authState}
                                                filterSelections={props.filterSelections}
                                                startDate={props.startDate}
                                                endDate={props.endDate}
                                                alarmStartDate={props.alarmStartDate}
                                                alarmEndDate={props.alarmEndDate}
                                                visible={ shouldShowFilters() }
                                                reloadId={props.reloadFiltersId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="spacer"  style={{ height: (shouldShowFilters()) ? '170px' : '50px'}}>
                        &nbsp;
                    </div>
                </div>
            }
            { props.authState &&
              props.authState.groups &&
              props.authState.groups.official &&
              <div className="spacer"  style={{ height: '50px'}}>
                    &nbsp;
              </div>
            }
        </>
    );
}

export default NavBar;
