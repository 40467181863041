import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import * as logger from "../utilities/logger";
import EntityManagement from './entityManagement';
import { Entity } from './entityForm';
import { mergeStateValue, deepClone, authenticatedFilteredAxiosGet, constructCancelObject } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import moment from 'moment';
import { EntityTableHeader } from './entityTable';
import { useTranslation } from 'react-i18next';
import { FormField } from './entityForm';

interface Vehicle extends Entity {
    id: number;
    operator_id: number;
    gateway_id: string;
    site_id?: number;
    description: string;
    reg_number?: string;
    vin: string;
    production_date?: string;
    body_model_id: number;
    engine_model_id: number;
    emission_class_id: number;
    // highBackpressureThreshold?: number;
    vehicle_kms?: number;
    authority_visibility?: boolean;
}

function VechileManagement(props: {
    authState: AuthState,
    filterSelections: FilterSelections,
    setFiltersVisibility: (visibility: boolean) => void,
    onAddNew?: (data) => void,
    doAddNew?: boolean,
    onEdit?: (data) => void,
    doEdit?: string | number,
    preSelectedOperator?: boolean,
    onDataUpdated?: () => void,
}) {

    const isAdmin = !!props.authState.groups.admin;
    const isRd = !!props.authState.groups.rd;
    const isInstaller = !!props.authState.groups.installer;
    const isEngineerAdmin = !!props.authState.groups.expert;

    const [operators, setOperators] = useState([] as Entity[]);
    const [vehicles, setVehicles] = useState([] as Vehicle[]);
    const [currentVehicle, setCurrentVehicle] = useState<Vehicle>({ id: 0, operator_id: 0, gateway_id: '', description: '', reg_number: '', vin: '', body_model_id: 0, engine_model_id: 0, emission_class_id: 0 });
    const [bodyModels, setBodyModels] = useState([]);
    const [engineModels, setEngineModels] = useState([]);
    const [sites, setSites] = useState([] as Entity[]);
    const [emissionClasses, setEmissionClasses] = useState([] as Entity[]);

    const vehicleListLimit = 25;

    const { t } = useTranslation();

    useEffect(
        () => {
            fetchData();
        },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            props.authState.isAuthenticated,
        ])
    );

    const restPath = '/v10/vehicle/';

    const [cancelObject] = useState(constructCancelObject());

    function fetchVehicles() {
        return authenticatedFilteredAxiosGet('/v10/vehicle', props.authState.isAuthenticated, props.filterSelections, cancelObject);
    }

    async function fetchData() {
        props.setFiltersVisibility(true);
        try {
            const [
                operatorsResponse,
                emissionClassesResponse,
                vehiclesResponse,
                bodyModelsResponse,
                engineModelResponse,
            ] = await Promise.all([
                Axios.get((isInstaller) ? '/v10/subcontractor_company_operators' : '/v10/operators2'),
                Axios.get('/v10/emission_class'),
                fetchVehicles(),
                Axios.get('/v10/bodyModel'),
                Axios.get('/v10/engineModel'),
            ]);
            setOperators(operatorsResponse.data.operators);
            if (operatorsResponse.data.operators.length === 1) {
                getSites(operatorsResponse.data.operators[0].id as number);
            }
            setEmissionClasses(emissionClassesResponse.data.result);
            const allVehicles = vehiclesResponse.data.result.vehicles;
            setVehicles(allVehicles);
            bodyModelsResponse.data.result.map(body => body.name = body.body_manufacturer_name + " - " + body.name);
            setBodyModels(bodyModelsResponse.data.result);
            engineModelResponse.data.result.map(model => model.name = model.engine_manufacturer_name + " - " + model.name);
            setEngineModels(engineModelResponse.data.result);
        } catch (err) {
            if (!err.isCancel) {
                logger.err(err.message || err);
            }
        }
    }

    async function getSites(operatorIdValue: string | number | undefined) {
        const operatorId = typeof operatorIdValue === 'string' ? parseInt(operatorIdValue) : operatorIdValue;
        if (operatorId) {
            const response = await Axios.get(`/v10/sites?operator_id=${operatorId}`);
            setSites(response.data.result);
        } else {
            setSites([]);
        }
    }

    async function startEditing(id: number | undefined) {
        props.setFiltersVisibility(false);
        if (id === undefined) {
            setCurrentVehicle({
                id: 0,
                gateway_id: '',
                description: '',
                reg_number: '',
                production_date: '',
                emission_class_id: 0,
                vin: '',
                operator_id: 0,
                body_model_id: 0,
                engine_model_id: 0,
                authority_visibility: false,
            });
        } else {
            let useVehicles = vehicles;
            if (vehicles.length === 0) {
                const response = await Axios.get(`/v10/vehicle?vehicle_id=${props.doEdit}`);
                setVehicles(response.data.result.vehicles);
                useVehicles = response.data.result.vehicles;
            }
            const aVehicle = useVehicles.find(e => e.id === id);
            if (!aVehicle) {
                throw new Error("Internal error, requested vehicle for editing not found");
            }
            const vehicle = deepClone(aVehicle);
            if (vehicle.operator_id) {
                getSites(vehicle.operator_id);
            }
            /* if (props.authState.groups.admin && vehicle.gateway_id) {
                const response = await Axios.get('/v10/alarmRuleParams/vehicle/' + vehicle.id);
                vehicle.highBackpressureThreshold = response.data.highBackpressureThreshold;
            } */
            setCurrentVehicle(vehicle);
        }
    }

    function changeValue(name: string, value: string | number | undefined) {
        if (name === "operator_id") {
            getSites(value);
        }
        mergeStateValue(setCurrentVehicle, name, value);
    }

    function validate() {
        // check type:number fields only has digits, not even +,-,e
        const regDigit = /^\d+$/;
        // check there's no spaces in string
        const regSpaces = /\s/g;
        if (!isEngineerAdmin) {
            if (currentVehicle.gateway_id.trim() === "") {
                return t('Please specify Gateway ID');
            } else if (currentVehicle.gateway_id && regSpaces.test(currentVehicle.gateway_id)) {
                return t('Please enter a valid Gateway ID');
            } else if (currentVehicle.description.trim() === "") {
                return t('Please specify Vehicle fleet number');
            } else if (!currentVehicle.operator_id) {
                return t('Please specify operator');
            } else if (!currentVehicle.body_model_id) {
                return t('Please specify the vehicle Chassis and Body');
            } else if (!currentVehicle.engine_model_id) {
                return t('Please specify engine model');
            } else if (!currentVehicle.emission_class_id) {
                return t('Please specify the vehicle Emission Class');
            } else if (currentVehicle.vin && regSpaces.test(currentVehicle.vin)) {
                return t('Please enter a valid VIN number');
            // } else if (currentVehicle.highBackpressureThreshold && !regDigit.test("" + currentVehicle.highBackpressureThreshold)) {
            //     return t('Please enter a valid High Backpressure Threshold value');
            } else if (currentVehicle.vehicle_kms && !regDigit.test("" + currentVehicle.vehicle_kms)) {
                return t('Please enter a valid number of kilometers');
            }
        }
        return "";
    }

    function getSanitizedEditingValues() {
        const utcOffsetMinute = moment().utcOffset();
        if (!isEngineerAdmin) {
            return {
                sourceId: currentVehicle.gateway_id.trim(),
                description: currentVehicle.description.trim(),
                regNumber: typeof currentVehicle.reg_number === 'string' ? currentVehicle.reg_number.trim() : null,
                productionDate: moment(currentVehicle.production_date).add(utcOffsetMinute, 'minutes').utc(),
                emissionClassId: currentVehicle.emission_class_id,
                vin: typeof currentVehicle.vin === 'string' ? currentVehicle.vin.trim() : null,
                operator_id: Number(currentVehicle.operator_id),
                vid: Number(currentVehicle.id),
                siteId: currentVehicle.site_id,
                bodyModelId: Number(currentVehicle.body_model_id),
                engineModelId: Number(currentVehicle.engine_model_id),
                // highBackpressureThreshold: (currentVehicle.highBackpressureThreshold) ? Number(currentVehicle.highBackpressureThreshold) : null,
                vehicleKms: (currentVehicle.vehicle_kms) ? Number(currentVehicle.vehicle_kms) : null,
                authorityVisibility: currentVehicle.authority_visibility,
            };
        } else {
            return {
                operator_id: Number(currentVehicle.operator_id),
                vid: Number(currentVehicle.id),
                siteId: currentVehicle.site_id,
            };
        }
    }

    function onAddNew(what) {
        if (props.onAddNew) {
            props.onAddNew(what);
        }
    }

    function onEdit(what) {
        if (props.onEdit) {
            props.onEdit(what);
        }
    }

    function canEditVehicle(vehicle) {
        return !!props.authState.groups.admin || !!props.authState.groups.expert;
    }

    async function askDeleteConfirmation(id: number) {
        let msg = t('Are you sure you want to delete the vehicle?');
        const response = await Axios.get(`/v10/vehicleHasDiagnosticData/${id}`);
        if (response.data.has) {
            msg = t('The vehicle has already diagnostic information in the database and is likely to be a vehicle in use. ') + msg;
        }
        return window.confirm(msg);
    }

    function canDeleteVehicle(vehicle) {
        return !!props.authState.groups.admin;
    }

    const tableHeaders: EntityTableHeader[] = [
        { localizedText: t('Gateway ID'), name: 'gateway_id', sortable: true },
        { localizedText: t('Vehicle fleet number'), name: 'description', sortable: true },
        { localizedText: t('Vehicle register number'), name: 'reg_number', sortable: true },
        { localizedText: t('Chassis'), name: 'body_manufacturer_name' },
        { localizedText: t('Installation date'), name: 'production_date', type: 'date', sortable: true },
        { localizedText: t('Engine model'), name: 'engine_model_name' },
        { localizedText: t('Engine Manufacturer'), name: 'engine_manufacturer_name' },
        { localizedText: t('Emission class'), name: 'emission_class_name' },
        { localizedText: t('VIN nr'), name: 'vin', sortable: true },
        { localizedText: t('Vehicle km´s'), name: 'vehicle_kms', sortable: true },
        { localizedText: t('Visible to official'), name: 'authority_visibility', type: 'boolean' },
    ];
    if (isAdmin) {
        tableHeaders.push({ localizedText: t('Edit'), type: 'edit' });
        tableHeaders.push({ localizedText: t('Delete'), type: 'delete' });
    }
    if (isEngineerAdmin) {
        tableHeaders.push({ localizedText: t('Edit'), type: 'edit' });
    }

    const formFields: FormField[] = !isEngineerAdmin ? [
        { label: t('Gateway ID'), name: 'gateway_id', placeholder: t('Enter gateway'), maxLength: 255, type: 'string', readOnly: isAdmin || isRd ? undefined : "readOnlyOnEdit" },
        { label: t('Vehicle fleet number'), name: 'description', placeholder: t('Enter Vehicle fleet number'), maxLength: 255, type: 'string', autofocusOnEdit: true },
        { label: t('Vehicle register number'), name: 'reg_number', placeholder: t('Enter vehicle register number'), maxLength: 255, type: 'string', autofocusOnEdit: true },
        { label: t('Operator'), name: 'operator_id', allowedValues: { entityName: 'operators', name: 'name', preSelectOnlyOption: props.preSelectedOperator ? props.preSelectedOperator : false } },
        { label: t('Site'), name: 'site_id', allowedValues: {entityName: 'sites', name: 'name', } },
        { label: t('Chassis and Body'), name: 'body_model_id', allowedValues: { entityName: 'bodyModels', name: 'name', } },
        { label: t('Engine manufacturer and model'), name: 'engine_model_id', allowedValues: { entityName: 'engineModels', name: 'name', } },
        { label: t('Emission class'), name: 'emission_class_id', allowedValues: { entityName: 'emissionClasses', name: 'name', } },
        { label: t('VIN number'), name: 'vin', placeholder: t('Enter VIN number'), maxLength: 17, type: 'string' },
        { label: t('Installation date'), name: 'production_date', placeholder: t('Enter installation date'), type: 'date' },
        // { label: t('High Backpressure Threshold (greater than)'), name: 'highBackpressureThreshold', visible: isAdmin, placeholder: t('Override default backpressure threshold'), maxValue: 999999999, type: 'number' },
        { label: t('Vehicle km´s'), name: 'vehicle_kms', placeholder: t('Enter vehicle kilometers'), type: 'number' },
        { label: t('Visible to official'), name: 'authority_visibility', type: 'checkbox' },
    ] :
    [
        { label: t('Gateway ID'), name: 'gateway_id', placeholder: t('Enter gateway'), maxLength: 255, type: 'string', readOnly: "readOnlyOnEdit" },
        { label: t('Vehicle fleet number'), name: 'description', placeholder: t('Enter Vehicle fleet number'), maxLength: 255, type: 'string', readOnly: "readOnlyOnEdit" },
        { label: t('Vehicle register number'), name: 'reg_number', placeholder: t('Enter vehicle register number'), maxLength: 255, type: 'string', readOnly: "readOnlyOnEdit" },
        { label: t('Operator'), name: 'operator_id', allowedValues: { entityName: 'operators', name: 'name', preSelectOnlyOption: props.preSelectedOperator ? props.preSelectedOperator : false }, readOnly: "readOnlyOnEdit"  },
        { label: t('Site'), name: 'site_id', allowedValues: {entityName: 'sites', name: 'name', }, autofocusOnEdit: true },
    ];

    return (
            <EntityManagement
                groups={props.authState.groups}
                filterSelections={props.filterSelections}

                restPath={restPath}
                fetchData={fetchData}
                onAddNew={onAddNew}
                doAddNew={props.doAddNew}
                onEdit={onEdit}
                doEdit={props.doEdit}
                onDataUpdated={props.onDataUpdated}

                editor={{
                    edit: startEditing,
                    getSanitizedValues: getSanitizedEditingValues,
                    set: changeValue,
                    validate: validate,
                    entityName: 'currentVehicle',
                }}

                texts={{
                    localizedAddNewText: (isAdmin) ? t('Add new Vehicle') : undefined,
                    confirmDelete: askDeleteConfirmation,
                    errors: {
                        generic: t("Vehicle couldn't be saved! Please try again or contact administrator!"),
                        alreadyExists: t("A Vehicle with that name already exists"),
                        deleteInUse: t("Vehicle is in use and cannot be deleted"),
                        deleteGeneric: t("Vehicle couldn't be deleted! Please try again or contact administrator!")
                    }
                }}
                isEntityEditable={canEditVehicle}
                isEntityDeletable={canDeleteVehicle}
                entities={{ currentVehicle, vehicles, operators, bodyModels, engineModels, sites, emissionClasses }}
                entityName='vehicles'
                tableHeaders={tableHeaders}
                limitLongList={vehicleListLimit}
                formFields = {formFields}
                stripStringId={id => ''}
            />
    );
}

export default VechileManagement;
