import React, {useState, useEffect, CSSProperties} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as logger from "../utilities/logger";
import { cancellableAxiosCall, constructCancelObject, formatDate, getSectionBackgroundColor, round } from '../utilities/generic';
import SectionPanel from './SectionPanel';

function VehicleStats(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    vehicle: number | undefined,
}>) {

    const { t } = useTranslation();
    const oneHourInSeconds = 3600;

    const [stats, setStats] = useState<Array<{
        day: string,
        avg_nox_in_ppm: number | undefined,
        avg_nox_out_ppm: number | undefined,
        avg_maf: number | undefined,
        avg_nox_in_mgs: number | undefined,
        avg_nox_out_mgs: number | undefined,
        nox_reduction_efficiency: number | undefined,
        avg_nox_in_mgs_200_c: number | undefined,
        avg_nox_out_mgs_200_c: number | undefined,
        nox_reduction_efficiency_200_c: number | undefined,
        avg_nox_in_mgs_200_c_urea: number | undefined,
        avg_nox_out_mgs_200_c_urea: number | undefined,
        nox_reduction_efficiency_200_c_urea: number | undefined,
        avg_dosing_capacity: number | undefined,
        avg_inlet_scr: number | undefined,
        avg_bp: number | undefined,
        running_time: string | undefined,
        valid_rows: number | undefined,
        ignored_rows_maf_nox: number | undefined,
        ignored_rows_nox: number | undefined,
        travelled: number | undefined,
        gps_used: boolean | undefined,
        tank_empty: number | undefined,
        nox_emission: number | undefined,
        fault_codes: number | undefined,
    }>>([]);

    useEffect(() => {
        fetchData();
        // addHours(props.startDate, 12); // TODO, make max date configurable
    }, [props.vehicle, props.startDate, props.endDate]);

    const [cancelObject] = useState(constructCancelObject());
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [inError, setInError] = useState<boolean>(false);

    async function fetchData() {
        if (props.vehicle && props.startDate && props.endDate) {
            try {
                setIsBusy(true);
                setInError(false);
                const startDate = moment(props.startDate).utc().startOf('day').toISOString();
                const endDate = moment(props.endDate).utc().endOf('day').toISOString();
                const response = await cancellableAxiosCall({ url: `/v10/vehicleStats/${props.vehicle}/${startDate}/${endDate}` }, cancelObject);
                setStats(response.data.result.concat(countStatsOverAllAverages(response.data.result)));
            } catch (err) {
                if (!err.isCancel) {
                    setInError(true);
                    logger.err(err.message || err);
                }
            } finally {
                setIsBusy(false);
            }
        } else {
            setStats([]);
        }
    }

    function formatDay(aDate: Date) {
        return formatDate(aDate, "dayAndMonthWithLeadingZeroes");
    }

    const centerMiddleStyle: CSSProperties = {textAlign: 'center', verticalAlign: 'middle'};

    function countStatsOverAllAverages(vehiclesStats) {
        const overAll = {avg_nox_in_ppm: 0,
                        avg_nox_out_ppm: 0,
                        avg_maf: 0,
                        avg_nox_in_mgs: 0,
                        avg_nox_out_mgs: 0,
                        nox_reduction_efficiency: 0,
                        avg_nox_in_mgs_200_c: 0,
                        avg_nox_out_mgs_200_c: 0,
                        nox_reduction_efficiency_200_c: 0,
                        avg_nox_in_mgs_200_c_urea: 0,
                        avg_nox_out_mgs_200_c_urea: 0,
                        nox_reduction_efficiency_200_c_urea: 0,
                        avg_dosing_capacity: 0,
                        avg_inlet_scr: 0,
                        avg_bp: 0,
                        running_time: 0,
                        valid_rows: 0,
                        ignored_rows_maf_nox: 0,
                        ignored_rows_nox: 0,
                        travelled: 0,
                        tank_empty: 0,
                        nox_emission: 0,
                        fault_codes: 0,
                    };
        const numberOfStats = JSON.parse(JSON.stringify(overAll));
        const overAllAverage = JSON.parse(JSON.stringify(overAll));

        vehiclesStats.forEach(day => {
            if (day.running_time > oneHourInSeconds) {
                if (typeof day.avg_nox_in_ppm !== 'undefined' && day.avg_nox_in_ppm !== null) {
                    overAll.avg_nox_in_ppm += parseFloat(day.avg_nox_in_ppm);
                    numberOfStats.avg_nox_in_ppm += 1;
                }
                if (typeof day.avg_nox_out_ppm !== 'undefined' && day.avg_nox_out_ppm !== null) {
                    overAll.avg_nox_out_ppm += parseFloat(day.avg_nox_out_ppm);
                    numberOfStats.avg_nox_out_ppm += 1;
                }
                if (typeof day.avg_maf !== 'undefined' && day.avg_maf !== null) {
                    overAll.avg_maf += parseFloat(day.avg_maf);
                    numberOfStats.avg_maf += 1;
                }
                if (typeof day.avg_nox_in_mgs !== 'undefined' && day.avg_nox_in_mgs !== null) {
                    overAll.avg_nox_in_mgs += parseFloat(day.avg_nox_in_mgs);
                    numberOfStats.avg_nox_in_mgs += 1;
                }
                if (typeof day.avg_nox_out_mgs !== 'undefined' && day.avg_nox_out_mgs !== null) {
                    overAll.avg_nox_out_mgs += parseFloat(day.avg_nox_out_mgs);
                    numberOfStats.avg_nox_out_mgs += 1;
                }
                if (typeof day.nox_reduction_efficiency !== 'undefined' && day.nox_reduction_efficiency !== null) {
                    overAll.nox_reduction_efficiency += parseFloat(day.nox_reduction_efficiency);
                    numberOfStats.nox_reduction_efficiency += 1;
                }
                if (typeof day.avg_nox_in_mgs_200_c !== 'undefined' && day.avg_nox_in_mgs_200_c !== null) {
                    overAll.avg_nox_in_mgs_200_c += parseFloat(day.avg_nox_in_mgs_200_c);
                    numberOfStats.avg_nox_in_mgs_200_c += 1;
                }
                if (typeof day.avg_nox_out_mgs_200_c !== 'undefined' && day.avg_nox_out_mgs_200_c !== null) {
                    overAll.avg_nox_out_mgs_200_c += parseFloat(day.avg_nox_out_mgs_200_c);
                    numberOfStats.avg_nox_out_mgs_200_c += 1;
                }
                if (typeof day.nox_reduction_efficiency_200_c !== 'undefined' && day.nox_reduction_efficiency_200_c !== null) {
                    overAll.nox_reduction_efficiency_200_c += parseFloat(day.nox_reduction_efficiency_200_c);
                    numberOfStats.nox_reduction_efficiency_200_c += 1;
                }
                if (typeof day.avg_nox_in_mgs_200_c_urea !== 'undefined' && day.avg_nox_in_mgs_200_c_urea !== null) {
                    overAll.avg_nox_in_mgs_200_c_urea += parseFloat(day.avg_nox_in_mgs_200_c_urea);
                    numberOfStats.avg_nox_in_mgs_200_c_urea += 1;
                }
                if (typeof day.avg_nox_out_mgs_200_c_urea !== 'undefined' && day.avg_nox_out_mgs_200_c_urea !== null) {
                    overAll.avg_nox_out_mgs_200_c_urea += parseFloat(day.avg_nox_out_mgs_200_c_urea);
                    numberOfStats.avg_nox_out_mgs_200_c_urea += 1;
                }
                if (typeof day.nox_reduction_efficiency_200_c_urea !== 'undefined' && day.nox_reduction_efficiency_200_c_urea !== null) {
                    overAll.nox_reduction_efficiency_200_c_urea += parseFloat(day.nox_reduction_efficiency_200_c_urea);
                    numberOfStats.nox_reduction_efficiency_200_c_urea += 1;
                }
                if (typeof day.avg_dosing_capacity !== 'undefined' && day.avg_dosing_capacity !== null) {
                    overAll.avg_dosing_capacity += parseFloat(day.avg_dosing_capacity);
                    numberOfStats.avg_dosing_capacity += 1;
                }
                if (typeof day.avg_inlet_scr !== 'undefined' && day.avg_inlet_scr !== null) {
                    overAll.avg_inlet_scr += parseFloat(day.avg_inlet_scr);
                    numberOfStats.avg_inlet_scr += 1;
                }
                if (typeof day.avg_bp !== 'undefined' && day.avg_bp !== null) {
                    overAll.avg_bp += parseFloat(day.avg_bp);
                    numberOfStats.avg_bp += 1;
                }
                if (typeof day.running_time !== 'undefined' && day.running_time !== null) {
                    overAll.running_time += parseFloat(day.running_time);
                    numberOfStats.running_time += 1;
                }
                if (typeof day.valid_rows !== 'undefined' && day.valid_rows !== null) {
                    numberOfStats.valid_rows += 1;
                    overAll.valid_rows += parseFloat(day.valid_rows);
                }
                if (typeof day.ignored_rows_maf_nox !== 'undefined' && day.ignored_rows_maf_nox !== null) {
                    overAll.ignored_rows_maf_nox += parseFloat(day.ignored_rows_maf_nox);
                    numberOfStats.ignored_rows_maf_nox += 1;
                }
                if (typeof day.ignored_rows_nox !== 'undefined' && day.ignored_rows_nox !== null) {
                    overAll.ignored_rows_nox += parseFloat(day.ignored_rows_nox);
                    numberOfStats.ignored_rows_nox += 1;
                }
                if (typeof day.travelled !== 'undefined' && day.travelled !== null) {
                    overAll.travelled += parseFloat(day.travelled);
                    numberOfStats.travelled += 1;
                }
                if (typeof day.tank_empty !== 'undefined' && day.tank_empty !== null) {
                    overAll.tank_empty += parseFloat(day.tank_empty);
                    numberOfStats.tank_empty += 1;
                }
                if (typeof day.nox_emission !== 'undefined' && day.nox_emission !== null) {
                    overAll.nox_emission += parseFloat(day.nox_emission);
                    numberOfStats.nox_emission += 1;
                }
                if (typeof day.fault_codes !== 'undefined' && day.fault_codes !== null) {
                    overAll.fault_codes += parseInt(day.fault_codes);
                    numberOfStats.fault_codes += 1;
                }
            }
        });

        if (numberOfStats.avg_nox_in_ppm) {overAllAverage.avg_nox_in_ppm = round(overAll.avg_nox_in_ppm / numberOfStats.avg_nox_in_ppm, 1); }
        if (numberOfStats.avg_nox_out_ppm) {overAllAverage.avg_nox_out_ppm = round(overAll.avg_nox_out_ppm / numberOfStats.avg_nox_out_ppm, 1); }
        if (numberOfStats.avg_maf) {overAllAverage.avg_maf = round(overAll.avg_maf / numberOfStats.avg_maf, 1); }
        if (numberOfStats.avg_nox_in_mgs) {overAllAverage.avg_nox_in_mgs = round(overAll.avg_nox_in_mgs / numberOfStats.avg_nox_in_mgs, 1); }
        if (numberOfStats.avg_nox_out_mgs) {overAllAverage.avg_nox_out_mgs = round(overAll.avg_nox_out_mgs / numberOfStats.avg_nox_out_mgs, 1); }
        if (numberOfStats.nox_reduction_efficiency) {overAllAverage.nox_reduction_efficiency = round(overAll.nox_reduction_efficiency / numberOfStats.nox_reduction_efficiency, 1); }
        if (numberOfStats.avg_nox_in_mgs_200_c) {overAllAverage.avg_nox_in_mgs_200_c = round(overAll.avg_nox_in_mgs_200_c / numberOfStats.avg_nox_in_mgs_200_c, 1); }
        if (numberOfStats.avg_nox_out_mgs_200_c) {overAllAverage.avg_nox_out_mgs_200_c = round(overAll.avg_nox_out_mgs_200_c / numberOfStats.avg_nox_out_mgs_200_c, 1); }
        if (numberOfStats.nox_reduction_efficiency_200_c) {overAllAverage.nox_reduction_efficiency_200_c = round(overAll.nox_reduction_efficiency_200_c / numberOfStats.nox_reduction_efficiency_200_c, 1); }
        if (numberOfStats.avg_nox_in_mgs_200_c_urea) {overAllAverage.avg_nox_in_mgs_200_c_urea = round(overAll.avg_nox_in_mgs_200_c_urea / numberOfStats.avg_nox_in_mgs_200_c_urea, 1); }
        if (numberOfStats.avg_nox_out_mgs_200_c_urea) {overAllAverage.avg_nox_out_mgs_200_c_urea = round(overAll.avg_nox_out_mgs_200_c_urea / numberOfStats.avg_nox_out_mgs_200_c_urea, 1); }
        if (numberOfStats.nox_reduction_efficiency_200_c_urea) {overAllAverage.nox_reduction_efficiency_200_c_urea = round(overAll.nox_reduction_efficiency_200_c_urea / numberOfStats.nox_reduction_efficiency_200_c_urea, 1); }
        if (numberOfStats.avg_dosing_capacity) {overAllAverage.avg_dosing_capacity = round(overAll.avg_dosing_capacity / numberOfStats.avg_dosing_capacity, 1); }
        if (numberOfStats.avg_inlet_scr) {overAllAverage.avg_inlet_scr = round(overAll.avg_inlet_scr / numberOfStats.avg_inlet_scr, 1); }
        if (numberOfStats.avg_bp) {overAllAverage.avg_bp = round(overAll.avg_bp / numberOfStats.avg_bp, 1); }
        if (numberOfStats.running_time) {overAllAverage.running_time = round(overAll.running_time / numberOfStats.running_time, 1); }
        if (numberOfStats.valid_rows) {overAllAverage.valid_rows = round(overAll.valid_rows / numberOfStats.valid_rows, 1); }
        if (numberOfStats.ignored_rows_maf_nox) {overAllAverage.ignored_rows_maf_nox = round(overAll.ignored_rows_maf_nox / numberOfStats.ignored_rows_maf_nox, 1); }
        if (numberOfStats.ignored_rows_nox) {overAllAverage.ignored_rows_nox = round(overAll.ignored_rows_nox / numberOfStats.ignored_rows_nox, 1); }
        if (numberOfStats.travelled) {overAllAverage.travelled = round(overAll.travelled / numberOfStats.travelled, 1); }
        if (numberOfStats.tank_empty) {overAllAverage.tank_empty = round(overAll.tank_empty / numberOfStats.tank_empty, 1); }
        if (numberOfStats.nox_emission) {overAllAverage.nox_emission = round(overAll.nox_emission / numberOfStats.nox_emission, 1); }
        if (numberOfStats.fault_codes) {overAllAverage.fault_codes = round(overAll.fault_codes / numberOfStats.fault_codes, 1); }

        return overAllAverage;
    }

    function notLongEnoughRunningTime(day: { running_time: string | undefined }) {
        return day.running_time && parseFloat(day.running_time) < oneHourInSeconds;
    }

    function getSectionState() {
        if (inError) {
            return "error";
        } else if (isBusy) {
            return "busy";
        } else {
            return "ok";
        }
    }
    const filteredAverages = stats.some(day => notLongEnoughRunningTime(day));

    return (
        <SectionPanel
                state={getSectionState()}
                child={
        <>
            <table
                    className="table is-hoverable is-fullwidth is-narrow is-bordered"
                    style={{borderCollapse: 'unset', fontSize: 'small', marginBottom: 10, backgroundColor: getSectionBackgroundColor(inError, isBusy)}}>
                <thead style={{backgroundColor: 'lightgray'}}>
                    <tr style={{fontSize: 'x-small'}}>
                        <th></th>
                        <th colSpan={5} style={{textAlign: 'center'}}>{t('Avg')}</th>
                        <th></th>
                        <th colSpan={2} style={{textAlign: 'center'}}>{t('Avg')}</th>
                        <th></th>
                        <th colSpan={2} style={{textAlign: 'center'}}>{t('Avg')}</th>
                        <th></th>
                        <th colSpan={3} style={{textAlign: 'center'}}>{t('Avg')}</th>
                        <th colSpan={8}></th>
                    </tr>
                    <tr>
                        <th style={{textAlign: 'center'}}>{t('Date (UTC)')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx in')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx out')}</th>
                        <th style={{textAlign: 'center'}}>{t('MAF')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx in')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx out')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx reduction efficiency')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx in')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx out')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx reduction efficiency')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx in')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx out')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx reduction efficiency')}</th>
                        <th style={{textAlign: 'center'}}>{t('Dosing capacity')}</th>
                        <th style={{textAlign: 'center'}}>{t('inlet SCR')}</th>
                        <th style={{textAlign: 'center'}}>{t('BP')}</th>
                        <th style={{textAlign: 'center'}}>{t('Running time')}</th>
                        <th style={{textAlign: 'center'}}>{t('Valid rows')}</th>
                        <th style={{textAlign: 'center'}}>{t('Ignored rows')}</th>
                        <th style={{textAlign: 'center'}}>{t('Ignored rows')}</th>
                        <th style={{textAlign: 'center'}}>{t('Travelled')}</th>
                        <th style={{textAlign: 'center'}}>{t('Tank Empty')}</th>
                        <th style={{textAlign: 'center'}}>{t('NOx emission')}</th>
                        <th style={{textAlign: 'center'}}>{t('Fault Codes')}</th>
                    </tr>
                    <tr style={{fontSize: 'x-small'}}>
                        <th></th>
                        <th colSpan={2} style={centerMiddleStyle}>ppm</th>
                        <th style={centerMiddleStyle}>kg/h</th>
                        <th colSpan={2} style={centerMiddleStyle}>mg/s</th>
                        <th style={centerMiddleStyle}>%</th>
                        <th colSpan={2} style={centerMiddleStyle}>mg/s</th>
                        <th style={centerMiddleStyle}>%</th>
                        <th colSpan={2} style={centerMiddleStyle}>mg/s</th>
                        <th style={centerMiddleStyle}>%</th>
                        <th style={centerMiddleStyle}>ml/h</th>
                        <th style={centerMiddleStyle}>°C</th>
                        <th style={centerMiddleStyle}>mbar</th>
                        <th colSpan={2}></th>
                        <th style={centerMiddleStyle}>MAF + NOx</th>
                        <th style={centerMiddleStyle}>NOx</th>
                        <th style={centerMiddleStyle}>km</th>
                        <th style={centerMiddleStyle}>%</th>
                        <th style={centerMiddleStyle}>g/km</th>
                        <th></th>
                    </tr>
                    <tr style={{fontSize: 'x-small'}}>
                        <th colSpan={7}></th>
                        <th colSpan={3} style={centerMiddleStyle}>&gt; 200°C</th>
                        <th colSpan={3} style={centerMiddleStyle}>&gt; 200°C ({t('Urea in Tank')})</th>
                        <th colSpan={11}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        stats.map((day, i) => {
                            return (
                                <tr key={i} className={notLongEnoughRunningTime(day) ? 'back-lightgray' : ''}>
                                    <td>{i === stats.length - 1 ? (filteredAverages ? t('Filtered Avg') : t('Avg')) : formatDay(new Date(day.day))}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_in_ppm}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_out_ppm}</td>
                                    <td style={centerMiddleStyle}>{day.avg_maf}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_in_mgs}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_out_mgs}</td>
                                    <td style={centerMiddleStyle}>{day.nox_reduction_efficiency}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_in_mgs_200_c}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_out_mgs_200_c}</td>
                                    <td style={centerMiddleStyle}>{day.nox_reduction_efficiency_200_c}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_in_mgs_200_c_urea}</td>
                                    <td style={centerMiddleStyle}>{day.avg_nox_out_mgs_200_c_urea}</td>
                                    <td style={centerMiddleStyle}>{day.nox_reduction_efficiency_200_c_urea}</td>
                                    <td style={centerMiddleStyle}>{day.avg_dosing_capacity}</td>
                                    <td style={centerMiddleStyle}>{day.avg_inlet_scr}</td>
                                    <td style={centerMiddleStyle}>{day.avg_bp}</td>
                                    <td style={centerMiddleStyle}>{day.running_time ? new Date(parseInt(day.running_time) * 1000).toISOString().substr(11, 8) : '' }</td>
                                    <td style={centerMiddleStyle}>{day.valid_rows}</td>
                                    <td style={centerMiddleStyle}>{day.ignored_rows_maf_nox}</td>
                                    <td style={centerMiddleStyle}>{day.ignored_rows_nox}</td>
                                    <td style={centerMiddleStyle}>{day.travelled}{day.gps_used ? ' *' : ''}</td>
                                    <td style={centerMiddleStyle}>{day.tank_empty}</td>
                                    <td style={centerMiddleStyle}>{day.nox_emission}</td>
                                    <td style={centerMiddleStyle}>{day.fault_codes}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            { filteredAverages &&
                <span style={{fontSize: 'small', float: 'left'}}>{t('Day rows with less than 1h of daily running time are gray and filtered out of totals average')}</span>
            }
            { stats.some(day => day.gps_used) &&
                <span style={{fontSize: 'small', float: 'right'}}>*&nbsp;{t('Distance calculated partly or totally from GPS data')}</span>
            }
        </>}
    />);
}

export default VehicleStats;
