import React, { useState, useEffect } from "react";
import * as logger from "../utilities/logger";
import eventClient, { eppVehicleTargetPrefix } from '../utilities/epp-client';
import { cancellableAxiosCall, constructCancelObject } from "../utilities/generic";
import { Operator } from "../reducers/filterSelections";

function Map(props: Readonly<{
    eppClientInstance: ReturnType<typeof eventClient>,
    showMap: boolean,
    startDate: Date | undefined,
    endDate: Date | undefined,
    vehicles: Array<{ id: number }>, // TODO: Get the whole Vehicle here so that it's operatorId can be used also
    operators: Operator[] | undefined,
    site: number | undefined,
    bodyManufacturerId: number | undefined,
    bodyModelId: number | undefined,
    engineManufacturerId: number | undefined,
    engineModelId: number | undefined,
    onVehicleIdSelected: (vehicleId: number) => void,
}>) {

    const [mapState, setMapState] = useState<{
        mapDiv?: HTMLElement,
        map?
    }>({});

    const [selection, setSelection] = useState<{
        startDate: Date | undefined,
        endDate: Date | undefined,
        vehicles: Array<{ id: number }>,
        bodyManufacturerId: number | undefined,
        bodyModelId: number | undefined,
        engineManufacturerId: number | undefined,
        engineModelId: number | undefined,
        operators: Operator[] | undefined,
    }>({
        startDate: undefined,
        endDate: undefined,
        vehicles: [],
        bodyManufacturerId: undefined,
        bodyModelId: undefined,
        engineManufacturerId: undefined,
        engineModelId: undefined,
        operators: undefined,
    });

    useEffect(
        updateMap,
        [
            props.eppClientInstance,
            props.showMap,
            props.startDate,
            props.endDate,
            props.vehicles,
            props.operators,
            props.bodyManufacturerId,
            props.bodyModelId,
            props.engineManufacturerId,
            props.engineModelId,
        ]
    );

    function updateMap() {
        try {
            if (props.eppClientInstance) {
                if (props.showMap) {
                    if (props.startDate !== selection.startDate || props.endDate !== selection.endDate ||
                        props.operators !== selection.operators ||
                        props.bodyManufacturerId !== selection.bodyManufacturerId ||
                        props.bodyModelId !== selection.bodyModelId ||
                        props.engineManufacturerId !== selection.engineManufacturerId ||
                        props.engineModelId !== selection.engineModelId ||
                        props.vehicles.map(v => v.id).join(',') !== (selection.vehicles.map(v => v.id)).join(',')
                    ) {
                        setSelection(props);
                        props.eppClientInstance.getMap(redrawMapAndRoute);
                    }
                } else {
                    props.eppClientInstance.destroyMap();
                    setMapState({});
                }
            }
        } catch (err) {
            logger.err('Map error:', err);
        }
    }

    const [getVehiclesCancelObject] = useState(constructCancelObject());

    function redrawMapAndRoute(map, mapDiv: HTMLDivElement) {
        setMapState(oldState => {
            if (map && map !== oldState.map) {
                if (oldState.map) {
                    oldState.map.removeAllListeners();
                }
                map.on('targetMarkerClick', targetDetails => {
                    const vehicleIdWithVPrefix: string = targetDetails.targetId;
                    if (vehicleIdWithVPrefix.startsWith(eppVehicleTargetPrefix)) {
                        const vehicleId = parseInt(vehicleIdWithVPrefix.substr(2));
                        if (vehicleId > 0) {
                            props.onVehicleIdSelected(vehicleId);
                            return;
                        }
                    }
                    throw new Error('Not expected targetDetails: ' + JSON.stringify(targetDetails));
                });
            }
            return { mapDiv, map };
        });
        if (map) {
            // TODO: Remove this async wrapper and rewrite all EPP map related code by async/await
            (async function () {
                await map.clearMap();
                map.setEnrichment();
                map.setTimeSpan();
                map.redraw();
                if (props.startDate) {
                    if (props.vehicles.length > 0) {
                        let moveTo: "history" | "markers";
                        if (props.vehicles.length === 1) {
                            map.setEnrichment("noxOut");
                            const startDate = new Date(props.startDate);
                            const endDate = props.endDate ? new Date(props.endDate) : new Date();
                            const oneDayLess = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1, endDate.getHours(), endDate.getMinutes());
                            map.setTimeSpan(oneDayLess > startDate ? oneDayLess : startDate, props.endDate ? endDate : undefined);
                            moveTo = "history";
                        } else {
                            moveTo = "markers";
                        }
                        try {
                            // TODO: Remove fetching of vehicle details when Vehicle objects actually contain operatorId (and other details)
                            const results = await cancellableAxiosCall({ url: '/v10/vehicle?vehicle_id=' + props.vehicles.map(v => v.id).join(',') }, getVehiclesCancelObject);
                            const vehicles = results.data?.result?.vehicles;
                            if (Array.isArray(vehicles) && vehicles.length >= 1) {
                                const vehicleIdsWithV = vehicles.map(v => eppVehicleTargetPrefix + v.id);
                                const [ firstVehicle ] = vehicles;
                                if (vehicles.some(v => v.operator_id !== firstVehicle.operator_id)) { // This happens at least in case of Fault graph bar clicking when vehicles have different operators
                                    await Promise.all(vehicles.map(v => map.showTargets(
                                        [ v.gateway_id ],
                                        {
                                            mustHaveGroups: [ 'operator_' + v.operator_id ],
                                        }
                                    )));
                                    map.focusMapToTargets(vehicleIdsWithV);
                                } else {
                                    await map.showTargets(
                                        vehicleIdsWithV,
                                        {
                                            moveTo: moveTo,
                                            mustHaveGroups: [ 'operator_' + firstVehicle.operator_id ],
                                        }
                                    );
                                }
                            }
                        } catch (err) {
                            if (!err.isCancel) {
                                throw err;
                            }
                        }
                    } else {
                        const eppOperatorGroupIds = (props.operators ?? []).map(operator => 'operator_' + operator.id);
                        if (eppOperatorGroupIds.length > 0) {
                            await Promise.all(((eppOperatorGroupIds).map(eppOperatorGroupId => {
                                const eppGroups: string[] = [];
                                eppGroups.push(eppOperatorGroupId);
                                if (props.site) {
                                    eppGroups.push('site_' + props.site);
                                }
                                if (props.bodyModelId) {
                                    eppGroups.push('bmo_' + props.bodyModelId);
                                }
                                if (props.bodyManufacturerId) {
                                    eppGroups.push('bma_' + props.bodyManufacturerId);
                                }
                                if (props.engineManufacturerId) {
                                    eppGroups.push('ema_' + props.engineManufacturerId);
                                }
                                if (props.engineModelId) {
                                    eppGroups.push('emo_' + props.engineModelId);
                                }
                                return map.showTargetGroups(
                                    eppGroups,
                                    {
                                        mustHaveAllGroups: true,
                                    }
                                );
                            })));
                            map.focusMapToTargetGroups(eppOperatorGroupIds);
                        }
                    }
                }
                map.redraw();
            })().catch(err => {
                logger.err('map redrawMapAndRoute:', err.message || err);
            });
        }
    }

    return (
        <div className="columns is-fullwidth is-multiline">
        {
            mapState.mapDiv &&
            <div className="column is-12" style={{ zIndex: 0 }}
                ref={divElement => {
                    logger.debug('divElement for map:', divElement);
                    if (divElement && mapState.mapDiv) {
                        divElement.appendChild(mapState.mapDiv);
                    }
                }}
            >
            </div>
        }
        </div>);
}

export default Map;
