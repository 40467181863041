import React, { useState, useEffect, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as logger from "../utilities/logger";
import * as actionTypes from '../Actions/Actions';
import RedGreenIndicator from './RedGreenIndicator';
import RouteContext from '../contexts/RouteContext';
import HomeViewSection from './HomeViewSection';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { FilterSelections } from '../reducers/filterSelections';
import { authenticatedFilteredAxiosGet, constructCancelObject, getSectionBackgroundColor, round, useSelectionAutoScroll } from '../utilities/generic';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import SectionPanel from './SectionPanel';

export interface Vehicle {
    id: number;
    description: string;
    operator_name: string;
    body_manufacturer_name: string;
    nox_out: number | null;
    backpressure: number | null;
    is_tank_empty: boolean | null;
    dayEmission?: number;
    monthEmission?: number;
    halfYearEmission?: number;
}

function VehicleTable(props: RouteComponentProps & Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    authState: AuthState,
    filterSelections: FilterSelections,
    selectedVehicleIds: number[] | undefined,
}>) {

    const { t } = useTranslation();
    const context = useContext(RouteContext);

    const [vehiclesInfo, setVehiclesInfo] = useState({
        vehicles: [] as Vehicle[],
    });

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [inError, setInError] = useState<boolean>(false);

    const [getVehiclesCancelObject] = useState(constructCancelObject());

    async function getVehicles() {
        try {
            setIsBusy(true);
            setInError(false);
            const callResult = await authenticatedFilteredAxiosGet('/v10/vehicle?includeEmission=true', props.authState.isAuthenticated, props.filterSelections, getVehiclesCancelObject, props.filterSelections.operators && props.filterSelections.operators.length > 0);
            const result = callResult.data.result;
            setVehiclesInfo(
                {
                    vehicles: result.vehicles,
                }
            );
        } catch (err) {
            if (!err.isCancel) {
                setInError(true);
                logger.err(err.message || err);
            }
        } finally {
            setIsBusy(false);
        }
    }

    useEffect(
        () => {
            getVehicles();
        },
        constructArrayOfFilterSelections(props.filterSelections, false).concat([
            props.startDate,
            props.endDate,
            props.authState.isAuthenticated,
        ])
    );

    function isSelected(vehicle: Vehicle) {
        return props.selectedVehicleIds && props.selectedVehicleIds.some(sv => sv === vehicle.id);
    }

    function shouldShowOperatorColumn() {
        return props.authState.groups.admin || props.authState.groups.rd || props.authState.groups.regionalEngineer;
    }

    function toggleVehicleSelection(vehicle: Vehicle) {
        if (context) {
            context.filterSelectionsDispatch(
                {
                    type: actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION,
                    fixedVehiclesSelection: isSelected(vehicle) ? undefined : [ { id: vehicle.id } ],
                }
            );
        }
    }

    const { scrollableDivRef, tableBodyRef, selectedRowRef } = useSelectionAutoScroll([
        props.filterSelections.fixedVehiclesSelection
    ]);

    function renderTable() {
        return vehiclesInfo ? (
            <div
                style={{maxHeight: '300px', overflow: 'auto'}}
                ref={scrollableDivRef}
            >
                <table className="table is-hoverable is-fullwidth is-narrow is-bordered sticky" style={{background: 'inherit'}}>
                    <thead>
                        <tr>
                            <th>{t('Vehicle ID')}</th>
                            <th>{t('Chassis')}</th>
                            {shouldShowOperatorColumn() &&
                                <th>{t('Bus Operator')}</th>
                            }
                            <th>24h {t('average NOx Emissions')} (g/km)</th>
                            <th>30 {t('days average NOx Emissions')} (g/km)</th>
                            <th>180 {t('days average NOx Emissions')} (g/km)</th>
                            <th>{t('Urea Level')}</th>
                            <th>{t('Tailpipe NOx below')} 100 ppm</th>
                            <th>DPF {t('Backpressure below')} 20 KPa</th>
                        </tr>
                    </thead>
                    <tbody ref={tableBodyRef}>
                        {vehiclesInfo.vehicles.map((vehicle, i) => {
                            const selected = isSelected(vehicle);
                            return (
                                <tr
                                    key={i}
                                    className={ selected ? "is-selected" : undefined }
                                    ref={elem => {
                                        if (selected) {
                                            selectedRowRef(elem);
                                        }
                                    }}
                                >
                                    <td>
                                        <button className="islink" onClick={() => toggleVehicleSelection(vehicle)}>
                                        {vehicle.description}
                                        </button>
                                    </td>
                                    <td>{vehicle.body_manufacturer_name}</td>
                                    {shouldShowOperatorColumn() &&
                                        <td>{vehicle.operator_name}</td>
                                    }
                                    <td>{vehicle.dayEmission ? round(vehicle.dayEmission, 2) : 'N/A'}</td>
                                    <td>{vehicle.monthEmission ? round(vehicle.monthEmission, 2) : 'N/A'}</td>
                                    <td>{vehicle.halfYearEmission ? round(vehicle.halfYearEmission, 2) : 'N/A'}</td>
                                    <td><RedGreenIndicator showGreen={vehicle.is_tank_empty !== null ? !vehicle.is_tank_empty : undefined} /></td>
                                    <td><RedGreenIndicator showGreen={vehicle.nox_out !== null ? vehicle.nox_out < 100 : undefined} /></td>
                                    <td><RedGreenIndicator showGreen={vehicle.backpressure !== null ? vehicle.backpressure < 200 : undefined} /></td>
                                </tr>
                            );
                            })
                        }
                    </tbody>
                </table>
            </div>
        ) :
        <></>;
    }

    function getSectionState() {
        if (inError) {
            return "error";
        } else if (isBusy) {
            return "busy";
        } else {
            return "ok";
        }
    }

    return (
        <HomeViewSection
            columnsStyle={{marginTop: '10px'}}
            backgroundStyle={{backgroundColor: getSectionBackgroundColor(inError, isBusy)}}
            child={
                <div className="columns" style={{backgroundColor: getSectionBackgroundColor(inError, isBusy)}}>
                    <div className="column is-full" style={{ position: "relative"}}>
                        <SectionPanel
                            state={getSectionState()}
                            child={renderTable()} />
                    </div>
                </div>
            }
        />);
}

export default withRouter(VehicleTable);
