import * as actionTypes from '../../Actions/Actions';

// FIXME: there's duplicated logic / values here and in user roles, multiple maintenance points for same thing
export interface Groups {
    admin?: true;
    official?: true;
    installer?: true;
    installerManager?: true;
    rd?: true;
    executive?: true;
    expert?: true;
    regionalEngineer?: true;
    technician?: true;
    operators: number[];
}

export interface AuthState {
    serverVersion: string | undefined;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    userName?: string;
    groups: Groups;
    eppMapScriptUrl: string;
    eppRulesToListen?: string[];
    hideLocation?: boolean;
    userCity?: number;
    maxBucketCountForSensorDataFetch?: number;
}

export const initialState: AuthState = {
    serverVersion: undefined,
    isAuthenticated: false,
    isAuthenticating: true,
    groups: {operators: []},
    eppMapScriptUrl: '',
};

export interface LoginAction {
    type: typeof actionTypes.SET_CLIENTCONFIG_AND_LOGIN;
    serverVersion: string;
    userName: string;
    groups: Groups;
    hideLocation: boolean;
    eppMapScriptUrl: string;
    eppRulesToListen: string[] | undefined;
    userCity?: number;
    maxBucketCountForSensorDataFetch: number;
}

export interface LogoutAction {
    type: typeof actionTypes.LOGOUT;
    serverVersion: string;
}

export function reducer(state: AuthState, action: LoginAction | LogoutAction): AuthState {
    switch (action.type) {
        case actionTypes.LOGOUT:
            return {
                serverVersion: action.serverVersion || state.serverVersion,
                isAuthenticated: false,
                isAuthenticating: false,
                groups: {operators: []},
                eppMapScriptUrl: '',
            };
        case actionTypes.SET_CLIENTCONFIG_AND_LOGIN:
            // TODO: Don't return new authState object if userName, groups and operator matches?
            return {
                serverVersion: action.serverVersion,
                isAuthenticated: true,
                isAuthenticating: false,
                userName: action.userName,
                userCity: action.userCity,
                groups: action.groups,
                eppMapScriptUrl: action.eppMapScriptUrl,
                eppRulesToListen: action.eppRulesToListen,
                hideLocation: action.hideLocation,
                maxBucketCountForSensorDataFetch: action.maxBucketCountForSensorDataFetch,
            };
    }
    return state;
}
