import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { AbstractEntity, FormField } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';

interface Rule extends AbstractEntity<string> {
    name: string | undefined;
    // highBackpressureThreshold?: string;
    // highBackpressureTime?: string;
    lowBackpressureThreshold?: string;
    lowBackpressureTime?: string;
    lowBackpressureRuleMafThreshold?: string;
    threeDayNoxReductionDifferenceThreshold?: string;
    threeDayNoxReductionRevertThreshold?: string;
}

function AlarmRuleManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
}>) {

    const [entities, setEntities] = useState([] as Rule[]);
    const [currentEntity, setCurrentEntity] = useState<Rule>({ id: '', name: '' });

    const restPath = '/v10/alarmRules/';

    const { t } = useTranslation();

    function fetchData() {
        Axios.get('/v10/alarmRules').then(response => setEntities(response.data.result));
    }

    async function startEditing(id: string | undefined) {
        if (id === undefined) {
            setCurrentEntity({ id: '', name: '' });
        } else {
            const entity = entities.find(e => e.id === id);
            if (!entity) {
                throw new Error('Internal error');
            }
            setCurrentEntity(deepClone(entity));
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentEntity, name, value);
    }

    function validate() {
        if (currentEntity) {
            /* if (currentEntity.id === 'highBackpressure') {
                if (currentEntity.highBackpressureThreshold === '') {
                    return t("Please specify high backpressure threshold");
                }
                if (currentEntity.highBackpressureTime === '') {
                    return t("Please specify high backpressure time");
                }
            } else */
            if (currentEntity.id === 'lowBackpressure') {
                if (currentEntity.lowBackpressureThreshold === '') {
                    return t("Please specify low backpressure threshold");
                }
                if (currentEntity.lowBackpressureTime === '') {
                    return t("Please specify low backpressure time");
                }
                if (currentEntity.lowBackpressureRuleMafThreshold === '') {
                    return t("Please specify low backpressure rule MAF threshold");
                }
            } else if (currentEntity.id === 'impairedNoxReduction') {
                if (currentEntity.threeDayNoxReductionDifferenceThreshold === '') {
                    return t("Please specify NOx reduction difference threshold");
                }
                if (currentEntity.threeDayNoxReductionRevertThreshold === '') {
                    return t("Please specify NOx reduction revert threshold");
                }
            }
        }
        return "";
    }

    function getSanitizedEditingValues() {
        if (currentEntity) {
            Object.entries(currentEntity).forEach(([key, value]) => {
                if (key !== 'id' && key !== 'name') {
                    currentEntity[key] = parseFloat(value as string);
                }
            });
            return currentEntity;
        }
    }

    function getCurrentRuleFields() {
        let fields: FormField[] = [];
        if (currentEntity) {
            /* if (currentEntity.id === 'highBackpressure') {
                fields = [
                    { label: t('High Backpressure Threshold (greater than)'), name: 'highBackpressureThreshold', placeholder: t('High Backpressure Threshold') },
                    { label: t('High Backpressure Time (in milliseconds)'), name: 'highBackpressureTime', placeholder: t('High Backpressure Time (in milliseconds)') },
                ];
            } else */
            if (currentEntity.id === 'lowBackpressure') {
                fields = [
                    { label: t('Low Backpressure Threshold (less than)'), name: 'lowBackpressureThreshold', placeholder: t('Low Backpressure Threshold') },
                    { label: t('Low Backpressure Time (in milliseconds)'), name: 'lowBackpressureTime', placeholder: t('Low Backpressure Time (in milliseconds)') },
                    { label: t('Low Backpressure MAF Threshold (greater than)'), name: 'lowBackpressureRuleMafThreshold', placeholder: t('Low Backpressure MAF Threshold') },
                ];
            } else if (currentEntity.id === 'impairedNoxReduction') {
                fields = [
                    { label: t('Three Day NOx Reduction Difference Threshold % (equal or greater than)'), name: 'threeDayNoxReductionDifferenceThreshold', placeholder: t('Three Day NOx Reduction Difference Threshold %') },
                    { label: t('Three Day NOx Reduction Revert Threshold (equal or greater than)'), name: 'threeDayNoxReductionRevertThreshold', placeholder: t('Three Day NOx Reduction Revert Threshold') },
                ];
            }
        }
        return fields;
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}

            restPath={restPath}
            fetchData={fetchData}

            parseId={id => id}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentEntity'
            }}

            texts={{
                // addNew: 'Add new alarm rule',
                // confirmDelete: 'Are you sure you want to delete the alarm rule?',
                errors: {
                    generic: t("Alarm rule couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("An alarm rulewith that name already exists"),
                    deleteInUse: t("Alarm rule is in use and cannot be deleted"),
                    deleteGeneric: t("Alarm rule couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ entities: entities, currentEntity: currentEntity }}
            entityName='entities'

            tableHeaders={[
                { localizedText: t('Alarm Rule'), name: 'name', sortable: true },
                { localizedText: t('Edit'), type: 'edit' },
            ]}

            formFields={[
                { label: t('Alarm Rule Name'), name: 'name', placeholder: t('Enter an alarm rule name'), readOnly: 'readOnly' } as FormField
            ].concat(getCurrentRuleFields())}
            stripStringId={id => id}
        />
    );
}

export default AlarmRuleManagement;
