import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import EntityTable, { EntityTableHeader } from './entityTable';
import EntityForm, { Entities, FormField } from './entityForm';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import DeleteDialog from './DeleteDialog';

function EntityManagement<IdType extends (string | number)>(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    entities: Entities<IdType>,
    entityName: string,
    tableHeaders: EntityTableHeader[],
    formFields: FormField[],
    fetchData: () => void,
    editor: {
        entityName: string,
        validate: () => string,
        edit: (id?: IdType) => Promise<void>,
        set: (name: string, value) => void,
        getSanitizedValues: () => unknown,
    },
    errorCodeToMessage?: (err: { response?: { data?: { [ key: string ]: string } }, message?: string }) => string | undefined,
    parseId?: (id: string) => number | string,
    stripStringId?: (id: string) => string | number,
    restPath: string,
    isEntityEditable?: (entity) => boolean,
    isEntityDeletable?: (entity) => boolean,
    onDataUpdated?: () => void,

    // callback, can be used in cases where for example a management is called for simply adding new entity
    onAddNew?: (data?) => void,
    // fire for straight opening an add form
    doAddNew?: boolean,

    onEdit?: (data?) => void,
    doEdit?: string | number,
    // allow a limit to prevent listing of entities to blow up
    limitLongList?: number,

    texts: {
        localizedAddNewText?: string,
        confirmDelete?: string | ((id: number) => boolean) | ((id: number) => Promise<boolean>),
        errors: {
            deleteInUse: string,
            deleteGeneric: string,
            alreadyExists: string,
            generic: string,
        }
    },

    additionalTopContent?: JSX.Element,
}>) {

    const [error, setError] = useState('');
    const [viewMode, setViewMode] = useState<'list' | 'add' | 'edit'> ('list');
    const [selectedId, setSelectedId] = useState();
    const [deleteDialogState, setDeleteDialogState] = useState({
        showDeleteDialog: false as boolean,
        error: "" as string,
        id: "" as string,
        name: "" as string,
     });

    useEffect(() => {
        if (props.doAddNew) {
            handleAddNew();
        } else if (props.doEdit) { // FIXME: undefined check
            handleEdit(props.doEdit);
        } else {
            showListView();
        }
    }, [props.filterSelections.operators]);

    function triggerDataUpdatedNotification() {
        if (props.onDataUpdated) {
            props.onDataUpdated();
        }
    }

    function showListView() {
        setViewMode('list');
        setError('');
        props.fetchData();
    }

    async function handleAddNew() {
        await props.editor.edit();
        setViewMode('add');
    }

    function handleFormChange(event) {
        const [name, value] = [event.target.name, event.target.value];
        props.editor.set(name, value);
    }

    function handleFormChangeDate(date, fieldName) {
        const [name, value] = [fieldName, date];
        props.editor.set(name, value);
    }

    function handleFormCheckboxChange(event) {
        const [name, value] = [event.target.name, event.target.checked];
        props.editor.set(name, value);
    }

    function handleAddFormSubmit(event) {
        event.preventDefault();
        const errMsg = props.editor.validate();
        if (errMsg === "") {
            const reqBody = props.editor.getSanitizedValues();
            Axios.post(props.restPath, reqBody).then(resp => {
                if (resp.status === 201) {
                    if (props.onAddNew) {
                        props.onAddNew({request: reqBody, response: resp});
                    }
                    showListView();
                    triggerDataUpdatedNotification();
                } else {
                    throw new Error("");
                }
            }).catch(err => {
                setError(getErrorMessage(err, false));
            });
        } else {
            setError(errMsg);
        }
    }

    async function handleEdit(id) {
        await props.editor.edit(id);
        setSelectedId(id);
        setViewMode('edit');
    }

    function handleEditFormSubmit(event, id) {
        event.preventDefault();
        const errMsg = props.editor.validate();
        if (errMsg) {
            setError(errMsg);
        } else {
            const reqBody = props.editor.getSanitizedValues();
            Axios.patch(props.restPath + (props.stripStringId ? props.stripStringId(id) : Number(id)), reqBody).then(resp => {
                if (resp.status === 200) {
                    if (props.onEdit) {
                        props.onEdit({request: reqBody, response: resp});
                    }
                    showListView();
                    triggerDataUpdatedNotification();
                } else {
                    throw new Error("");
                }
            }).catch(err => {
                setError(getErrorMessage(err, false));
            });
        }
    }

    function askDeleteConfirmation(id, name?: string | undefined) {
        if (typeof props.texts.confirmDelete === "function") {
            return Promise.resolve(props.texts.confirmDelete(id));
        }
    }

    // FIXME: this looks like leftover from incomplete refactor and duplicate logic of handleDeleteConfirm
    async function handleDelete(id, name?: string | undefined) {
        if (typeof props.texts.confirmDelete === "function") {
            if (await askDeleteConfirmation(id)) {
                Axios.delete(props.restPath + id).then(resp => {
                    if (resp.status === 200) {
                        triggerDataUpdatedNotification();
                        props.fetchData();
                    } else {
                        throw new Error("");
                    }
                }).catch(err => {
                    alert(getErrorMessage(err, true));
                });
            }
        } else {
             const newState = {...deleteDialogState};
             newState.showDeleteDialog = true;
             newState.id = id;
             newState.name = name !== undefined ? name : "";
             setDeleteDialogState(newState);
        }
    }

    async function handleDeleteConfirm() {
        if (!deleteDialogState.error) {
            await Axios.delete(props.restPath + deleteDialogState.id).then(resp => {
                if (resp.status === 200) {
                    const newState = {...deleteDialogState};
                    newState.showDeleteDialog = !deleteDialogState.showDeleteDialog;
                    setDeleteDialogState(newState);
                    triggerDataUpdatedNotification();
                    props.fetchData();
                } else {
                    throw new Error("");
                }
            }).catch(err => {
                const newState = {...deleteDialogState};
                newState.error = getErrorMessage(err, true);
                setDeleteDialogState(newState);
            });
        }
    }

    function handleDeleteCancel() {
        const newState = {...deleteDialogState};
        newState.showDeleteDialog = false;
        newState.id = "";
        newState.name = "";
        newState.error = "";
        setDeleteDialogState(newState);
    }

    function getErrorMessage(err, forDeletion) {
        if (props.errorCodeToMessage) {
            const text = props.errorCodeToMessage(err);
            if (text) {
                return text;
            }
        }
        if (forDeletion) {
            if (err.response && err.response.data && err.response.data.inUse) {
                return props.texts.errors.deleteInUse;
            } else {
                return props.texts.errors.deleteGeneric;
            }
        } else {
            if (err && err.response && err.response.data && err.response.data.alreadyExists) {
                return props.texts.errors.alreadyExists;
            } else {
                return props.texts.errors.generic;
            }
        }
    }

    function onFormCancel() {
        if (viewMode === 'add' && props.onAddNew) {
            props.onAddNew();
        } else if (viewMode === 'edit' && props.onEdit) {
            props.onEdit();
        }
        showListView();
    }

    return (
        <div>
            <div>&nbsp;</div>
            <div className="columns is-multiline is-mobile">
                <div className="column is-12 is-mobile">
                    {viewMode === 'list' && deleteDialogState.showDeleteDialog &&
                        <DeleteDialog
                            onHandleDeleteConfirm={() => handleDeleteConfirm()}
                            onHandleDeleteCancel={() => handleDeleteCancel()}
                            showDialog={deleteDialogState.showDeleteDialog}
                            deleteConfirmText={props.texts.confirmDelete}
                            toBeDeletedName={deleteDialogState.name}
                            error={deleteDialogState.error}
                        />
                    }
                    {viewMode === 'list' &&
                        <EntityTable
                            groups={props.groups}

                            localizedAddNewText={props.texts.localizedAddNewText}
                            additionalTopContent={props.additionalTopContent}
                            entities={props.entities}
                            entityName={props.entityName}
                            headers={props.tableHeaders}
                            limitLongList={props.limitLongList}

                            isEntityEditable={props.isEntityEditable}
                            isEntityDeletable={props.isEntityDeletable}

                            onAddNew={ props.texts.localizedAddNewText ? (() => handleAddNew()) : undefined }
                            onEdit={(id) => handleEdit(props.parseId ? props.parseId(id) : Number(id))}
                            onDelete={ props.texts.confirmDelete ? ((id, name) => handleDelete(props.parseId ? props.parseId(id) : Number(id), name as string)) : undefined }
                        />
                    }
                    {viewMode !== 'list' &&
                        <EntityForm
                            error={error}
                            entities={props.entities}
                            entityName={props.editor.entityName}
                            formFields={props.formFields}
                            editMode={viewMode}
                            onFormChange={(e) => handleFormChange(e)}
                            onFormChangeDate={(date, name) => handleFormChangeDate(date, name)}
                            onFormCheckboxChange={(e) => handleFormCheckboxChange(e)}
                            onFormSubmit={(e) => (viewMode === 'add') ? handleAddFormSubmit(e) : handleEditFormSubmit(e, selectedId)}
                            onCancel={() => onFormCancel()}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default EntityManagement;
