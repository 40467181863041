import React, { useState, useEffect } from 'react';
import MixedChart from './MixedChart';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import * as logger from "../utilities/logger";
import { authenticatedFilteredAxiosGet, round, constructCancelObject, groupBy, getSectionBackgroundColor } from '../utilities/generic';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import { FilterSelections } from '../reducers/filterSelections';
import ScaleSelectButtons, { ScaleOptions, generateTimestamps, formatTimestamps } from './ScaleSelectButtons';
import SectionPanel from './SectionPanel';

function NoxReductionEfficiencyMixed(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    authState: AuthState,
    filterSelections: FilterSelections,
    noxReduction
}>) {

    const { t } = useTranslation();

    const [state, setState] = useState(
        {
            scale: 'day' as ScaleOptions,
            labels: [] as string[],
            ppmSerie: [] as Array<number | undefined>,
            loading: false,
            error: false
        }
    );

    useEffect(
        () => { getNoxReductionsIntervals(state.scale); },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            props.startDate,
            props.endDate,
            props.authState.isAuthenticated,
        ])
    );

    const [cancelObject] = useState(constructCancelObject());

    function isEngineerOrEngineerAdmin() {
        return props.authState.groups.expert || props.authState.groups.technician;
    }

    async function getNoxReductionsIntervals(scale: ScaleOptions) {
        if (props.startDate && props.endDate) {
            const startTimestamps = generateTimestamps({ startDate: props.startDate, endDate: props.endDate, scale });
            const labels = formatTimestamps({ startTimestamps, scale });
            try {
                setState({scale: 'day', labels, ppmSerie: [], loading: true, error: false });
                const startDate = moment(props.startDate).toISOString();
                const end = moment(props.endDate).toISOString();
                const noxReduction = await authenticatedFilteredAxiosGet(`/v10/reduction/${startDate}/${end}/${scale}`, props.authState.isAuthenticated, props.filterSelections, cancelObject);
                const noxReductionDataGroupedByTime = groupBy<{ noxPercentReductionFromPpm: number }>(noxReduction.data, 'startTimestamp');
                const series = startTimestamps.map(ts => ts.toISOString()).map(isoDateStr => noxReductionDataGroupedByTime[isoDateStr] ? noxReductionDataGroupedByTime[isoDateStr][0] : undefined);

                setState({
                    labels,
                    ppmSerie: series.map(item => {
                        if (typeof item === 'undefined') {
                            return undefined;
                        } else {
                            if (isEngineerOrEngineerAdmin() && item.noxPercentReductionFromPpm < 0) {
                                return 0;
                            } else {
                                return round(item.noxPercentReductionFromPpm, 2);
                            }
                        }
                    }),
                    scale: scale,
                    loading: false,
                    error: false
                });
            } catch (err) {
                if (!err.isCancel) {
                    logger.err(err.message || err);
                    setState({scale: 'day', labels, ppmSerie: [], loading: false, error: true });
                }
            }
        }
    }

    function isBusy() {
        return (state.loading || (props.noxReduction && props.noxReduction.loading)) && !inError();
    }

    function inError() {
        return (props.noxReduction && props.noxReduction.error) || state.error;
    }

    function getSectionState() {
        if (inError()) {
            return "error";
        } else if (isBusy()) {
            return "busy";
        } else {
            return "ok";
        }
    }

    const detailsStyle = { display: 'inline-block', marginRight: 15 };

    return (
        <div className="box" style={{position: 'relative'}}>
            <div className="media-content">
                <div className="content">
                    <div className="columns is-multiline">
                        <div className="column is-12 is-paddingless is-centered" style={{ marginBottom: '10px' }}>
                            <strong>{t('NOx Purification Performance')}</strong>
                            <div className="column is-12 is-paddingless is-centered">
                                {/* Spacer to make this same size than FleetStatusSection component */}
                                <div style={detailsStyle}>
                                </div>
                                <br/>
                                <div style={detailsStyle}>
                                </div>
                            </div>
                            <div className="column is-mobile is-centered">
                                <ScaleSelectButtons
                                    selectedScale={state.scale}
                                    disabledButtons={isBusy()}
                                    onScaleSelect={getNoxReductionsIntervals}
                                />
                            </div>
                        </div>
                        <div className="column is-12" style={{ backgroundColor: getSectionBackgroundColor(inError(), isBusy()) }}>
                            <SectionPanel
                                state={getSectionState()}
                                child={
                                    <MixedChart
                                        data={
                                            {
                                                line: {title: t("Long time average"),
                                                        serie: state.ppmSerie ? state.ppmSerie.map(() => round(props.noxReduction && props.noxReduction.fromPpm, 2)) : undefined, type: 'line', color: 'rgba(255,99,132,0.2)'},
                                                bar2: {title: t('NOx Purification Performance'), serie: state.ppmSerie, type: 'bar', color: '#00ff00'}
                                            }
                                        }
                                        labels={state.labels}
                                    />
                                }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoxReductionEfficiencyMixed;
