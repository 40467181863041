import * as actionTypes from '../../Actions/Actions';

export interface DistanceReduction {
  meters: number;
  loading: boolean;
  error: boolean;
}

export default function reducer(state: DistanceReduction, action) {
  switch (action.type) {
    case actionTypes.SET_DISTANCES:
      return {
        meters: action.meters,
        loading: typeof action.loading !== 'undefined' ? action.loading : false,
        error: typeof action.error !== 'undefined' ? action.error : false,
      };
    default:
      return state;
  }
}
