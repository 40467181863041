import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import * as logger from "../utilities/logger";
import RouteContext from '../contexts/RouteContext';
import * as actionTypes from '../Actions/Actions';

import HomeSummary from './HomeSummary';
import FaultChartSection from './FaultChartSection';
import VehicleTable from './VehicleTable';
import DepotTable from './DepotsTable';
import SensorDataChartSection from './SensorDataChartSection';
import { authenticatedFilteredAxiosGet, constructCancelObject } from '../utilities/generic';

import VehicleStats from './VehicleStats';
import CsvExportDialog from './CsvExportDialog';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import Map from './Map';
import { FilterSelections } from '../reducers/filterSelections';
import FleetStatusSection from './FleetStatusSection';
import NoxReductionEfficiencyMixed from './NoxReductionEfficiencyMixed';
import { useTranslation } from 'react-i18next';

function HomeView(props: RouteComponentProps & Readonly<{
    authState: AuthState,
    filterSelections: FilterSelections,
    startDate: Date,
    endDate: Date | undefined,
    distanceReduction,
    vehicleCount: number,
    increaseLoaderCount: () => void,
    decreaseLoaderCount: () => void,
}>) {

    const { t } = useTranslation();

    const context = useContext(RouteContext);

    const [mapAndSensorGraphTimeRange, setMapAndSensorGraphTimeRange] = useState<{
        startDate: Date,
        endDate: Date | undefined,
    }>(
        {
            startDate: new Date(),
            endDate: new Date(),
        }
    );

    const [noxFleetReduction, setNoxFleetReduction] = useState(
        {
        period: {} as {
            fromPpm: number | null,
            kg: number | null,
            runningTime: number | null,
            startTimestamp: moment.Moment | null,
        } | null,
        loading: true,
        error: false
    });

    const [getFleetNoxReductionForTimeRangeCancelObject] = useState(constructCancelObject());
    async function getFleetNoxReduction() {
        if (props.startDate) {
            const startDate = moment(props.startDate).toISOString();
            const endDate = moment(props.endDate).toISOString();
            const obj = { ...noxFleetReduction };
            obj.loading = true;
            setNoxFleetReduction(obj);
            try {
                const noxReductionForTimeRange = await authenticatedFilteredAxiosGet(`/v10/reduction/${startDate}/${endDate}`, props.authState.isAuthenticated, props.filterSelections, getFleetNoxReductionForTimeRangeCancelObject);
                setNoxFleetReduction({
                    period: noxReductionForTimeRange.data.length === 1 ? {
                        startTimestamp: noxReductionForTimeRange.data[0].startTimestamp ? moment(noxReductionForTimeRange.data[0].startTimestamp) : null,
                        fromPpm: noxReductionForTimeRange.data[0].noxPercentReductionFromPpm,
                        kg: noxReductionForTimeRange.data[0].noxReductionKg,
                        runningTime: noxReductionForTimeRange.data[0].runningTime,
                    } : null,
                    loading: false,
                    error: false
                });
            } catch (err) {
                if (!err.isCancel) {
                    logger.err("problem while fetching reductions stuff", err);
                    obj.error = true;
                    setNoxFleetReduction(obj);
                }
                return;
            }
        }
    }

    const [getFleetDistanceCancelObject] = useState(constructCancelObject());
    async function getFleetDistance() {
        if (context) {
            // FIXME: handle query failure
            context.distanceReductionDispatch(
                {
                    type: actionTypes.SET_DISTANCES,
                    meters: 0,
                    loading: true,
                    error: false
                }
            );
            try {
                const results = await authenticatedFilteredAxiosGet(`/v10/distance`, props.authState.isAuthenticated, props.filterSelections, getFleetDistanceCancelObject);
                context.distanceReductionDispatch(
                    {
                        type: actionTypes.SET_DISTANCES,
                        meters: results.data && results.data.meters ? results.data.meters : undefined,
                    }
                );
            } catch (err) {
                if (!err.isCancel) {
                    logger.err("problem while fetching distance data", err);
                    context.distanceReductionDispatch(
                        {
                            type: actionTypes.SET_DISTANCES,
                            meters: 0,
                            error: true
                        });
                }
                return;
            }
        }
    }

    const [getFleetUtilityCancelObject] = useState(constructCancelObject());
    async function getFleetUtility() {
        if (context && props.startDate) {
            const startDate = moment(props.startDate).toISOString();
            const endDate = moment(props.endDate).toISOString();
            const results = await authenticatedFilteredAxiosGet(`/v10/utility/${startDate}/${endDate}`, props.authState.isAuthenticated, props.filterSelections, getFleetUtilityCancelObject);
            const [inUseAndVehicleCounts] = results.data;
            context.fleetUtilityDispatch(
                {
                    type: actionTypes.SET_FLEET_UTILITY,
                    // TODO: In server-side, do not query values not needed here?
                    vehicleCount: inUseAndVehicleCounts.vehicleCount,
                }
            );
        }
    }

    function isOperatorSelected() {
        return (typeof props.filterSelections.operators !== 'undefined' && props.filterSelections.operators.length > 0);
    }

    useEffect(
        () => {
            if (props.authState.isAuthenticated) {
                Promise.all([
                    getFleetDistance(),
                ]).catch((err) => {
                    if (!err.isCancel) {
                        logger.err(err.message || err);
                    }
                });
            }
        },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            props.authState.isAuthenticated,
        ])
    );

    useEffect(
        () => {
            if (props.authState.isAuthenticated) {
                Promise.all([
                    getFleetNoxReduction(),
                    getFleetUtility(),
                ]).catch((err) => {
                    if (!err.isCancel) {
                        logger.err(err.message || err);
                    }
                });
            }
        },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            props.startDate,
            props.endDate,
            props.authState.isAuthenticated,
        ])
    );

    useEffect(
        () => {
            setMapAndSensorGraphTimeRange({
                startDate: props.startDate,
                endDate: props.endDate,
            });
        },
        [
            props.startDate,
            props.endDate,
        ]
    );

    function onMapVehicleIdSelected(id: number) {
        if (context) {
            context.filterSelectionsDispatch({
                type: actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION,
                fixedVehiclesSelection: [ { id } ],
            });
        }
    }

    const sensorDataChartSectionRef = useRef<HTMLDivElement | null>();

    function onSelectionFaultCodeTimestamp(timestamp: Date | undefined) {
        if (timestamp && props.authState.maxBucketCountForSensorDataFetch) {
            const endDate = new Date(timestamp);
            const startDate = new Date(timestamp);
            startDate.setSeconds(startDate.getSeconds() - props.authState.maxBucketCountForSensorDataFetch);
            setMapAndSensorGraphTimeRange({startDate, endDate});
            if (sensorDataChartSectionRef.current) {
                sensorDataChartSectionRef.current.scrollIntoView();
                window.scrollBy(0, -150); // Hack to scroll a bit up because of the top navigation
            }
        }
    }

    function isEngineerOrEngineerAdmin() {
        return props.authState.groups.expert || props.authState.groups.technician;
    }

    const selectedVehicles = props.filterSelections.fixedVehiclesSelection ? props.filterSelections.fixedVehiclesSelection : props.filterSelections.vehicles;

    return (
        <div>
            <HomeSummary
                authState={props.authState}
                filterSelections={props.filterSelections}
            />
            {isOperatorSelected() &&
                <DepotTable
                    startDate={props.startDate}
                    endDate={props.endDate}
                    authState={props.authState}
                    filterSelections={props.filterSelections}
                />
            }
            <div className="columns">
                <div className="column is-6">
                    <FleetStatusSection
                        startDate={props.startDate}
                        endDate={props.endDate}
                        authState={props.authState}
                        filterSelections={props.filterSelections}
                        vehicleCount={props.vehicleCount}
                        noxReduction={noxFleetReduction && noxFleetReduction.period}
                    />
                </div>
                <div className="column is-6">
                    <NoxReductionEfficiencyMixed
                        startDate={props.startDate}
                        endDate={props.endDate}
                        authState={props.authState}
                        filterSelections={props.filterSelections}
                        noxReduction={noxFleetReduction && noxFleetReduction.period}
                    />
                </div>
            </div>
            {!isEngineerOrEngineerAdmin() &&
                <FaultChartSection
                    authState={props.authState}
                    filterSelections={props.filterSelections}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedVehicleIds={selectedVehicles.map(v => v.id)}
                    onSelectionFaultCodeTimestamp={onSelectionFaultCodeTimestamp}
                />
            }
            {(isOperatorSelected() || props.filterSelections.vehicles.length > 0 || (props.filterSelections.fixedVehiclesSelection && props.filterSelections.fixedVehiclesSelection.length > 0)) &&
                <VehicleTable
                    selectedVehicleIds={props.filterSelections.fixedVehiclesSelection ? props.filterSelections.fixedVehiclesSelection.map(v => v.id) : undefined}
                    authState={props.authState}
                    filterSelections={props.filterSelections}
                    startDate={props.startDate}
                    endDate={props.endDate}
                />
            }
            {(isOperatorSelected() || selectedVehicles.length > 0) && context && context.eppClientInstance &&
                <Map
                    eppClientInstance={context.eppClientInstance}
                    showMap={(props.authState.isAuthenticated && !props.authState.hideLocation)}
                    startDate={mapAndSensorGraphTimeRange.startDate}
                    endDate={mapAndSensorGraphTimeRange.endDate}
                    vehicles={selectedVehicles}
                    operators={props.filterSelections.operators}
                    site={props.filterSelections.site}
                    bodyManufacturerId={props.filterSelections.bodyManufacturer}
                    bodyModelId={props.filterSelections.bodyModel}
                    engineManufacturerId={props.filterSelections.engineManufacturer}
                    engineModelId={props.filterSelections.engineModel}
                    onVehicleIdSelected={onMapVehicleIdSelected}
                />
            }
            {(selectedVehicles.length === 1) &&
                <SensorDataChartSection
                    ref={elem => {
                        sensorDataChartSectionRef.current = elem;
                    }}
                    isAuthenticated={props.authState.isAuthenticated}
                    vehicle={selectedVehicles[0].id}
                    startDate={mapAndSensorGraphTimeRange.startDate}
                    endDate={mapAndSensorGraphTimeRange.endDate}
                    longScaleStartDate={props.startDate}
                    longScaleEndDate={props.endDate}
                    secondsForZoomToLevelOfSeconds={props.authState.maxBucketCountForSensorDataFetch}
                    onTimeRangeChange={setMapAndSensorGraphTimeRange}
                />
            }
            {!props.authState.groups.official && !(props.authState.groups.installer || props.authState.groups.installerManager) &&
                selectedVehicles.length === 1 &&
                <div className="columns is-fullheight">
                    <div className="column is-full">
                        <div className="container box" style={{overflow: 'auto'}}>
                            <nav className="level">
                                <div className="level-left">
                                    <strong>{t('Daily average performance')}</strong>
                                </div>
                                <div className="level-right">
                                    <div className="level-item">
                                        <CsvExportDialog
                                            startDate={props.startDate}
                                            endDate={props.endDate}
                                            vehicle={selectedVehicles[0].id}
                                        />
                                    </div>
                                </div>
                            </nav>
                            <VehicleStats
                                vehicle={selectedVehicles[0].id}
                                startDate={props.startDate}
                                endDate={props.endDate}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default withRouter(HomeView);
