import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from "react-dropdown-select";
import { formatDate, getDateFormat } from '../utilities/generic';

export interface Entity extends AbstractEntity<number> {
}

export interface NamedEntity extends Entity {
    name: string;
}

export interface AbstractEntity<IdType> {
    id: IdType;
    name?: string;
}

export interface FormField {
    label: string;
    name: string;
    allowedValues?: { entityName: string, name: string, preSelectOnlyOption?: boolean, multi?: boolean, disabled?: boolean, multiSelection?: boolean };
    placeholder?: string;
    type?: 'string' | 'number' | 'email' | 'multipleEmails' | 'password' | 'date' | 'checkbox';
    maxLength?: number;
    maxValue?: number;
    readOnly?: "readOnly" | "readOnlyOnEdit" | undefined;
    autofocusOnEdit?: boolean;
    visible?: boolean;
}

export interface Entities<IdType extends (string | number)> {
    [entityName: string]: AbstractEntity<IdType> | Array<AbstractEntity<IdType>>;
}

function EntityForm<IdType extends (string | number)>(props: Readonly<{
    onFormChange: (event) => void,
    onFormChangeDate: (date, name) => void,
    onFormCheckboxChange: (event) => void,
    onFormSubmit: (event: FormEvent) => void,
    onCancel: () => void,
    entityName: string,
    entities: Entities<IdType>,
    formFields: FormField[],
    error: string,
    editMode: 'list' | 'add' | 'edit',
}>) {

    const { t } = useTranslation();

    function renderSelect(field: FormField, autofocus: boolean) {
        if (!field.allowedValues) {
            throw new Error("Internal error");
        }
        const allowedValues = props.entities[field.allowedValues.entityName];
        if (!Array.isArray(allowedValues)) {
            throw new Error("Internal Error");
        }
        const value = props.entities[props.entityName][field.name];

        if (field.readOnly && field.readOnly === "readOnly") {
            const displayValue = allowedValues.find(aValue => aValue.id === value);
            return displayValue && displayValue[field.allowedValues.name];
        } else {
            const values = props.entities[props.entityName][field.name];

            const selectedValues = (Object.keys(allowedValues).length === 1 && field.allowedValues.preSelectOnlyOption) ?
                allowedValues :
               (values && values !== null && typeof values !== 'object') ?
                    allowedValues.filter(item => item.id === values) :
                    (values && values !== null && props.editMode === "edit") ?
                        allowedValues.filter(aValue => values.indexOf(aValue.id) !== -1) :
                        undefined;
            return (
                <Select
                    options={allowedValues}
                    disabled={((Object.keys(allowedValues).length === 1) && field.allowedValues.preSelectOnlyOption) || (props.editMode === 'edit' && field.readOnly === "readOnlyOnEdit") ? true : false}
                    values={selectedValues}
                    valueField="id"
                    labelField={field.allowedValues.name}
                    placeholder={field.label}
                    dropdownHandle={true}
                    clearable={(allowedValues && allowedValues.length > 0)}
                    multi={field.allowedValues.multi}
                    onChange={(someValues) => {
                        const ids = field.allowedValues!.multi ? someValues.map(element => element.id) : (someValues.length > 0) ? someValues[0].id : undefined;
                        const evt = { target: { name: field.name, value: ids } };
                        props.onFormChange(evt);
                    }}
                    searchBy="name"
                >
                </Select>
            );
        }
    }

    function renderInput(field: FormField, autofocus: boolean) {
        const fieldValue = props.entities[props.entityName][field.name];
        const value = field.type === 'date' && fieldValue ? formatDate(moment(fieldValue), "date") : fieldValue;
        const className = field.name === 'secret' ? ' loginpwd' : '';

        return (<input
            name={field.name}
            className={"input is-medium" + className}
            type={(field.type === 'multipleEmails' ? 'email' : field.type) || "string"}
            multiple={field.type === 'multipleEmails' ? true : undefined}
            placeholder={field.placeholder}
            value={typeof value === 'undefined' || value === null ? '' : value}
            onChange={props.onFormChange}
            autoFocus={props.editMode === 'edit' ? field.autofocusOnEdit : autofocus}
            maxLength={field.maxLength}
            disabled={(props.editMode === 'edit' && field.readOnly === "readOnlyOnEdit") || (field.readOnly === "readOnly")}
            max={field.maxValue}
        />);
    }

    function renderCheckbox(field: FormField, autofocus: boolean) {
        return (<input
            name={field.name}
            className="checkbox is-medium"
            type="checkbox"
            onChange={props.onFormCheckboxChange}
            checked={props.entities[props.entityName][field.name] || false}
        />);
    }

    function renderDatePicker(field: FormField, autofocus: boolean) {
        const fieldValue = props.entities[props.entityName][field.name];
        const value = field.type === 'date' && fieldValue ? moment(fieldValue).toDate() : fieldValue;
        const maxDate = moment(new Date()).add(1, 'month').toDate();

        return (<DatePicker
            dateFormat={ getDateFormat('date', 'dateFnsFormat') }
            selected={value}
            onChange={(date) => props.onFormChangeDate(date, field.name)}
            name={field.name}
            maxDate={maxDate}
            autoComplete='off'
        />);

    }

    function isVisible(field: FormField) {
        return field.hasOwnProperty('visible') ? field.visible : true;
    }

    return (
        <div>
            <form onSubmit={(event) => props.onFormSubmit(event)}>
                {props.error !== '' &&
                    <div style={{ color: 'red', fontWeight: 'bold', marginBottom: "10px" }}>{props.error}</div>
                }
                {props.formFields.map((field, index) => {
                    if (isVisible(field)) {
                        const autoFocus = index === 0;
                        return (
                            <div key={index} className="field">
                                <label className="label">{t(field.label)}</label>
                                {field.allowedValues && renderSelect(field, autoFocus)}
                                {(field.placeholder && field.type !== "date") && renderInput(field, autoFocus)}
                                {field.type === "checkbox" && renderCheckbox(field, autoFocus)}
                                {field.type === "date" && renderDatePicker(field, autoFocus)}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
                <div className="column">
                    <input
                        type='submit'
                        className="button is-medium is-4 is-rounded is-pulled-left"
                        value={t("Submit") as string}
                        style={{
                            backgroundColor: '#015B9F',
                            color: 'white',
                            marginBottom: '10px',
                            marginTop: '10px',
                            marginRight: '10px'
                        }}
                    />
                    <button
                        className="button is-medium is-4 is-rounded is-pulled-left"
                        type="button"
                        onClick={props.onCancel}
                        style={{ backgroundColor: '#f4c326', color: 'white', marginBottom: '10px', marginTop: '10px' }}
                    >{t('Cancel')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EntityForm;
