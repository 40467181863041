import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'chartjs-plugin-zoom';
import 'hammerjs';
import { Line } from 'react-chartjs-2';
import { ChartDataSets } from 'chart.js';
import { calculateZoomTimeRange, timeRangeMinDate, timeRangeMaxDate } from './TimeRange';
import { constructCancelObject, cancellableAxiosCall, getDateFormat, round, patchedLegendClickHandler } from '../utilities/generic';
import * as logger from "../utilities/logger";
import SectionPanel from './SectionPanel';

interface SensorDataChartLineData {
    title: string;
    hiddenByDefault?: true;
    color: string;
    serie: Array<{ x: Date, y: number }>;
    yAxisId?: 'percentage' | 'reductionPercentage' | 'ppm' | 'temp' | 'mgs' | 'dosing_rate' | 'maf' | 'pump_state' | 'speed' | 'backpressure' | 'doser_pressure';
    unit: string;
}
interface Yaxes {
    type: string;
    display: string;
    position: string;
    id: string;
    ticks?: {
        min: undefined;
        max: undefined;
    };
    afterFit?: (string) => void;
    scaleLabel: {
        display: boolean;
        labelString: string;
    };
}

function SensorDataChart(props: Readonly<{
    isAuthenticated: boolean,
    vehicle: number | undefined,
    startDate: Date,
    endDate: Date | undefined,
    longScaleStartDate: Date | undefined,
    longScaleEndDate: Date | undefined,
    onTimeRangeChange: (timerange: {
        startDate: Date,
        endDate: Date | undefined,
    }) => void,
    selectedAggregate: 'avg' | 'min' | 'max',
    hideState: 'defaults' | 'none' | 'all',
    positionAxelLabelsToLeft?: boolean,
    hideLegends?: boolean,
    hideScrollBar?: boolean,
    enableTouch?: boolean,
    showXaxesLabelInsteadOfTicks?: boolean,
    onPan?: () => void,
}>) {

    const { t } = useTranslation();

    const [state, setState] = useState<{
        chartStartDate: Date,
        chartEndDate: Date | undefined,
    }>(
        {
            chartStartDate: new Date(),
            chartEndDate: new Date(),
        }
    );
    const stateRef = useRef(state);
    stateRef.current = state;

    const [chartWideDivWidth, setChartWideDivWidth] = useState<number>();
    const [scrollbarPosInPx, setScrollbarPosInPx] = useState<number>(0);
    const [shouldSetChartTimes, setShouldSetChartTimes] = useState(false);
    const [oldScrollLeftPos, setOldScrollLeftPos] = useState<number>(0);
    const [scrollPosOnLargeTimeScale, setScrollPosOnLargeTimeScale] = useState<number>();
    const [widthOfDiv, setWidthOfDiv] = useState<number>();
    const [keyPressed, setkeyPressed] = useState(false);

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [inError, setInError] = useState<boolean>(false);

    useEffect(
        () => {
            setInError(false);
            setState({
                chartStartDate: props.startDate,
                chartEndDate: props.endDate,
            });
            if (props.isAuthenticated) {
                getSensorData(props.startDate, props.endDate);
                counIndicatorDiv(props.startDate, props.endDate);
            }
            centerScrollBar();
        },
        [
            props.startDate,
            props.endDate,
            props.longScaleStartDate,
            props.longScaleEndDate,
            props.isAuthenticated,
            props.vehicle,
        ]
    );

    useEffect(
        () => {
            const scrollContainer = document.getElementById('ScrollContainer');
            if (scrollContainer) {
                scrollContainer.scrollLeft = scrollbarPosInPx;
            }
        },
        [
            scrollbarPosInPx,
        ]
    );

    useEffect(
        () => {
            const scrollContainer = document.getElementById('chartContainer');
            if (scrollContainer) {
                scrollContainer.addEventListener("wheel", zoomChartWithMouse, { passive: false });
            }
            return () => {
                if (scrollContainer) {
                    scrollContainer.removeEventListener("wheel", zoomChartWithMouse);
                }
            };
        }
    );

    useLayoutEffect(() => {
        window.addEventListener('resize', centerScrollBar);
        return () => window.removeEventListener('resize', centerScrollBar);
    }, []);

    function getSectionState() {
        if (inError) {
            return "error";
        } else if (isBusy) {
            return "busy";
        } else {
            return "ok";
        }
    }

    const [sensorDataApiResult, setSensorDataApiResult] = useState<{ sensorData: Array<{ [key: string]: number }>, useAggregateTable?: boolean }>();
    const [queryTakesTooLong, setQueryTakesTooLong] = useState(false);
    const [cancelObject] = useState(constructCancelObject());

    async function getSensorData(startDateValue: Date | undefined, endDateValue: Date | undefined, doneCb?: () => void) {
        if (typeof endDateValue === 'undefined' || typeof startDateValue === 'undefined') {
            setSensorDataApiResult(undefined);
        } else {
            if (props.vehicle) {
                let queryTakesLongEnoughTimer: ReturnType<typeof setTimeout> | undefined;
                try {
                    setIsBusy(true);
                    setInError(false);
                    setQueryTakesTooLong(false);
                    const queryTakesTooLongTimeoutMs = 5000;
                    queryTakesLongEnoughTimer = setTimeout(() => {
                        setSensorDataApiResult(undefined);
                        setQueryTakesTooLong(true);
                    }, queryTakesTooLongTimeoutMs);
                    const startDate = moment(startDateValue).toISOString();
                    const endDate = moment(endDateValue).toISOString();
                    const results = await cancellableAxiosCall({ url: `/v10/sensorData/${props.vehicle}/${startDate}/${endDate}` }, cancelObject);
                    const resultSensorData = results.data;
                    if (Array.isArray(resultSensorData.sensorData) && resultSensorData.sensorData.length >= 1) {
                        if (resultSensorData.sensorData.length > 10000) {
                            throw new Error("Internal error: Server returned too much data to render to screen");
                        }
                        setSensorDataApiResult(resultSensorData);
                    } else {
                        setSensorDataApiResult(undefined);
                    }
                    if (doneCb) {
                        doneCb();
                    }
                } catch (err) {
                    if (!err.isCancel) {
                        setInError(true);
                        logger.err(err.message || err);
                        setSensorDataApiResult(undefined);
                    } // In case of cancelling of query, don't empty old query result -- this ensures smoother horizontal scrolling of graph
                } finally {
                    setIsBusy(false);
                    setQueryTakesTooLong(false);
                    if (queryTakesLongEnoughTimer) {
                        clearTimeout(queryTakesLongEnoughTimer);
                    }
                }
            } else {
                setSensorDataApiResult(undefined);
            }
        }
    }

    const reductionPercentageMinMax = {
        min: undefined,
        max: undefined,
    };

    function constructSeriePoint(item: { [key: string]: number }, valueName: string, hardLimitForMin?: number, hardLimitForMax?: number, foundMinMax?: { min: number | undefined, max: number | undefined }) {
        const v = item[props.selectedAggregate + valueName];
        const valueNumberOrUndefined = typeof v !== 'undefined' && v !== null ? Number(v) : undefined;
        if (typeof foundMinMax !== 'undefined' && typeof valueNumberOrUndefined === 'number') {
            if (typeof hardLimitForMin === 'number' && (
                typeof foundMinMax.min === 'undefined' || valueNumberOrUndefined < foundMinMax.min
            )) {
                foundMinMax.min = Math.max(valueNumberOrUndefined, hardLimitForMin);
            }
            if (typeof hardLimitForMax === 'number' && (
                typeof foundMinMax.max === 'undefined' || valueNumberOrUndefined > foundMinMax.max
            )) {
                foundMinMax.max = Math.min(valueNumberOrUndefined, hardLimitForMax);
            }
        }
        return {
            x: new Date(item.startTime),
            y: valueNumberOrUndefined,
            // tslint:disable-next-line: no-any
        } as any; // TODO: Remove this "any"
    }

    const sensorDataApiData = sensorDataApiResult && sensorDataApiResult.sensorData ? sensorDataApiResult.sensorData : [];
    const sensorData: SensorDataChartLineData[] =
        ((props.selectedAggregate === 'avg' ? [ // Don't show NOx Reduction ppm and mg/s when min or max selected because they are not currently returned by API
            {
                title: t('NOx Reduction ppm'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'ReductionByPpm', -20, 100, reductionPercentageMinMax)),
                color: 'rgb(185, 122, 87)',
                yAxisId: 'reductionPercentage',
                unit: "%",
            },
            {
                title: t('NOx Reduction mg/s'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'ReductionByMgs', -20, 100, reductionPercentageMinMax)),
                color: 'rgb(255, 201, 13)',
                yAxisId: 'reductionPercentage',
                unit: "%",
            },
        ] : []) as SensorDataChartLineData[]).concat([
            {
                title: t('NOx In ppm'),
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'NoxIn')),
                color: 'rgb(0, 0, 254)',
                yAxisId: 'ppm',
                unit: "ppm",
            },
            {
                title: t('NOx Out ppm'),
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'NoxOut')),
                color: 'rgb(251, 1, 1)',
                yAxisId: 'ppm',
                unit: "ppm",
            },
            {
                title: t('NOx In mg/s'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'InMgs')),
                color: 'rgb(0, 128, 255)',
                yAxisId: 'mgs',
                unit: "mg/s",
            },
            {
                title: t('NOx Out mg/s'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'OutMgs')),
                color: 'rgb(144, 238, 144)',
                yAxisId: 'mgs',
                unit: "mg/s",
            },
            {
                title: t('Backpressure'),
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'Backpressure')),
                color: 'rgb(163, 43, 43)',
                yAxisId: 'backpressure',
                unit: "mbar",
            },
            {
                title: t('Diesel exhaust fluid level'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'DefLevelPercent')),
                color: 'rgb(37, 175, 77)',
                yAxisId: 'percentage',
                unit: "%",
            },
            {
                title: t('Doser Pressure'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'DoserPressure')),
                color: 'rgb(1, 254, 254)',
                yAxisId: 'doser_pressure',
                unit: "bar",
            },
            {
                title: t('Dosing rate'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'DosingRate')),
                color: 'rgb(0, 99, 0)',
                yAxisId: 'dosing_rate',
                unit: "ml/h",
            },
            {
                title: t('Exhaust Inlet Temperature'),
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'TempIn')),
                color: 'rgb(253, 165, 0)',
                yAxisId: 'temp',
                unit: "°C",
            },
            {
                title: t('MAF'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'Maf')),
                color: 'rgb(128, 128, 128)',
                yAxisId: 'maf',
                unit: "kg/h",
            },
            {
                title: t('O2 In'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'O2In')),
                color: 'rgb(32, 176, 171)',
                yAxisId: 'percentage',
                unit: "%",
            },
            {
                title: t('O2 Out'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'O2Out')),
                color: 'rgb(254, 174, 201)',
                yAxisId: 'percentage',
                unit: "%",
            },
            {
                title: t('Pump State'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'PumpState')),
                color: 'rgb(128, 0, 127)',
                yAxisId: 'pump_state',
                unit: "Numero 1-4",
            },
            {
                title: t('Speed'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'Speed')),
                color: 'rgb(0, 0, 0)',
                yAxisId: 'speed',
                unit: "km/h",
            },
            {
                title: t('Temp def'),
                hiddenByDefault: true,
                serie: sensorDataApiData.map(item => constructSeriePoint(item, 'TempDef')),
                color: 'rgb(254, 254, 0)',
                yAxisId: 'temp',
                unit: "°C",
            },
        ]
        );

    const yAxes: Yaxes[] = [
        {
            type: 'linear',
            display: 'auto',
            position: 'left',
            id: 'reductionPercentage',
            ticks: reductionPercentageMinMax,
            scaleLabel: {
                display: true,
                labelString: t('NOx Reduction Percentage %')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'left',
            id: 'percentage',
            scaleLabel: {
                display: true,
                labelString: t('Percentage %')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'left',
            id: 'ppm',
            scaleLabel: {
                display: true,
                labelString: t('NOx ppm')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'left',
            id: 'temp',
            scaleLabel: {
                display: true,
                labelString: t('Temperature °C')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'left',
            id: 'mgs',
            scaleLabel: {
                display: true,
                labelString: t('mg/s')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'right',
            id: 'dosing_rate',
            scaleLabel: {
                display: true,
                labelString: t('Dosing rate ml/h')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'right',
            id: 'maf',
            scaleLabel: {
                display: true,
                labelString: t('MAF kg/h')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'right',
            id: 'pump_state',
            scaleLabel: {
                display: true,
                labelString: t('Pump State')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'right',
            id: 'speed',
            scaleLabel: {
                display: true,
                labelString: t('Speed km/h')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'right',
            id: 'backpressure',
            scaleLabel: {
                display: true,
                labelString: t('Backpressure mbar')
            }
        },
        {
            type: 'linear',
            display: 'auto',
            position: 'right',
            id: 'doser_pressure',
            scaleLabel: {
                display: true,
                labelString: t('Doser Pressure bar')
            }
        }
    ];

    yAxes.forEach( ticks => {
        ticks.afterFit = function (scale) {
            if (scale.minSize.width > 0) {
                scale.width = Math.max(scale.longestLabelWidth + 28, scale.width);
            }
        };
    });

    if (props.positionAxelLabelsToLeft) {
        yAxes.forEach( ticks => {
            ticks.position = 'left';
        });
    }

    const datasets = sensorData.map(line => {
        return {
            yAxisID: line.yAxisId || 'y-axis-1',
            label: line.title,
            hidden: props.hideState === 'defaults' ? line.hiddenByDefault : (props.hideState === 'all'),
            fill: false,
            lineTension: 0,
            borderWidth: 1.0,
            backgroundColor: line.color,
            borderColor: line.color,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            // pointBorderColor: 'rgba(75,192,192,1)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            // pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: line.serie,
            unit: line.unit,
        };
    });

    const getSensorDataByScollBarPosTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>();

    const stateRefMouse = useRef(shouldSetChartTimes);
    stateRefMouse.current = shouldSetChartTimes;

    function getSensorDataDelayed(doneCb?: (startDate: Date, endDate: Date | undefined) => void) {
        if (!getSensorDataByScollBarPosTimerRef.current) {
            getSensorDataByScollBarPosTimerRef.current = setTimeout(() => {
                getSensorDataByScollBarPosTimerRef.current = undefined;
                const startDate = stateRef.current.chartStartDate;
                const endDate = stateRef.current.chartEndDate;
                getSensorData(startDate, endDate, () => {
                    if (doneCb) {
                        doneCb(startDate, endDate);
                    }
                });
            }, 500);
        }
    }

    function setChartTimesByScrollBarPos() {
        if (state.chartStartDate && state.chartEndDate && stateRefMouse) {
            const scrollContainer = document.getElementById('ScrollContainer');
            const chartContainer = document.getElementById("chartContainer");
            if (scrollContainer && chartContainer && chartContainer.offsetWidth > 0) {
                const pixelInTime = (state.chartEndDate.getTime() - state.chartStartDate.getTime()) / chartContainer.offsetWidth;
                const timeChange = (scrollContainer.scrollLeft - oldScrollLeftPos) * pixelInTime;
                const newStartTime = moment(state.chartStartDate).add(timeChange, 'milliseconds').toDate();
                const newEndTime = moment(state.chartEndDate).add(timeChange, 'milliseconds').toDate();

                if (newStartTime > timeRangeMinDate && newEndTime < timeRangeMaxDate) {
                    counIndicatorDiv(newStartTime, newEndTime);
                    setState({ chartStartDate: newStartTime, chartEndDate: newEndTime });
                    setOldScrollLeftPos(scrollContainer.scrollLeft);
                    getSensorDataDelayed();
                }
            }
        }
    }

    function centerScrollBar() {
        const scrollContainer = document.getElementById('ScrollContainer');
        if (scrollContainer && scrollContainer.offsetWidth >= 0) {
            const currWidth = scrollContainer.offsetWidth;
            const wideDivWidth = 5 * currWidth;
            const centerOfWideDiv = (wideDivWidth - (currWidth)) / 2;
            setChartWideDivWidth(wideDivWidth);
            setScrollbarPosInPx(centerOfWideDiv);
            scrollContainer.scrollLeft = (centerOfWideDiv);
            setOldScrollLeftPos(centerOfWideDiv);
        }
    }

    function counIndicatorDiv(startTime: Date | undefined, endTime: Date | undefined) {
        if (props.longScaleStartDate && props.longScaleEndDate && startTime && endTime) {
            // Count width
            const longScaleDuration = moment(props.longScaleEndDate).valueOf() - moment(props.longScaleStartDate).valueOf();
            const chartScaleDuration = endTime.getTime() - startTime.getTime();
            const newWidthOfDiv = Math.max(Math.round((chartScaleDuration / longScaleDuration) * 100), 1);
            setWidthOfDiv(newWidthOfDiv);

            // Count position
            const startTimeDiff = moment(props.longScaleEndDate).valueOf() - startTime.getTime();
            const TimeScaleRelation = 100 - Math.round((startTimeDiff / longScaleDuration) * 100);

            setScrollPosOnLargeTimeScale(TimeScaleRelation);
        }
    }

    function setChartTimesWithKey(e: React.KeyboardEvent) {
        e.preventDefault();
        if ((e.keyCode === 37 || e.keyCode === 39) && !keyPressed) {
            setkeyPressed(true);
            const scrollContainer = document.getElementById('ScrollContainer');
            const chartContainer = document.getElementById("chartContainer");

            if (scrollContainer && state.chartEndDate && state.chartStartDate && chartContainer) {
                const pixelInTime = (state.chartEndDate.getTime() - state.chartStartDate.getTime()) / chartContainer.offsetWidth;
                const timeChange = 100 * pixelInTime;
                let newStartTime: Date;
                let newEndTime: Date;

                if (e.keyCode === 37) {
                    scrollContainer.scrollLeft -= 100;
                    newStartTime = moment(state.chartStartDate).subtract(timeChange, 'milliseconds').toDate();
                    newEndTime = moment(state.chartEndDate).subtract(timeChange, 'milliseconds').toDate();
                } else {
                    scrollContainer.scrollLeft += 100;
                    newStartTime = moment(state.chartStartDate).add(timeChange, 'milliseconds').toDate();
                    newEndTime = moment(state.chartEndDate).add(timeChange, 'milliseconds').toDate();
                }

                counIndicatorDiv(newStartTime, newEndTime);
                setState({ chartStartDate: newStartTime, chartEndDate: newEndTime });
            }
        }
    }

    function afterSettingChartTimesWithKey(e: React.KeyboardEvent) {
        if (e.keyCode === 37 || e.keyCode === 39) {
            setkeyPressed(false);
            centerScrollBar();
            if (stateRef.current.chartStartDate && stateRef.current.chartEndDate) {
                props.onTimeRangeChange({ startDate: stateRef.current.chartStartDate, endDate: stateRef.current.chartEndDate });
            }
        }
    }

    function zoomChartWithMouse(e: WheelEvent) {
        e.preventDefault();
        let zoomDirection: 'zoomIn' | 'zoomOut' | undefined;
        if (e.deltaY < 0) {
            zoomDirection = 'zoomIn';
        } else if (e.deltaY > 0) {
            zoomDirection = 'zoomOut';
        }
        if (zoomDirection && typeof state.chartStartDate !== 'undefined') {
            const newChartTimes = calculateZoomTimeRange(state.chartStartDate, state.chartEndDate, zoomDirection, false, 1.1);
            const currentDate = new Date();
            const diff = (newChartTimes.newEndDate.getTime() - currentDate.getTime());
            // IF new endDate is in future, endDate is today and add diff to startDate
            if (diff > 0) {
                newChartTimes.newEndDate = currentDate;
                newChartTimes.newStartDate = moment(newChartTimes.newStartDate).subtract(diff).toDate();
            }
            if (newChartTimes.newStartDate > timeRangeMinDate && newChartTimes.newEndDate < timeRangeMaxDate) {
                counIndicatorDiv(newChartTimes.newStartDate, newChartTimes.newEndDate);
                setState({ chartStartDate: newChartTimes.newStartDate, chartEndDate: newChartTimes.newEndDate });
                getSensorDataDelayed((startDate, endDate) => {
                    props.onTimeRangeChange({ startDate, endDate });
                });
            }
        }
    }

    const chartjsRef = useRef();

    useEffect(
        () => {
            // tslint:disable-next-line: no-any
            const current = chartjsRef.current as any;
            if (current) {
                const chart = current.chartInstance;
                if (datasets && chart) {
                    datasets.forEach((_, i) => {
                        chart.getDatasetMeta(i).hidden = props.hideState === 'defaults' ? sensorData[i].hiddenByDefault : (props.hideState === 'all');
                    });
                    chart.update();
                }
            }
        },
        [props.hideState]
    );

    return (<SectionPanel
        state={ getSectionState() }
        child={
            <>
                <div id="chartContainer" >
                {
                    !queryTakesTooLong && typeof sensorDataApiResult === 'undefined' &&
                    <div style={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginLeft: '-150px', width: '300px'  }}>
                        <p>{ t('No data in the selected time range') }</p>
                    </div>
                }
                    <Line
                        // tslint:disable-next-line: no-any
                        ref={chartjsRef as any}
                        data={{
                            datasets: datasets as ChartDataSets[]
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            // tslint:disable-next-line: no-any
                            animation: false as any,
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                displayColors: true,
                                callbacks: {
                                    label: function (tooltipItem) {
                                        let label = '';
                                        if (typeof tooltipItem!.yLabel === 'number' && datasets !== undefined && tooltipItem.datasetIndex !== undefined) {
                                            label = datasets[tooltipItem.datasetIndex].label || '';
                                            if (label) {
                                                label += ': ';
                                            }
                                            const dataset = datasets[tooltipItem.datasetIndex];
                                            if (dataset.label === "Doser Pressure") {
                                                label += round(tooltipItem.yLabel, 3) + " " + dataset.unit;
                                            } else {
                                                label += round(tooltipItem.yLabel, 0) + " " + dataset.unit;
                                            }
                                        }
                                        return label;
                                    },
                                }
                            },
                            hover: {
                                mode: 'index',
                                intersect: false,
                            },
                            elements: {
                                line: {
                                    fill: false
                                }
                            },
                            legend: {
                                display: props.hideLegends ? false : true,
                                onClick: function (e, legendItem) {
                                    // tslint:disable-next-line: no-any
                                    patchedLegendClickHandler(legendItem, this as any);
                                }
                            },
                            scales: {
                                xAxes: [
                                    {
                                        type: 'time',
                                        scaleLabel: {
                                            display:  props.showXaxesLabelInsteadOfTicks ? true : false,
                                            labelString: moment(state.chartStartDate).format(getDateFormat('datetimeWithLeadingZeroesSeconds')).toString() + ' - ' + moment(state.chartEndDate).format(getDateFormat('datetimeWithLeadingZeroesSeconds')).toString()
                                        },
                                        ticks: {
                                            min: state.chartStartDate,
                                            max: state.chartEndDate,
                                            maxRotation: 0,
                                            autoSkip: true,
                                            autoSkipPadding: 100,
                                            display: props.showXaxesLabelInsteadOfTicks ? false : true,
                                            major: {
                                                enabled: true
                                            },
                                        },
                                        time: {
                                            // unit: 'day',
                                            // unitStepSize: 1,
                                            displayFormats: {
                                                millisecond: getDateFormat("datetimeWithLeadingZeroesSeconds"),
                                                second: getDateFormat("datetimeWithLeadingZeroesSeconds"),
                                                minute: getDateFormat("datetimeWithLeadingZeroesSeconds"),
                                                hour: getDateFormat("datetimeWithLeadingZeroesSeconds"),
                                                day: getDateFormat("date"),
                                                week: getDateFormat("date"),
                                                month: getDateFormat("date"),
                                                quarter: getDateFormat("date"),
                                                year: getDateFormat("date"),
                                            }
                                        }
                                    }
                                ],
                                yAxes: yAxes,
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: props.enableTouch ? true : false,
                                        mode: 'x',
                                        rangeMin: {
                                            x: null,
                                        },
                                        rangeMax: {
                                            x: new Date(),
                                        },
                                        speed: 20,
                                        threshold: 10,
                                        onPan: () => {
                                            if (props.onPan) {
                                                props.onPan();
                                            }
                                        },
                                        onPanComplete: function ({chart}) {
                                            const [firstXAxisId] = Object.keys(chart.scales);
                                            if (firstXAxisId && chart.scales[firstXAxisId]) {
                                                const chartTicks = chart.scales[firstXAxisId].options.ticks;
                                                const endDate = new Date(chartTicks.max);
                                                const startDate = new Date(chartTicks.min);
                                                // Need to set timestate direcly, if from props graph will blink before setting new values
                                                setState({ chartStartDate: startDate, chartEndDate: endDate });
                                                props.onTimeRangeChange({ startDate, endDate });
                                            }
                                        }
                                    },
                                    // Container for zoom options
                                    zoom: {
                                        drag: !props.enableTouch ? true : false,
                                        enabled: true,
                                        mode: 'x',
                                        threshold: 20,
                                        onZoomComplete: function ({ chart }) {
                                            const [firstXAxisId] = Object.keys(chart.scales);
                                            if (firstXAxisId && chart.scales[firstXAxisId]) {
                                                const chartTicks = chart.scales[firstXAxisId].options.ticks;
                                                const startDate = new Date(chartTicks.min);
                                                let endDate = new Date(chartTicks.max);
                                                if (endDate.getTime() - startDate.getTime() < 1000) { // One second
                                                    endDate = new Date(startDate.getTime() + 1000);
                                                }
                                                props.onTimeRangeChange({ startDate, endDate });
                                            }
                                        },
                                    }
                                }
                            }
                        }}
                    />
                </div>
                {!props.hideScrollBar &&
                    <>
                        <div style={{ overflow: "hidden" }}>
                            <div style={{ height: "10px", position: "relative", width: "90%", left: "50%", transform: "translateX(-50%)" }}>
                                <div style={{ borderBottom: "darkgrey 1px solid", width: "100%", height: "5px" }} >&nbsp;</div>
                                <div style={{ background: "#015B9F", position: "absolute", top: "0px", left: scrollPosOnLargeTimeScale + "%", width: widthOfDiv + "%", height: "10px" }} >&nbsp;</div>
                            </div>
                        </div>
                        <div style={{ overflowX: "scroll", overflowY: "hidden", width: "90%", height: "25px", margin: "0 auto" }}
                            id="ScrollContainer" onScroll={() => {
                                if (shouldSetChartTimes) {
                                    setChartTimesByScrollBarPos();
                                }
                            }}
                            onMouseDown={() => setShouldSetChartTimes(true)}
                            onMouseUp={() => {
                                setTimeout(() => { // This timer is hack to avoid implementation to take the scroll position during the browser scroll bar move animation
                                    centerScrollBar();
                                    setShouldSetChartTimes(false);
                                    if (stateRef.current.chartStartDate && stateRef.current.chartEndDate) {
                                        props.onTimeRangeChange({startDate: stateRef.current.chartStartDate, endDate: stateRef.current.chartEndDate});
                                    }
                                }, 100);
                            }}
                            tabIndex={1}
                            onKeyDown={(e) => setChartTimesWithKey(e)}
                            onKeyUp={(e) => afterSettingChartTimesWithKey(e)}>
                            <div style={{ width: chartWideDivWidth, height: "1px" }}></div>
                        </div>
                    </>
                }
            </>
        }
    />);
}

export default SensorDataChart;
