// import React, { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Resources
import FilterSelectorsMobile from './FilterSelectorsMobile';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { onHamburgerMenuClick } from '../utilities/menu-helper';

function NavBarMobile(props: Readonly<{
    authState: AuthState,
    logoutHandler: () => Promise<void>,
    setMobileMenuSelection: (selectedPage: 'homepage' | 'sensorGraph' | 'faultSection') => void,
    changeToDesktopOrMobileView: () => void,
}>) {

    const { t } = useTranslation();

    return (
        <>
            { props.authState.isAuthenticated &&
                <nav className="navbar is-light is-fixed-top mobile is-mobile"
                    role="navigation"
                    aria-label="main navigation"
                    style={{ backgroundColor: '#015B9F'}}
                >
                    <section className="container">

                        <div className="navbar-brand">
                        <FilterSelectorsMobile/>
                        <div className="spacer" style={{width: '16px'}}>&nbsp;</div>
                            <span
                                className="nav-toggle navbar-burger"
                                onClick={onHamburgerMenuClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </div>
                        <div className="navbar-menu" style={{backgroundColor: '#015B9F'}}>
                            <div className="navbar-start" style={{textAlign: "center", fontSize: "22px"}}>
                                <a className="navbar-item" href="/#" onClick={() => props.setMobileMenuSelection('homepage')} style={{ color: '#fff', borderTop: '1px solid #fff' }}>
                                    {t('Overview')}
                                </a>
                                <a className="navbar-item" href="/#" onClick={() => props.setMobileMenuSelection('sensorGraph')} style={{ color: '#fff', borderTop: '1px solid #fff' }}>
                                    <p>{t('Sensor Graph')}</p>
                                </a>
                                <a className="navbar-item" href="/#" onClick={() => props.setMobileMenuSelection('faultSection')} style={{ color: '#fff', borderTop: '1px solid #fff' }}>
                                    <p>{t('Fault Section')}</p>
                                </a>
                                <a className="navbar-item" href="/#" onClick={() => props.changeToDesktopOrMobileView()} style={{ color: '#fff', borderTop: '1px solid #fff' }}>
                                    <p>{t('Change to Desktop Mode')}</p>
                                </a>
                                <a className="navbar-item" href="/#" onClick={props.logoutHandler} style={{ color: '#fff', borderTop: '1px solid #fff' }}>
                                    <p>{t('Log Out')} {props.authState.userName}</p>
                                </a>
                            </div>
                        </div>
                    </section>
                </nav>
            }
        </>
    );
}

export default NavBarMobile;
