export type LocalStorageKey = 'selectedCountry' | 'selectedCity' | 'selectedOperators' | 'selectedSite';

export function writeItemToLocalStorage(key: LocalStorageKey, value: string) {
  if (key) {
    localStorage.setItem(key, value);
  }
}

export function getItemsFromLocalStorage(keys: LocalStorageKey[]) {
  return keys.map(key => (key && localStorage.getItem(key)) || undefined);
}

export function removeItemFromLocalStorage(key: LocalStorageKey) {
  if (key) {
    localStorage.removeItem(key);
  }
}
