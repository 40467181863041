import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import * as logger from "../utilities/logger";
import { useTranslation } from 'react-i18next';

import FaultTableSectionMobile from './FaultTableSectionMobile';
import { authenticatedFilteredAxiosGet, constructCancelObject, getDateFormat } from '../utilities/generic';

import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import { FilterSelections } from '../reducers/filterSelections';
import VehicleStatus from './VehicleStatus';
import SensorDataChart from '../components/SensorDataChart';
import NavBarMobile from '../components/NavbarMobile';
import SectionPanel from './SectionPanel';
import AppFooterMobile from '../AppFooterMobile';
import { calculateZoomTimeRange, timeRangeMinDate, timeRangeMaxDate } from './TimeRange';
import zoomIn from '../resources/zoomIn.png';
import zoomOut from '../resources/zoomOut.png';

interface TimeRange {
    startDate: Date;
    endDate: Date | undefined;
}

function constructTimeRangeObj(rangeInSeconds: number) {
    return {
        startDate: new Date(Date.now() - 1000 * rangeInSeconds),
        endDate: new Date(),
    };
}

function HomeViewMobile(props: RouteComponentProps & Readonly<{
    authState: AuthState,
    filterSelections: FilterSelections,
    startDate: Date | undefined,
    endDate: Date | undefined,
    logoutHandler: () => Promise<void>,
    changeToDesktopOrMobileView: () => void,
}>) {

    const { t } = useTranslation();
    const selectedVehicles = props.filterSelections.fixedVehiclesSelection ? props.filterSelections.fixedVehiclesSelection : props.filterSelections.vehicles;
    const [menuSelection, setMobileMenuSelection] = useState<'homepage' | 'faultSection' | 'sensorGraph'>('homepage');

    const [overviewTimeRange, setOverviewTimeRange] = useState<TimeRange>(constructTimeRangeObj(3600));
    const [vehicleTimeRange, setVehicleTimeRange] = useState<TimeRange>(constructTimeRangeObj(3600));
    const [vehicleAutoUpdate, setVehicleAutoUpdate] = useState(true);

    function getRangeDiffInSecs(timeRange: TimeRange) {
        return ((timeRange.endDate ?? new Date()).getTime() - timeRange.startDate.getTime()) / 1000;
    }

    const [noxFleetReduction, setNoxFleetReduction] = useState(
        {
            period: {} as {
                fromPpm: number | null,
                kg: number | null,
                runningTime: number | null,
                startTimestamp: moment.Moment | null,
            } | null,
            loading: true,
            error: false
        });

    const [vehicleStatusLoadStatus, setVehicleStatusLoadStatus] = useState(
        {
            loading: false,
            error: false
        }
    );

    const [getFleetNoxReductionForTimeRangeCancelObject] = useState(constructCancelObject());

    async function getFleetNoxReduction() {
        const startDate = moment(overviewTimeRange.startDate).toISOString();
        const endDate = moment(overviewTimeRange.endDate).toISOString();
        const obj = { ...noxFleetReduction };
        obj.loading = true;
        setNoxFleetReduction(obj);
        try {
            const noxReductionForTimeRange = await authenticatedFilteredAxiosGet(`/v10/reduction/${startDate}/${endDate}`, props.authState.isAuthenticated, props.filterSelections, getFleetNoxReductionForTimeRangeCancelObject);
            setNoxFleetReduction({
                period: noxReductionForTimeRange.data.length === 1 ? {
                    startTimestamp: noxReductionForTimeRange.data[0].startTimestamp ? moment(noxReductionForTimeRange.data[0].startTimestamp) : null,
                    fromPpm: noxReductionForTimeRange.data[0].noxPercentReductionFromPpm,
                    kg: noxReductionForTimeRange.data[0].noxReductionKg,
                    runningTime: noxReductionForTimeRange.data[0].runningTime,
                } : null,
                loading: false,
                error: false
            });
        } catch (err) {
            if (!err.isCancel) {
                logger.err("problem while fetching reductions stuff", err);
                obj.error = true;
                setNoxFleetReduction(obj);
            }
            return;
        }
    }

    const [vehicleCount, setVehicleCount] = useState<number | undefined>();

    const [getFleetUtilityCancelObject] = useState(constructCancelObject());
    async function getFleetUtility() {
        const startDate = moment(overviewTimeRange.startDate).toISOString();
        const endDate = moment(overviewTimeRange.endDate).toISOString();
        const results = await authenticatedFilteredAxiosGet(`/v10/utility/${startDate}/${endDate}`, props.authState.isAuthenticated, props.filterSelections, getFleetUtilityCancelObject);
        const [inUseAndVehicleCounts] = results.data;
        setVehicleCount(inUseAndVehicleCounts?.vehicleCount);
    }

    useEffect(
        () => {
            if (props.authState.isAuthenticated) {
                Promise.all([
                    getFleetNoxReduction(),
                    getFleetUtility(),
                ]).catch((err) => {
                    if (!err.isCancel) {
                        logger.err(err.message || err);
                    }
                });
            }
        },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            overviewTimeRange.startDate,
            overviewTimeRange.endDate,
            props.authState.isAuthenticated,
        ])
    );

    const updateInterval = Math.ceil(getRangeDiffInSecs(vehicleTimeRange) / 20); // Update sensor data chart when 1/20 of the visible timerange time has passed

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (vehicleAutoUpdate && menuSelection === 'sensorGraph') {
            interval = setInterval(() => {
                setVehicleTimeRange(oldRange => {
                    return constructTimeRangeObj(getRangeDiffInSecs(oldRange));
                });
            }, updateInterval * 1000);
        } else if (!vehicleAutoUpdate && interval) {
            clearInterval(interval);
            interval = undefined;
        }
        return () => {
            if (interval) {
                clearInterval(interval);
                interval = undefined;
            }
        };
    }, [vehicleAutoUpdate, updateInterval, menuSelection]);

    function setStartAndEndTimesByUsingTimerange(newTimerange: number) {
        const rangeObj = constructTimeRangeObj(newTimerange);
        setOverviewTimeRange(rangeObj);
        setVehicleTimerangeAndCheckAutoUpdateState(rangeObj);
    }

    function getStatusSectionState() {
        if (vehicleStatusLoadStatus.error) {
            return "error";
        } else if (vehicleStatusLoadStatus.loading) {
            return "busy";
        } else {
            return "ok";
        }
    }

    function showPleaseSelectVehicle() {
        return <div style={{ position: 'absolute', top: '50%', width: '98%', textAlign: 'center' }} >
            <p>{t('Please select a vehicle')}</p>
        </div>;
    }

    function isEndTimeCloseToNow(timeRange: TimeRange) {
        return !timeRange.endDate || (Math.abs(new Date().getTime() - timeRange.endDate.getTime()) / 1000) < 10;
    }

    function quickSetTimeRange(selection: 'zoomOut' | 'zoomIn') {
        const { newStartDate, newEndDate } = calculateZoomTimeRange(vehicleTimeRange.startDate, vehicleTimeRange.endDate, selection, false);
        if (newStartDate > timeRangeMinDate && newEndDate < timeRangeMaxDate) {
            setVehicleTimerangeAndCheckAutoUpdateState({ startDate: newStartDate, endDate: newEndDate });
        }
    }

    function setVehicleTimerangeAndCheckAutoUpdateState(timeRange: {
        startDate: Date,
        endDate: Date | undefined,
    }) {
        setVehicleAutoUpdate(isEndTimeCloseToNow(timeRange));
        setVehicleTimeRange(timeRange);
    }

    const timeRangeSelection = menuSelection === 'homepage' ?
        getRangeDiffInSecs(overviewTimeRange) :
        (isEndTimeCloseToNow(vehicleTimeRange) ? getRangeDiffInSecs(vehicleTimeRange) : -1);
    const fiveMinutes = 60 * 5;
    const oneHourSeconds = 60 * 60;
    const oneDaySeconds = oneHourSeconds * 24;
    const oneWeekSeconds = oneDaySeconds * 7;

    return (
        <div>
            <div id='header'>
                <NavBarMobile
                    authState={props.authState}
                    logoutHandler={props.logoutHandler}
                    setMobileMenuSelection={setMobileMenuSelection}
                    changeToDesktopOrMobileView={props.changeToDesktopOrMobileView}
                />

                <div style={{ marginTop: '52px' }} className="is-centered level is-vcentered">
                    <p className="is-pulled-left level-item">View past
                    <button className={timeRangeSelection === fiveMinutes ? 'button button-primary active' : 'button button-primary'} onClick={(e) => { e.preventDefault(); setStartAndEndTimesByUsingTimerange(fiveMinutes); }}>
                            {t('5 min')}
                        </button>
                        <button className={timeRangeSelection === oneHourSeconds ? 'button button-primary active' : 'button button-primary'} onClick={(e) => { e.preventDefault(); setStartAndEndTimesByUsingTimerange(oneHourSeconds); }} >
                            {t('Hour')}
                        </button>
                        <button className={timeRangeSelection === oneDaySeconds ? 'button button-primary active' : 'button button-primary'} onClick={(e) => { e.preventDefault(); setStartAndEndTimesByUsingTimerange(oneDaySeconds); }}>
                            {t('Day')}
                        </button>
                        <button className={timeRangeSelection === oneWeekSeconds ? 'button button-primary active' : 'button button-primary'} onClick={(e) => { e.preventDefault(); setStartAndEndTimesByUsingTimerange(oneWeekSeconds); }}>
                            {t('Week')}
                        </button>
                        {(menuSelection === 'sensorGraph' && selectedVehicles.length === 1) &&
                            <button className={`button is-pulled-right button-primary ${vehicleAutoUpdate ? 'active' : 'inactive'}`} onClick={() => setVehicleAutoUpdate(!vehicleAutoUpdate)}>
                                {t('Auto-update')}
                            </button>
                        }
                    </p>
                </div>
            </div>
            <div id='content'>
                {(!props.authState.groups.official && !props.authState.groups.installer && !props.authState.groups.installerManager) &&
                    <>
                        {menuSelection === 'homepage' &&
                            <SectionPanel
                                state={getStatusSectionState()}
                                child={
                                    <>
                                        <VehicleStatus
                                            startDate={overviewTimeRange.startDate}
                                            endDate={overviewTimeRange.endDate}
                                            authState={props.authState}
                                            filterSelections={props.filterSelections}
                                            vehicleCount={vehicleCount}
                                            noxReduction={noxFleetReduction.period}
                                            onLoadStatusChange={setVehicleStatusLoadStatus}
                                        />
                                    </>
                                } />
                        }
                        {selectedVehicles.length === 1 ? (
                            <>
                                {menuSelection === 'sensorGraph' &&
                                    <>
                                        <button className={'button button-primary is-pulled-right'} onClick={() => quickSetTimeRange('zoomOut')} >
                                            <img className="" src={zoomOut} alt='zoomOut' />
                                        </button>
                                        <button className={'button button-primary'} onClick={() => quickSetTimeRange('zoomIn')} >
                                            <img className="" src={zoomIn} alt='zoomIn' />
                                        </button>
                                        <SensorDataChart
                                            isAuthenticated={props.authState.isAuthenticated}
                                            vehicle={selectedVehicles[0].id}
                                            startDate={vehicleTimeRange.startDate}
                                            endDate={vehicleTimeRange.endDate}
                                            longScaleStartDate={props.startDate}
                                            longScaleEndDate={props.endDate}
                                            onTimeRangeChange={(timeRange) => setVehicleTimerangeAndCheckAutoUpdateState(timeRange)}
                                            onPan={() => setVehicleAutoUpdate(false)}
                                            selectedAggregate={'avg'}
                                            hideState={'defaults'}
                                            positionAxelLabelsToLeft={true}
                                            hideLegends={true}
                                            hideScrollBar={true}
                                            enableTouch={true}
                                            showXaxesLabelInsteadOfTicks={true}
                                        />
                                    </>
                                }
                                {menuSelection === 'faultSection' &&
                                    <>
                                        <p>Timerange: {moment(vehicleTimeRange.startDate).format(getDateFormat('datetimeWithLeadingZeroesSeconds')).toString() + ' - ' + moment(vehicleTimeRange.endDate).format(getDateFormat('datetimeWithLeadingZeroesSeconds')).toString()}</p>
                                        <FaultTableSectionMobile
                                            isAuthenticated={props.authState.isAuthenticated}
                                            vehicle={selectedVehicles[0].id}
                                            startDate={overviewTimeRange.startDate}
                                            endDate={overviewTimeRange.endDate}
                                            onSelectionFaultCodeTimestamp={timestamp => {
                                                if (timestamp && props.authState.maxBucketCountForSensorDataFetch) {
                                                    const endDate = new Date(timestamp);
                                                    const startDate = new Date(timestamp);
                                                    startDate.setSeconds(startDate.getSeconds() - props.authState.maxBucketCountForSensorDataFetch);
                                                    setVehicleTimerangeAndCheckAutoUpdateState({ startDate, endDate });
                                                    setMobileMenuSelection("sensorGraph");
                                                }
                                            }}
                                        />
                                    </>
                                }
                            </>
                        ) : (
                            (menuSelection !== "homepage" && showPleaseSelectVehicle())
                        )}
                    </>
                }
            </div>
            <div id='footer'><AppFooterMobile /></div>
        </div>
    );
}
/* <p>{JSON.stringify(overviewTimeRange.startDate)}-{JSON.stringify(overviewTimeRange.endDate)}</p> */
export default withRouter(HomeViewMobile);
