import { useState, useEffect } from 'react';
import moment from 'moment';
import { constructCancelObject, cancellableAxiosCall } from '../utilities/generic';
import * as logger from "../utilities/logger";

type FaultDataChartData = Array<{
    startTime: Date,
    endTime?: Date,
    spn: string,
    fmi: string,
    spnDescription: string,
    fmiDescription: string,
}>;

export function constructFaultSelectionQueryParam(
    fixedFaultTypeSelection: string | undefined,
    fixedFaultCodeSelection: string | undefined,
) {
    return fixedFaultCodeSelection ? `spnAndFmi=${fixedFaultCodeSelection}` :
        constructFaultTypeSelectionQueryParam(fixedFaultTypeSelection);
}

export function constructFaultTypeSelectionQueryParam(
    fixedFaultTypeSelection: string | undefined,
) {
    return fixedFaultTypeSelection ? `faultTypeGroup=${fixedFaultTypeSelection}` : '';
}

export function useFaultDataState(props: Readonly<{
    vehicle: number | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined,
    fixedFaultTypeSelection?: string | undefined,
    fixedFaultCodelection?: string | undefined,
}>) {

    useEffect(
        () => {
            getFaultData();
        },
        [
            props.startDate,
            props.endDate,
            props.vehicle,
            props.fixedFaultTypeSelection,
            props.fixedFaultCodelection,
        ]
    );

    const [faultDataState, faultDataStateDispatcher] = useState<FaultDataChartData>([]);
    const [cancelObject] = useState(constructCancelObject());
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [inError, setInError] = useState<boolean>(false);

    async function getFaultData() {
        let newState: FaultDataChartData = [];
        if (props.vehicle) {
            let queryTakesLongEnoughTimer: ReturnType<typeof setTimeout> | undefined;
            try {
                setIsBusy(true);
                setInError(false);
                const queryTakesLongEnoughTimeoutMs = 5000;
                queryTakesLongEnoughTimer = setTimeout(() => {
                    faultDataStateDispatcher([]);
                }, queryTakesLongEnoughTimeoutMs);
                const startDate = moment(props.startDate).toISOString();
                const endDate = moment(props.endDate).toISOString();
                const results = await cancellableAxiosCall({
                    url: `/v10/faultData/${props.vehicle}/${startDate}/${endDate}?${constructFaultSelectionQueryParam(props.fixedFaultTypeSelection, props.fixedFaultCodelection)}` },
                    cancelObject
                );
                const faultData = results.data.faultData;
                if (Array.isArray(faultData) && faultData.length >= 1) {
                    newState = faultData;
                }
            } catch (err) {
                if (!err.isCancel) {
                    setInError(true);
                    logger.err(err.message || err);
                }
            } finally {
                setIsBusy(false);
                if (queryTakesLongEnoughTimer) {
                    clearTimeout(queryTakesLongEnoughTimer);
                }
            }
        } else {
            faultDataStateDispatcher([]);
        }
        faultDataStateDispatcher(newState);
    }

    function getSectionState() {
        if (inError) {
            return "error";
        } else if (isBusy) {
            return "busy";
        } else {
            return "ok";
        }
    }

    return { faultDataState, getSectionState };

}
