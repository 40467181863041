import * as actionTypes from '../../Actions/Actions';

export default function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_FLEET_UTILITY:
      return {
        inUse: typeof action.inUse === 'number' ? action.inUse : undefined,
        vehicleCount: typeof action.vehicleCount === 'number' ? action.vehicleCount : undefined,
      };
    default:
      return state;
  }
}
