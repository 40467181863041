import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { FormField, NamedEntity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';
import { EntityTableHeader } from './entityTable';

interface SiteEntity extends NamedEntity {
    operator_id: number;
    operator_name?: string;
    city_id?: number;
}

interface OperatorEntity extends NamedEntity {
    city_id: number;
    city_name: string;
    country_id: number;
    country_name: string;
}

function SiteManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    onDataUpdated: () => void,
}>) {

    const [sites, setSites] = useState([] as SiteEntity[]);
    const [cities, setCities] = useState([] as NamedEntity[]);
    const [operators, setOperators] = useState([] as OperatorEntity[]);
    const [currentSite, setCurrentSite] = useState<SiteEntity>({ id: 0, operator_id: 0, name: '' });

    const restPath = '/v10/sites/';

    const { t } = useTranslation();

    function fetchData() {
        // ?operator=0 or specific one for narrowing it down
        Axios.get('/v10/sites').then(response => setSites(response.data.result));
        Axios.get('/v10/operators').then(response => {
            setOperators(response.data.operators);
            if (response.data.operators.length === 1) {
                fetchCities(response.data.operators[0].country_id);
            } else {
                fetchCities();
            }
        });
    }

    function fetchCities(forCountry?) {
        const restCall = (forCountry) ? `/v10/cities?country_id=${forCountry}` : '/v10/cities';
        Axios.get(restCall).then(response => {
            response.data.result.forEach(city => {
                city.countryAndCity = city.country_name + " - " + city.name;
            });
            setCities(response.data.result);
        });
    }

    async function startEditing(id) {
        if (id === undefined) {
            setCurrentSite({
                id: 0,
                operator_id: operators[0].id,
                operator_name: operators[0].name,
                name: '',
                city_id: operators[0].city_id
            });
            fetchCities(operators[0].country_id);
        } else {
            const site = sites.find(e => e.id === id);
            if (!site) {
                throw new Error("Internal error");
            }
            setCurrentSite(deepClone(site));
            if (typeof site !== 'undefined') {
                const operator = operators.find(op => op.id === site.operator_id);
                if (typeof operator !== 'undefined') {
                    fetchCities(operator.country_id);
                }
            }
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentSite, name, value);
        if (name === "operator_id") {
            const operator = operators.find(op => op.id === Number(value));
            if (typeof operator !== 'undefined') {
                fetchCities(operator.country_id);
                changeValue("city_id", operator.city_id);
            }
        }
    }

    function validate() {
        return (currentSite.name.trim() === "") ? t("Please specify a site name") : "";
    }

    function getSanitizedEditingValues() {
        return {
            operator_id: Number(currentSite.operator_id),
            city_id: currentSite.city_id,
            name: currentSite.name.trim()
        };
    }

    const headersForTable: EntityTableHeader[] = [
        { localizedText: t('Site'), name: 'name', sortable: true },
        { localizedText: t('Country'), name: 'country_name', sortable: true },
        { localizedText: t('City'), name: 'city_name', sortable: true },
        { localizedText: t('Edit'), type: 'edit' },
        { localizedText: t('Delete'), type: 'delete' }
    ];

    const fieldsForForm: FormField[] = [
                            { label: t('City'), name: 'city_id', allowedValues: { entityName: 'cities', name: 'countryAndCity', preSelectOnlyOption: true },
                              readOnly: (props.groups && props.groups.expert) ? 'readOnly' : undefined},
                            { label: t('Site Name'), name: 'name', placeholder: t('Enter a site name'), maxLength: 255 }];

    if (props.groups && props.groups.admin) {
        headersForTable.unshift({ localizedText: t('Operator'), name: 'operator_name', sortable: true });
        fieldsForForm.unshift({ label: t('Operator'), name: 'operator_id', readOnly: "readOnlyOnEdit", allowedValues: { entityName: 'operators', name: 'name', preSelectOnlyOption: true } });
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}
            onDataUpdated={props.onDataUpdated}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentSite'
            }}

            texts={{
                localizedAddNewText: t('Add new site'),
                confirmDelete: t('Are you sure you want to delete the site?'),
                errors: {
                    generic: t("Site couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("A Site with that name already exists"),
                    deleteInUse: t("Site is in use and cannot be deleted"),
                    deleteGeneric: t("Site couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ sites, currentSite, cities, operators }}
            entityName='sites'

            tableHeaders={headersForTable}
            formFields={fieldsForForm}
        />
    );
}

export default SiteManagement;
