import * as actionTypes from '../../Actions/Actions';

export interface TimeRange extends Readonly<{
  startDate: Date,
  endDate: Date | undefined,
}> {}

export default function reducer(
  state: TimeRange,
  action: { type: string } & TimeRange,
): TimeRange {
  switch (action.type) {
    case actionTypes.SET_TIMERANGE:
      return {
        startDate: action.startDate,
        endDate: action.endDate,
      };
    default:
      return state;
  }
}
