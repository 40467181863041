import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { Entity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from "react-i18next";

function BodyModelManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    onDataUpdated: () => void,
}>) {

    interface BodyModel extends Entity {
        body_manufacturer_id: number;
        body_manufacturer_name: string;
    }

    const [bodyManufacturers, setBodyManufacturers] = useState([]);
    const [bodyModels, setBodyModels] = useState([] as BodyModel[]);
    const [currentBodyModel, setCurrentBodyModel] = useState<BodyModel>({ body_manufacturer_id: 0, body_manufacturer_name: '', id: 0, name: '' });

    const restPath = '/v10/bodyModel/';

    const { t } = useTranslation();

    function fetchData() {
        Axios.get('/v10/bodyManufacturer').then(response => setBodyManufacturers(response.data.result));
        Axios.get('/v10/bodyModel').then(response => setBodyModels(response.data.result));
    }

    async function startEditing(id) {
        if (id === undefined) {
            setCurrentBodyModel({ body_manufacturer_id: 0, body_manufacturer_name: '', id: 0, name: '' });
        } else {
            const model = bodyModels.find(e => e.id === id);
            if (!model) {
                throw new Error("Internal error");
            }
            setCurrentBodyModel(deepClone(model));
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentBodyModel, name, value);
    }

    function validate() {
        if (currentBodyModel?.name?.trim() === "") {
            return t("Please specify a body make and model name");
        } else if (!currentBodyModel?.body_manufacturer_id) {
            return t("Please specify a chassis");
        }
        return "";
    }

    function getSanitizedEditingValues() {
        return { body_manufacturer_id: currentBodyModel.body_manufacturer_id, name: currentBodyModel?.name?.trim() };
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}
            onDataUpdated={props.onDataUpdated}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentBodyModel'
            }}

            texts={{
                localizedAddNewText: t('Add new body make and model'),
                confirmDelete: t('Are you sure you want to delete the body?'),
                errors: {
                    generic: t("Body couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("A body with that name already exists for the given chassis"),
                    deleteInUse: t("Body is in use and cannot be deleted"),
                    deleteGeneric: t("Body couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ bodyModels: bodyModels, currentBodyModel: currentBodyModel, bodyManufacturers: bodyManufacturers }}
            entityName='bodyModels'

            tableHeaders={[{ localizedText: t('Chassis'), name: 'body_manufacturer_name', sortable: true },
            { localizedText: t('Body Name'), name: 'name', sortable: true },
            { localizedText: t('Edit'), type: 'edit' },
            { localizedText: t('Delete'), type: 'delete' }]}

            formFields={[{ label: t('Chassis'), name: 'body_manufacturer_id', allowedValues: { entityName: 'bodyManufacturers', name: 'name' } },
            { label: t('Body Name'), name: 'name', placeholder: t('Enter a body make and model name'), maxLength: 255 }]}
        />
    );
}

export default BodyModelManagement;
