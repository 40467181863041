import React, { Dispatch } from 'react';
import * as actionTypes from '../Actions/Actions';
import eppClient from '../utilities/epp-client';
import { LoginAndLogoutDispachActions } from '../components/Login';
import { FilterSelectionActions } from '../reducers/filterSelections';
import { DistanceReduction } from '../reducers/distance/reducer';

export default React.createContext<{
    eppClientInstance: ReturnType<typeof eppClient> | undefined,
    authenticatedDispatch: Dispatch<LoginAndLogoutDispachActions>,
    filterSelectionsDispatch: Dispatch<FilterSelectionActions>,
    timerangeDispatch: Dispatch<{
        type: typeof actionTypes.SET_TIMERANGE,
        startDate: Date,
        endDate: Date | undefined,
    }>,
    alarmsTimerangeDispatch: Dispatch<{
        type: typeof actionTypes.SET_TIMERANGE,
        startDate: Date,
        endDate: Date | undefined,
    }>,
    distanceReductionDispatch: Dispatch<{
        type: typeof actionTypes.SET_DISTANCES,
        meters: number,
        loading?: boolean,
        error?: boolean
    }>,
    fleetUtilityDispatch: Dispatch<{
        type: typeof actionTypes.SET_FLEET_UTILITY,
        vehicleCount,
    }>,
    distanceReduction: DistanceReduction,
} | undefined>(undefined);
