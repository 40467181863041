import React, { useState, useEffect, CSSProperties } from 'react';

import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import InstallationStatus from './InstallationStatus';
import RedGreenIndicator from './RedGreenIndicator';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { FilterSelections } from '../reducers/filterSelections';
import TimeRange from './TimeRange';
import VechileManagement from './vechileManagement';
import pencilEdit from '../resources/pencil.svg';
import plusAddNew from '../resources/add-circle.svg';
import { formatDate } from '../utilities/generic';

const centerMiddleStyle: CSSProperties = { textAlign: 'center', verticalAlign: 'middle' };

function FirstInstaller(props: Readonly<{
    authState: AuthState,
    filterSelections: FilterSelections,
    setFiltersVisibility: (visibility: boolean) => void,
}>) {

    const { t } = useTranslation();

    const [state, setState] = useState(
        {
            allVehicles: [] as Array<{
                gateway_id: string,
                vehicle_id: number,
                test_drive_started: string,
                installation_status: boolean,
                urea_rule: boolean,
                temp_rule: boolean,
                pump_rule_2: boolean,
                nox_rule: boolean,
                pump_rule_3: boolean,
                min_ave_reduction_rule: boolean,
                def_temp_rule: boolean,
                backpressure_rule: boolean,
                reg_number: string,
                description: string,
                installer: string
            }>,
            errorMessage: '',
            startTime: '',
            addNew: false,
            installationStatus: false,
            vehicleEditing: false,
            vehicleEditingId: 0,
            timeRange: {
                startDate: moment(new Date()).add(-1, 'week').toDate(),
                endDate: undefined as Date | undefined,
            },
            vehicle: {
                gateway_id: '',
                reg_number: '',
                body_model_id: '',
                production_date: '',
                engine_model_id: '',
                emission_class: '',
                vin: '',
                production_id: '',
                authority_visibility: '',
                site_id: '',
                vehicle_id: 0,
                description: '',
                test_drive_started: '',
                operator_id: 0
            }
        }
    );

    useEffect(() => {
        getInstallations();
    }, []);

    async function getInstallations() {
        try {
            const newState = { ...state };
            const installations = await axios.get(`/v10/installations?start=${state.timeRange.startDate.toISOString()}&end=${state.timeRange.endDate ? state.timeRange.endDate.toISOString() : ''}`);
            newState.allVehicles = installations.data.installations;
            newState.installationStatus = false;
            newState.vehicle.vehicle_id = 0;
            newState.vehicle.gateway_id = '';
            setState(newState);
        } catch (err) {
            const newState = { ...state };
            newState.errorMessage = t('Unable to get installations. Please check internet connection or contact administrator!');
            setState(newState);
        }
    }

    function handleAddNew() {
        const obj = { ...state };
        obj.addNew = !state.addNew;
        obj.errorMessage = '';
        obj.vehicle.operator_id = (props.filterSelections.operators && props.filterSelections.operators.length > 0) ? props.filterSelections.operators[0].id : 0;
        setState(obj);
    }

    function handleCancelAddNew() {
        const newState = { ...state };
        newState.addNew = false;
        for (const key of Object.keys(state.vehicle)) {
            newState.vehicle[key] = '';
        }
        newState.errorMessage = '';
        setState(newState);
    }

    function handleAddNewSubmitted(newVehicle) {
        if (typeof newVehicle === 'undefined') {
            handleCancelAddNew();
        } else {
            const newState = { ...state };
            newState.addNew = false;
            newState.installationStatus = true;
            newVehicle.request.vehicle_id = newVehicle.response.data.result.id;
            newState.allVehicles.push(newVehicle.request);
            newState.vehicle.vehicle_id = newVehicle.response.data.result.id;
            newState.vehicle.gateway_id = newVehicle.request.sourceId;
            newVehicle.gateway_id = newVehicle.request.sourceId;
            newState.startTime = new Date().toISOString();
            setState(newState);
        }
    }

    function handleShowVehicleDetail(id: number, gateway_id: string, startTime: string) {
        const newState = { ...state };
        newState.installationStatus = true;
        newState.vehicle.vehicle_id = id;
        newState.vehicle.gateway_id = gateway_id;
        newState.startTime = startTime;
        setState(newState);
    }

    function handleCloseInstallationStatus() {
        getInstallations();
    }

    function setTimeRange(startDate, endDate) {
        const newState = { ...state };
        newState.timeRange.startDate = startDate;
        newState.timeRange.endDate = endDate;
        setState(newState);
        getInstallations();
    }

    function onEdit(vehicleId: string) {
        const newState = { ...state };
        newState.vehicleEditing = true;
        newState.vehicleEditingId = Number(vehicleId);
        setState(newState);
    }

    function onEndVehicleEditing() {
        getInstallations().then(() => {
            setState(oldState => {
                const newState = { ...oldState };
                newState.vehicleEditing = false;
                newState.vehicleEditingId = 0;
                return newState;
                });
        });
    }

    function shouldShowInstallations() {
        return !state.addNew && !state.installationStatus && !state.vehicleEditing && state.errorMessage === '';
    }

    function canEditVehicle() {
        return !!props.authState.groups.installer;
    }

    function canAddNewInstallation() {
        return !props.authState.groups.installerManager;
    }

    function showInstallerUsername() {
        return !props.authState.groups.installer;
    }

    return (
        <>
            {shouldShowInstallations() &&
            <div>
                <div style={{zIndex: 1}}>
                    <div className="columns is-fullheight">
                        <div className="column is-fullwidth">
                            <div className="container">
                                <div className="columns">
                                    <div className="column is-fullwidth">
                                        <div className="box">
<                                           div className="media-content">
                                                <div className="content">
                                                    <TimeRange
                                                        startDate={state.timeRange.startDate}
                                                        endDate={state.timeRange.endDate}
                                                        submitHandler={(startDate, endDate) => { setTimeRange(startDate, endDate); }}
                                                        hourlyPrecision={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-12">
                        <h1 className="title"
                            style={{ paddingTop: '10px' }}
                        >
                            {t('Installations')}
                        </h1>
                    </div>
                </div>
                <div className="box">
                    <div>&nbsp;</div>
                    <div className="columns is-multiline is-mobile">
                        <div className="column is-12 is-mobile">
                            <>
                                {canAddNewInstallation() &&
                                    <button className="addNew" onClick={() => handleAddNew()}>
                                    {t('New Installation')}
                                    <span>
                                        &nbsp;<img className="icon is-small" src={plusAddNew} alt={t('New Installation')} />
                                    </span>
                                    </button>
                                }
                                <div>&nbsp;</div>
                                <div className="stickyTableHeader">
                                    <table className="table is-hoverable is-fullwidth is-narrow is-bordered installations">
                                        <thead className="installationsHeader">
                                            <tr>
                                                <th className="installationsHeader">{t('Gateway ID')}</th>
                                                <th className="installationsHeader">{t('Vehicle fleet number')}</th>
                                                <th className="installationsHeader">{t('Status')}</th>
                                                <th className="installationsHeader">{t('Test drive started')}</th>
                                                {showInstallerUsername() &&
                                                    <th className="installationsHeader">{t('Installer')}</th>
                                                }
                                                <th className="installationsHeader">{t('Urea Level')} &gt; 20%</th>
                                                <th className="installationsHeader">{t('Temperature increased after MAF')} &gt; 25</th>
                                                <th className="installationsHeader">{t('Pump reached state')} 2</th>
                                                <th className="installationsHeader">{t('NOx in has exceeded 800 ppm in 5 minutes after temperature has exceeded 150 C')}</th>
                                                <th className="installationsHeader">{t('Pump reached state 3 in 5 minutes after temperature has exceeded 160 C')}</th>
                                                <th className="installationsHeader">{t('Temperature reached 240 C, 90% temporarily NOx reduction reached and 15 min average reduction')} &gt;= 80%</th>
                                                <th className="installationsHeader">{t('Def temperature > 0 for the whole test drive')}</th>
                                                <th className="installationsHeader">{t('Backpressure > 5 mbar test drive duration')}</th>
                                                {canEditVehicle() &&
                                                    <th>{t('Edit')}</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="installationsBody">
                                            {state.allVehicles && state.allVehicles.length > 0 && state.allVehicles.map(vehicle => {
                                                return (
                                                    <tr key={vehicle.gateway_id}>
                                                        <td><button className="islink" onClick={() => handleShowVehicleDetail(vehicle.vehicle_id, vehicle.gateway_id, vehicle.test_drive_started)}>{vehicle.gateway_id}</button></td>
                                                        <td>{vehicle.description}</td>
                                                        <td><RedGreenIndicator showGreen={vehicle.installation_status} /></td>
                                                        <td>{formatDate(vehicle.test_drive_started, "datetimeWithSeconds")}</td>
                                                        {showInstallerUsername() &&
                                                            <td>{vehicle.installer}</td>
                                                        }
                                                        <td><RedGreenIndicator showGreen={vehicle.urea_rule} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.temp_rule} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.pump_rule_2} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.nox_rule} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.pump_rule_3} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.min_ave_reduction_rule} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.def_temp_rule} /></td>
                                                        <td><RedGreenIndicator showGreen={vehicle.backpressure_rule} /></td>
                                                        {canEditVehicle() &&
                                                            <td style={centerMiddleStyle}>
                                                                <button className="islink" id={vehicle.vehicle_id + ''} onClick={(e) => onEdit((e.target as HTMLTableCellElement).id)}>
                                                                    <div className="tooltip">
                                                                        <img id={vehicle.vehicle_id + ''} className="icon is-small" src={pencilEdit} alt={t('Edit')} />
                                                                        <span id={vehicle.vehicle_id + ''} className="tooltiptext">
                                                                            {t('Edit')}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
            }
            {state.addNew &&
                <div>
                    <div className="columns">
                        <div className="column is-12">
                            <h1 className="title"
                                style={{ paddingTop: '10px' }}
                            >
                                {t('New Installation')}
                            </h1>
                        </div>
                    </div>
                    <div className="box">
                        <VechileManagement
                            authState={props.authState}
                            filterSelections={props.filterSelections}
                            setFiltersVisibility={props.setFiltersVisibility}
                            onAddNew={handleAddNewSubmitted}
                            doAddNew={true}
                            preSelectedOperator={true}
                        />
                    </div>
                </div>
            }
            {state.installationStatus &&
                <div className="box">
                    <InstallationStatus
                        vehicleId={state.vehicle.vehicle_id}
                        gatewayId={state.vehicle.gateway_id}
                        startTime={state.startTime}
                        onClose={handleCloseInstallationStatus}
                        auth={props.authState}
                    />
                </div>
            }
            {state.vehicleEditing &&
                <div className="box">
                    <VechileManagement
                        authState={props.authState}
                        filterSelections={props.filterSelections}
                        setFiltersVisibility={props.setFiltersVisibility}
                        onEdit={onEndVehicleEditing}
                        doEdit={state.vehicleEditingId}
                        preSelectedOperator={true}
                    />
                </div>
            }
            {!state.addNew && !state.installationStatus && state.errorMessage !== '' &&
                <h2 className="title">{state.errorMessage}</h2>
            }
        </>
    );
}

export default FirstInstaller;
