import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeRange from './TimeRange';
import HomeViewSection from './HomeViewSection';
import SensorDataChart from './SensorDataChart';

function SensorDataChartSection(
  props: Readonly<{
    isAuthenticated: boolean,
    vehicle: number | undefined,
    startDate: Date,
    endDate: Date | undefined,
    longScaleStartDate: Date,
    longScaleEndDate: Date | undefined,
    secondsForZoomToLevelOfSeconds: number | undefined,
    onTimeRangeChange: (timerange: {
      startDate: Date,
      endDate: Date | undefined,
    }) => void,
  }>,
  ref: ((instance: HTMLDivElement) => void) | React.RefObject<HTMLDivElement> | null | undefined
) {

  const { t } = useTranslation();

  const [state, setState] = useState<{
    startDate: Date,
    endDate: Date | undefined,
  }>(
    {
      startDate: new Date(),
      endDate: new Date(),
    }
  );

  useEffect(
    () => {
      setState({
        startDate: props.longScaleStartDate,
        endDate: props.longScaleEndDate,
      });
    },
    [
      props.longScaleStartDate,
      props.longScaleEndDate,
    ]
  );

  const [selectedAggregate, setSelectedAggregate] = useState<'avg' | 'min' | 'max'>('avg');
  const [hideState, setHideState] = useState<'defaults' | 'none' | 'all'>('defaults');

  function onTimeRangeChange(timeRange: {startDate: Date, endDate: Date | undefined}) {
    setState(timeRange);
    props.onTimeRangeChange(timeRange);
  }

  return (<HomeViewSection
    ref={ref}
    backgroundStyle={{position: 'relative'}}
    child={
      <div className="columns" style={{ position: 'relative'}}>
        <div className="column is-12 is-paddingless is-centered">
          <strong>{t('Sensor graph')}</strong>
          <TimeRange
            startDate={state.startDate}
            endDate={state.endDate}
            submitHandler={(startDate: Date, endDate: Date | undefined) => onTimeRangeChange({ startDate, endDate })}
            hourlyPrecision={false}
          />
          <div className="column is-mobile is-centered">
            <button
              onClick={() => setSelectedAggregate('avg')}
              className={selectedAggregate === "avg" ? "button primary is-focused" : "button primary"}
            >{t('Avg')}</button>
            <button
              onClick={() => setSelectedAggregate('min')}
              className={selectedAggregate === "min" ? "button primary is-focused" : "button primary"}
            >{t('Min')}</button>
            <button
              onClick={() => setSelectedAggregate('max')}
              className={selectedAggregate === "max" ? "button primary is-focused" : "button primary"}
            >{t('Max')}</button>

            <button
              onClick={() => {
                if (state.endDate && props.secondsForZoomToLevelOfSeconds) {
                  const startDate = new Date(state.endDate);
                  startDate.setSeconds(startDate.getSeconds() - props.secondsForZoomToLevelOfSeconds);
                  const endDate = state.endDate;
                  onTimeRangeChange({startDate, endDate});
                }
              }}
              style={{ marginLeft: 30 }}
              className={"button primary"}
            >{t('Zoom to level of seconds')}</button>

            <button
              style={{ float: 'right' }}
              onClick={() => setHideState('all')}
              className={hideState === "all" ? "button primary is-focused" : "button primary"}
            >{t('None')}</button>
            <button
              style={{ float: 'right' }}
              onClick={() => setHideState('none')}
              className={hideState === "none" ? "button primary is-focused" : "button primary"}
            >{t('All')}</button>
            <button
              style={{ float: 'right' }}
              onClick={() => setHideState('defaults')}
              className={hideState === "defaults" ? "button primary is-focused" : "button primary"}
            >{t('Defaults')}</button>
            <div style={{ float: 'right', padding: "5px 12px" }}>
              {t('Show sensors')}:
                &nbsp;
              </div>

          </div>
          <SensorDataChart
            isAuthenticated={props.isAuthenticated}
            vehicle={props.vehicle}
            startDate={props.startDate}
            endDate={props.endDate}
            longScaleStartDate={props.longScaleStartDate}
            longScaleEndDate={props.longScaleEndDate}
            onTimeRangeChange={onTimeRangeChange}
            selectedAggregate={selectedAggregate}
            hideState={hideState}
          />
        </div>
      </div>
    } />);
}

export default React.forwardRef(SensorDataChartSection);
