import React, { useState, useEffect } from "react";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";
import { browser, getDateFormat, getTimeFormat } from "../utilities/generic";

function CsvExportDialog(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    vehicle: number | undefined,
}>) {

    const { t } = useTranslation();

    const [exportEnabled, setExportEnabled] = useState<boolean>();

    const [state, setState] = useState({
        exportStartDate: props.startDate,
        exportEndDate: props.endDate
    });

    useEffect(
        () => {
            setState({
                exportStartDate: props.startDate,
                exportEndDate: props.endDate
            });
        },
        [props.startDate, props.endDate]
    );

    async function fetchExportData(exportStartDate: Date, exportEndDate: Date) {
        const startDate = moment(exportStartDate).toISOString();
        const endDate = moment(exportEndDate).toISOString();

        if (props.vehicle) {
            const fileName = 'vehicle_' + props.vehicle + '_' + startDate + '-' + endDate + '.csv';
            const path = `${process.env.REACT_APP_APPLICATION_SERVER_URL || ''}/v10/exportData/${props.vehicle}/${startDate}/${endDate}?fileName=${fileName}`;
            if (browser() === 'edge') {
                const link = document.createElement('a');
                link.href = path;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.location.href = path;
            }
        }
    }

    async function closeDialog(submit = false) {
        if (submit && state.exportStartDate && state.exportEndDate) {
            await fetchExportData(state.exportStartDate, state.exportEndDate);
        }
        setExportEnabled(false);
    }

    function handleStartDateChange(d: Date | null) {
        setState(oldState => {
            const newState = {...oldState};
            if (d) {
                newState.exportStartDate = d;
            }
            return newState;
        });
    }

    function handleEndDateChange(d: Date | null) {
        setState(oldState => {
            const newState = {...oldState};
            if (d) {
                newState.exportEndDate = d;
            }
            return newState;
        });
    }

    const timeFormat = getTimeFormat(true);
    const dateFormat = getDateFormat('datetimeWithSeconds', 'dateFnsFormat');

    return (
        <>
        <div className={exportEnabled ? "modal is-active" : "modal"}>
            <div className="modal-background"></div>
            <div className="modal-card" style={{ overflow: 'visible' }}>
                <header className="modal-card-head">
                    <p className="modal-card-title">{t('Select Time Range for Export')}</p>
                    <button className="delete" aria-label="close" onClick={() => closeDialog()}></button>
                </header>
                <section className="modal-card-body">
                    <div className="level-item">
                        <div style={menuControlStyle}>
                            <DatePicker
                                showTimeSelect
                                timeFormat={timeFormat}
                                timeIntervals={60}
                                maxDate={state.exportEndDate}
                                dateFormat={dateFormat}
                                selected={state.exportStartDate}
                                onChange={handleStartDateChange}
                                name="startDate"
                            />
                        </div>
                        <div style={menuControlStyle}>
                            -
                        </div>
                        <div style={menuControlStyle}>
                            <DatePicker
                                placeholderText={t('Now')}
                                showTimeSelect
                                timeFormat={timeFormat}
                                timeIntervals={60}
                                minDate={state.exportStartDate}
                                dateFormat={dateFormat}
                                selected={state.exportEndDate}
                                onChange={handleEndDateChange}
                                name="endDate"
                            />
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                <button className="button is-success" onClick={() => closeDialog(true)}>{t('Export')}</button>
                <button className="button" onClick={() => closeDialog()}>{t('Cancel')}</button>
                </footer>
            </div>
        </div>
        <button onClick={() => setExportEnabled(true)} className="button primary is-focused">{t('Export')}...</button>
        </>
    );

}

const menuControlStyle = { display: "inline-block", marginRight: 10 };

export default CsvExportDialog;
