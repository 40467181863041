import React, { useState, } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { Entity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';

function EmissionClassManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    onDataUpdated: () => void,
}>) {

    const [emissionClasses, setEmissionClasses] = useState([] as Entity[]);
    const [currentEmissionClass, setCurrentEmissionClass] = useState<Entity>({ id: 0, name: '' });

    const restPath = '/v10/emission_class/';

    const { t } = useTranslation();

    function fetchData() {
        Axios.get('/v10/emission_class').then(response => setEmissionClasses(response.data.result));
    }

    async function startEditing(id) {
        if (id === undefined) {
            setCurrentEmissionClass({ id: 0, name: '' });
        } else {
            const emissionClass = emissionClasses.find(e => e.id === id);
            if (!emissionClass) {
                throw new Error('Internal error');
            }
            setCurrentEmissionClass(deepClone(emissionClass));
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentEmissionClass, name, value);
    }

    function validate() {
        return (currentEmissionClass?.name?.trim() === "") ? t("Please specify an emission class name") : "";
    }

    function getSanitizedEditingValues() {
        return { name: currentEmissionClass?.name?.trim() };
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}
            onDataUpdated={props.onDataUpdated}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentEmissionClass'
            }}

            texts={{
                localizedAddNewText: t('Add new emission class'),
                confirmDelete: t('Are you sure you want to delete the emission class?'),
                errors: {
                    generic: t("Emission class couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("An emission class with that name already exists"),
                    deleteInUse: t("Emission class is in use and cannot be deleted"),
                    deleteGeneric: t("Emission class couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ emissionClasses: emissionClasses, currentEmissionClass: currentEmissionClass }}
            entityName='emissionClasses'

            tableHeaders={[{ localizedText: t('Emission class'), name: 'name', sortable: true },
            { localizedText: t('Edit'), type: 'edit' },
            { localizedText: t('Delete'), type: 'delete' }]}

            formFields={[{ label: t('Emission class'), name: 'name', placeholder: t('Enter an emission class name'), maxLength: 255 }]}
        />
    );
}

export default EmissionClassManagement;
