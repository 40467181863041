export function onHamburgerMenuClick() {
    const toggle = document.querySelector(".nav-toggle");
    const showMobileMenu = !toggle?.classList.contains("is-active");
    if (showMobileMenu) {
        document.body.addEventListener('click', closeMobileMenuOnOutsideClick, { once: true });
        showHideMobileMenu(true);
    }
}

function closeMobileMenuOnOutsideClick(this: HTMLElement) {
    document.body.removeEventListener('click', closeMobileMenuOnOutsideClick);
    setTimeout(() => { // By timeout, ensure it's run after the hamburger menu onClick handler
        showHideMobileMenu(false);
    }, 10);
}

function showHideMobileMenu(show: boolean) {
    const toggle = document.querySelector(".nav-toggle");
    const menu = document.querySelector(".navbar-menu");
    if (toggle && menu) {
        if (show) {
            toggle.classList.add("is-active");
            menu.classList.add("is-active");
        } else {
            toggle.classList.remove("is-active");
            menu.classList.remove("is-active");
        }
    }
}
