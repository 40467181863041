import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

// i18n settings
import './i18n';

axios.defaults.baseURL = process.env.REACT_APP_APPLICATION_SERVER_URL;
process.env.REACT_APP_ENVIRONMENT === 'development' ? axios.defaults.withCredentials = true : axios.defaults.withCredentials = false;

ReactDOM.render(
    <Router>
        <App />
    </Router>
    , document.getElementById('root')
);
