import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionPanel from './SectionPanel';
import RouteContext from '../contexts/RouteContext';
import { authenticatedFilteredAxiosGet, constructCancelObject, getSectionBackgroundColor, formatDate } from '../utilities/generic';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { FilterSelections } from '../reducers/filterSelections';
import * as logger from "../utilities/logger";
import moment from 'moment';
import { constructArrayOfFilterSelections } from './FilterSelectors';

function HomeSummary(props: Readonly<{
    authState: AuthState,
    filterSelections: FilterSelections,
}>) {
    const { t } = useTranslation();
    const context = useContext(RouteContext);

    const distanceReduction = context ? context.distanceReduction : undefined;

    const [getQueryCancelObject] = useState(constructCancelObject());

    const [initialValues, setInitialValues] = useState<{
        startTimestamp: moment.Moment | null,
        noxIn: number | null,
        noxOut: number | null,
        mgsIn: number | null,
        mgsOut: number | null,
        distance: number | null,
    }>();

    const [noxFleetReduction, setNoxFleetReduction] = useState(
        {
        allTime: {} as {
            fromPpm: number | null,
            kg: number | null,
            noxIn: number | null,
            noxOut: number | null,
            runningTime: number | null,
            startTimestamp: moment.Moment | null,
        } | null,
        loading: true,
        error: false
    });

    useEffect(() => {
        async function fetchInitialValues() {
            try {
                const values = await authenticatedFilteredAxiosGet(`/v10/reduction_initial_values`,
                                                                props.authState.isAuthenticated,
                                                                props.filterSelections,
                                                                getQueryCancelObject);
                setInitialValues({
                    startTimestamp: values.data.startTimestamp ? moment(values.data.startTimestamp) : null,
                    noxIn: values.data.noxIn,
                    noxOut: values.data.noxOut,
                    mgsIn: values.data.mgsIn,
                    mgsOut: values.data.mgsOut,
                    distance: values.data.distance,
                });
            } catch (err) {
                if (!err.isCancel) {
                    logger.err("problem while fetching reduction_initial_values stuff", err);
                }
            }
        }
        fetchInitialValues();
    }, [props.authState.isAuthenticated, getQueryCancelObject, props.filterSelections]);

    useEffect(
        () => {
            getFleetNoxReduction();
        },
        constructArrayOfFilterSelections(props.filterSelections)
    );

    const [getFleetNoxReductionForAllTimeCancelObject] = useState(constructCancelObject());
    async function getFleetNoxReduction() {
        const obj = { ...noxFleetReduction };
        obj.loading = true;
        setNoxFleetReduction(obj);
        try {
            const noxPercentReductionForAlltime = await authenticatedFilteredAxiosGet(`/v10/reduction`, props.authState.isAuthenticated, props.filterSelections, getFleetNoxReductionForAllTimeCancelObject);
            setNoxFleetReduction({
                allTime: noxPercentReductionForAlltime.data.length === 1 ? {
                    startTimestamp: noxPercentReductionForAlltime.data[0].startTimestamp ? moment(noxPercentReductionForAlltime.data[0].startTimestamp) : null,
                    fromPpm: noxPercentReductionForAlltime.data[0].noxPercentReductionFromPpm,
                    kg: noxPercentReductionForAlltime.data[0].noxReductionKg,
                    noxOut: noxPercentReductionForAlltime.data[0].totalNoxOut,
                    noxIn: noxPercentReductionForAlltime.data[0].totalNoxIn,
                    runningTime: noxPercentReductionForAlltime.data[0].runningTime,
                } : null,
                loading: false,
                error: false
            });
        } catch (err) {
            if (!err.isCancel) {
                logger.err("problem while fetching reductions stuff", err);
                obj.error = true;
                setNoxFleetReduction(obj);
            }
            return;
        }
    }

    function isBusy() {
        return (((noxFleetReduction && noxFleetReduction.loading) || (distanceReduction && distanceReduction.loading)) && !inError()) || false;
    }

    function inError() {
        return (noxFleetReduction && noxFleetReduction.error) || (distanceReduction && distanceReduction.error) || false;
    }

    function renderSinceTime(useInitialValuesSinceTimestamp: boolean) {
        const when = useInitialValuesSinceTimestamp && initialValues?.startTimestamp ? initialValues.startTimestamp : noxFleetReduction?.allTime?.startTimestamp;
        if (when) {
            return <>{t('since')}&nbsp;{formatDate(when, 'date')}</>;
        } else {
            return '';
        }
    }

    function renderTotalKilometers() {
        if (!distanceReduction?.meters || !initialValues) {
            return '';
        }
        let distance = distanceReduction.meters || 0;
        if (initialValues.distance) {
            distance += initialValues.distance;
        }
        return distance ? <>{ Math.round(distance / 1000)}&nbsp;km</> : '';
    }

    function renderAvgSpeedContainingNoInitialValues() {
        if (!noxFleetReduction || !distanceReduction) {
            return '';
        }
        const runningTimeContainingNoInitialValues = noxFleetReduction.allTime?.runningTime || 0;
        const distanceContainingNoInitialValues = distanceReduction.meters || 0;
        return distanceContainingNoInitialValues && runningTimeContainingNoInitialValues
            ? <>{ Math.round(distanceContainingNoInitialValues / runningTimeContainingNoInitialValues * 3.6)}&nbsp;km/h</>
            : '';
    }

    function getReductionInKG() {
        if (!noxFleetReduction?.allTime || !initialValues) {
            return 0;
        }
        let reduction = noxFleetReduction.allTime.kg || 0;
        if (initialValues.mgsIn !== null && initialValues.mgsOut !== null) {
            reduction += ((initialValues.mgsIn - initialValues.mgsOut) / 1000);
        }
        return reduction;
    }

    function renderNoxReductionInKg() {
        return getReductionInKG() ? <>{ Math.round(getReductionInKG())}&nbsp;kg</> : '';
    }

    function getNoxReductionPercentage() {
        if (!noxFleetReduction?.allTime || !initialValues) {
            return 0;
        }
        let totalNoxIn = noxFleetReduction.allTime.noxIn || 0;
        let totalNoxOut = 0;
        if (totalNoxIn) {
            totalNoxOut = noxFleetReduction.allTime.noxOut || 0;
        }
        if (initialValues.noxIn !== null && initialValues.noxOut !== null) {
            totalNoxIn += initialValues.noxIn;
            totalNoxOut += initialValues.noxOut;
        }
        const result = totalNoxIn !== 0 ? (1 - (totalNoxOut / totalNoxIn)) * 100 : 0;
        return result;
    }

    function renderNoxReductionPercentage() {
        return getNoxReductionPercentage() ? <>{ Math.round(getNoxReductionPercentage())}&nbsp;%</> : '';
    }

    function getSectionState() {
        if (inError()) {
            return "error";
        } else if (isBusy()) {
            return "busy";
        } else {
            return "ok";
        }
    }

    return (
        <div className="columns is-fullheight">
            <div className="column is-fullwidth" >
                <div className="container box" style={{ position: "relative", backgroundColor: getSectionBackgroundColor(inError(), isBusy())}}>
                    <SectionPanel
                            state={getSectionState()}
                            child={
                        <div className="columns">
                            <div className="column is-2">
                                <h2 className="has-text-center">
                                    <b>{t('Summary')}</b><span className="has-text-centered" style={{fontSize: "small"}}>, {renderSinceTime(true)}</span>
                                </h2>
                            </div>
                            <div className="column is-2">
                                <p className="has-text-centered">{t('NOx Reduction')} {renderNoxReductionInKg()}</p>
                            </div>
                            <div className="column is-2">
                                <p className="has-text-centered">{t('NOx Reduction')} {renderNoxReductionPercentage()}</p>
                            </div>
                            <div className="column is-2">
                                <p className="has-text-centered">{t('Total Kilometers')} {renderTotalKilometers()}</p>
                            </div>
                            <div className="column is-2">
                                <p className="has-text-centered">{t('Average Speed')} {renderAvgSpeedContainingNoInitialValues()}<span style={{fontSize: "small"}}> ({renderSinceTime(false)})</span></p>
                            </div>
                        </div>
                    }/>
                </div>
            </div>
        </div>
    );
}

export default HomeSummary;
