import React from 'react';

function ProgressBar(props: Readonly<{
    status: 'is-info' | 'is-success' | 'is-danger',
    value: number,
}>) {

    return (
        <>
            <progress
                className = {"progress " + props.status}
                value = {props.value}
                max = {100}
            >{props.value}%</progress>
        </>
    );
}

export default ProgressBar;
