import axios, { AxiosRequestConfig } from 'axios';
import { FilterSelections } from '../reducers/filterSelections';
import * as logger from "../utilities/logger";
import moment from "moment";
import { useEffect, useRef } from 'react';

const cancelToken = axios.CancelToken;

export function browser() {
    // Based on https://stackoverflow.com/questions/56360225/how-to-detect-microsoft-chromium-edge-chredge-edgium-in-javascript
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf("edge") > -1: return "edge";
        case agent.indexOf("edg") > -1: return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 && !!(window as unknown as { opr: unknown }).opr: return "opera";
        case agent.indexOf("chrome") > -1 && !!(window as unknown as { chrome: unknown }).chrome: return "chrome";
        case agent.indexOf("trident") > -1: return "ie";
        case agent.indexOf("firefox") > -1: return "firefox";
        case agent.indexOf("safari") > -1: return "safari";
        default: return "other";
    }
}

export function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

type StateFnParameterType<T> = (oldvalue: T) => T;

export function mergeStateValue<T>(stateFn: (stateFnParameter: StateFnParameterType<T>) => unknown, name: string, value: unknown) {
    stateFn(oldValues => {
        const newValues = deepClone(oldValues);
        newValues[name] = value;
        return newValues;
    });
}

/**
 * Round number. Note: Consider not using toFixed function because it rounds for example: 859.385.toFixed(2) => "859.38" (https://stackoverflow.com/a/43998255)
 * @param n Number to be rounded
 * @param p Precision, in number of decimals
 */
export function round(n: number, p: number) {
    const m = Math.pow(10, p);
    return Math.round(n * m) / m;
}

export function groupBy<T>(xs: T[], key: string) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || ([] as T[])).push(x);
        return rv;
    }, {} as { [key: string]: T[] });
}

function addUrlParameter(restCall: string, paramName: string, paramValue: string | number) {
    restCall += restCall.indexOf('?') === -1 ? '?' : '&';
    restCall += `${paramName}=${paramValue}`;
    return restCall;
}

export interface CancelObject {
    cancel: (() => void) | undefined;
}

export function constructCancelObject() {
    return { cancel: undefined } as CancelObject;
}

export async function authenticatedFilteredAxiosGet(path: string, isAuthenticated: boolean, filterSelections: FilterSelections, cancelObject: CancelObject, dontFilterByExplicitlySelected?: boolean) {
    if (isAuthenticated) {
        let restCall = path;
        if (!dontFilterByExplicitlySelected && filterSelections.fixedVehiclesSelection && filterSelections.fixedVehiclesSelection.length > 0) {
            restCall = addUrlParameter(restCall, 'vehicle_id', filterSelections.fixedVehiclesSelection.map(v => v.id).join(','));
        } else if (filterSelections.vehicles.length > 0) {
            restCall = addUrlParameter(restCall, 'vehicle_id', filterSelections.vehicles.map(v => v.id).join(','));
        }
        if (typeof filterSelections.bodyManufacturer !== 'undefined') {
            restCall = addUrlParameter(restCall, 'bodyManufacturer', filterSelections.bodyManufacturer);
        }
        if (typeof filterSelections.bodyModel !== 'undefined') {
            restCall = addUrlParameter(restCall, 'bodyModel', filterSelections.bodyModel);
        }
        if (typeof filterSelections.engineManufacturer !== 'undefined') {
            restCall = addUrlParameter(restCall, 'engineManufacturer', filterSelections.engineManufacturer);
        }
        if (typeof filterSelections.engineModel !== 'undefined') {
            restCall = addUrlParameter(restCall, 'engineModel', filterSelections.engineModel);
        }
        if (typeof filterSelections.site !== 'undefined') {
            restCall = addUrlParameter(restCall, 'site_id', filterSelections.site);
        }
        if (typeof filterSelections.operators !== 'undefined' && filterSelections.operators.length > 0) {
            restCall = addUrlParameter(restCall, 'operator_id', filterSelections.operators.map(e => e.id).join(","));
        }
        if (typeof filterSelections.city !== 'undefined') {
            restCall = addUrlParameter(restCall, 'city_id', filterSelections.city);
        }
        if (typeof filterSelections.country !== 'undefined') {
            restCall = addUrlParameter(restCall, 'country_id', filterSelections.country);
        }
        return await cancellableAxiosCall({
            url: restCall
        }, cancelObject);
    } else {
        return { data: undefined };
    }
}

export async function cancellableAxiosCall(axiosRequestConfig: AxiosRequestConfig, cancelObject: CancelObject) {
    if (cancelObject.cancel) {
        cancelObject.cancel();
    }
    const source = cancelToken.source();
    cancelObject.cancel = () => {
        if (cancelObject.cancel) {
            logger.info('Cancelling call', axiosRequestConfig.url);
            source.cancel();
            cancelObject.cancel = undefined;
        }
    };
    axiosRequestConfig.cancelToken = source.token;
    let result;
    try {
        result = await axios(axiosRequestConfig);
    } catch (err) {
        if (axios.isCancel(err)) {
            err.isCancel = true;
        }
        throw err;
    }
    cancelObject.cancel = undefined;
    return result;
}

export function getSectionBackgroundColor(inError: boolean, isBusy: boolean) {
    return inError ? "#e09090" : isBusy ? '#f0f0f0' : 'white';
}

export function getTimeFormat(withSeconds: boolean) {
    return withSeconds ? 'HH:mm:ss' : 'HH:mm';
}

export type DateFormatOptions = 'date' | 'dayAndMonthWithLeadingZeroes' | 'datetime' | 'datetimeWithSeconds' | 'datetimeWithLeadingZeroesSeconds';

export function getDateFormat(type: DateFormatOptions, targetLibrary: 'momentJsFormat' | 'dateFnsFormat' = 'momentJsFormat') {
    const dateFnsLibFormat = targetLibrary === 'dateFnsFormat';
    let f: string;
    switch (type) {
        case 'dayAndMonthWithLeadingZeroes':
            f = (dateFnsLibFormat ? 'dd/MM' : 'DD/MM');
            break;
        case 'date':
            f = (dateFnsLibFormat ? 'd/M/yyyy' : 'D/M/YYYY');
            break;
        case 'datetime':
            f = (dateFnsLibFormat ? 'd/M/yyyy' : 'D/M/YYYY') + ' ' + getTimeFormat(false);
            break;
        case 'datetimeWithSeconds':
            f = (dateFnsLibFormat ? 'd/M/yyyy' : 'D/M/YYYY') + ' ' + getTimeFormat(true);
            break;
        case 'datetimeWithLeadingZeroesSeconds':
            f = (dateFnsLibFormat ? 'dd/MM/yyyy' : 'DD/MM/YYYY') + ' ' + getTimeFormat(true);
            break;
    }
    return f;
}

export function formatDate(date: moment.Moment | Date | string, type: DateFormatOptions) {
    const f = getDateFormat(type);
    if (!moment.isMoment(date)) {
        date = moment(date);
    }
    return date.format(f);
}

/**
 * As a fix for #367 change default functionality https://github.com/chartjs/Chart.js/blob/2.9/src/plugins/plugin.legend.js#L28 slightly
 * to match Chart.js 3.0 implementation.
 */
export function patchedLegendClickHandler(legendItem, self) {
    const index = legendItem.datasetIndex;
    const ci = self.chart;
    const meta = ci.getDatasetMeta(index);
    if (ci.isDatasetVisible(index)) {
        meta.hidden = true;
        legendItem.hidden = true;
    } else {
        meta.hidden = false;
        legendItem.hidden = false;
    }
    ci.update();
}

export function useSelectionAutoScroll(deps: React.DependencyList) {
    const scrollableDivRef = useRef<HTMLDivElement | null>(null);
    const tableBodyRef = useRef<HTMLElement | null>(null);
    const selectedRowRef = useRef<HTMLElement | null>(null);
    selectedRowRef.current = null;
    useEffect(
        () => {
            const scrollableDivElem = scrollableDivRef.current;
            const tableBodyElem = tableBodyRef.current;
            const selectedRowElem = selectedRowRef.current;
            if (scrollableDivElem && tableBodyElem && selectedRowElem) {
                const newScrollTop = selectedRowElem.offsetTop - tableBodyElem.offsetTop;
                if (scrollableDivElem.scrollTop + scrollableDivElem.offsetHeight < newScrollTop ||
                    scrollableDivElem.scrollTop > newScrollTop) {
                    scrollableDivElem.scrollTop = newScrollTop;
                }
            }
        },
        deps
    );
    return {
        scrollableDivRef: elem => {
            scrollableDivRef.current = elem;
        },
        tableBodyRef: elem => {
            tableBodyRef.current = elem;
        },
        selectedRowRef: elem => {
            selectedRowRef.current = elem;
        },
    };
}
