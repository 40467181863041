import React from 'react';

const redGreenIndicator = (props: Readonly<{
    showGreen: boolean | undefined
}>) => (
    <div style={{
        borderRadius: '100%',
        backgroundColor: typeof props.showGreen === 'undefined' ? '#d0d0d0' : (props.showGreen ? 'green' : 'red'),
        border: props.showGreen ? undefined : 'solid',
        height: '15px',
        width: '15px',
        margin: '0 auto',
    }}></div>
);

export default redGreenIndicator;
