import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { getSectionBackgroundColor } from '../utilities/generic';
import HomeViewSection from './HomeViewSection';
import SectionPanel from './SectionPanel';
import { formatDate } from '../utilities/generic';
import { useFaultDataState } from '../dal/FaultTableHook';

function FaultTableSection(props: Readonly<{
    isAuthenticated: boolean,
    vehicle: number | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined,
    fixedFaultTypeSelection: string | undefined,
    fixedFaultCodelection: string | undefined,
    onSelectionFaultCodeTimestamp: (timestamp: Date | undefined) => void,
 }>) {

    const { t } = useTranslation();

    const { faultDataState, getSectionState } = useFaultDataState(props);

    const cellStyle: CSSProperties = {textAlign: 'left', verticalAlign: 'middle'};

    return (
        <HomeViewSection
            backgroundStyle={{position: 'relative', backgroundColor: getSectionBackgroundColor(getSectionState() === 'error', getSectionState() === 'busy')}}
            child={
                <SectionPanel
                    state={getSectionState()}
                    child={
                        <>
                            <div className="columns">
                                <div className="column">
                                    <strong>{t('Fault Codes of Vehicle')}</strong>
                                </div>
                            </div>
                            {
                                faultDataState.length > 0 ?
                                    <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                        <table className="table is-hoverable is-fullwidth is-narrow is-bordered" style={{background: 'inherit'}}>
                                            <thead style={{backgroundColor: 'lightgray'}}>
                                                <tr style={{fontSize: 'x-small'}}>
                                                    <th>{t('Time')}</th>
                                                    <th>{t('Code')}</th>
                                                    <th>{t('Description')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                faultDataState.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={cellStyle}>
                                                                <p><button className="islink"
                                                                    onClick={() => {
                                                                        if (item.startTime) {
                                                                            props.onSelectionFaultCodeTimestamp(new Date(item.startTime));
                                                                        }
                                                                }}>
                                                                    {formatDate(item.startTime, "datetimeWithSeconds")}
                                                                    &nbsp;-&nbsp;
                                                                    {item.endTime ? formatDate(item.endTime, "datetimeWithSeconds") : '...'}
                                                                </button></p>
                                                            </td>
                                                            <td style={cellStyle}>
                                                                <p>{item.spn}&nbsp;/&nbsp;{item.fmi}</p>
                                                            </td>
                                                            <td style={cellStyle}>
                                                                <p>{item.spnDescription}&nbsp;/&nbsp;{item.fmiDescription}</p>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                :
                                <p>{t('No Fault codes on selected timerange')}</p>
                            }
                        </>
                    }
                />
            }
        />
    );
}

export default FaultTableSection;
