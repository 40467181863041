import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { Entity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';

interface Operator extends Entity {
    city_id?: number;
    fault_report_email?: string;
    hide_location?: boolean;
    // highBackpressureThreshold?: number;
}

function OperatorManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    onDataUpdated: () => void,
}>) {

    const { t } = useTranslation();
    const [cities, setCities] = useState([]);
    const [operators, setOperators] = useState([] as Operator[]);
    const [currentOperator, setCurrentOperator] = useState<Operator>({ id: 0 });

    const restPath = '/v10/operators2/';

    function fetchData() {
        Axios.get('/v10/cities').then(response => {
            response.data.result.forEach(city => {
                city.countryAndCity = city.country_name + " - " + city.name;
            });
            setCities(response.data.result);
        });
        Axios.get('/v10/operators2').then(response => setOperators(response.data.operators));
    }

    async function startEditing(id: number | undefined) {
        if (id === undefined) {
            setCurrentOperator({
                id: 0,
                city_id: 0,
                name: '',
                fault_report_email: '',
                hide_location: false,
                // highBackpressureThreshold: undefined,
            });
        } else {
            const operator = deepClone(operators.find(e => e.id === id)!);
            if (!operator) {
                throw new Error('Internal error');
            }
            /* if (props.groups.admin && operator.id) {
                const response = await Axios.get('/v10/alarmRuleParams/operator/' + operator.id);
                operator.highBackpressureThreshold = response.data.highBackpressureThreshold;
            } */
            setCurrentOperator(operator);
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentOperator, name, value);
    }

    function validate() {
        if (!currentOperator?.city_id) {
            return t("Please specify a country & city");
        } else if (currentOperator?.name?.trim() === "") {
            return t("Please specify an operator name");
        }
        return "";
    }

    function getSanitizedEditingValues() {
        return {
            operator_id: Number(currentOperator?.id),
            city_id: currentOperator?.city_id,
            name: currentOperator?.name?.trim(),
            fault_report_email: (currentOperator?.fault_report_email ? currentOperator?.fault_report_email.trim() : null),
            hide_location: currentOperator?.hide_location,
            // highBackpressureThreshold: Number(currentOperator?.highBackpressureThreshold),
        };
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}
            onDataUpdated={props.onDataUpdated}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentOperator'
            }}

            texts={{
                localizedAddNewText: t('Add new operator'),
                confirmDelete: t('Are you sure you want to delete the operator?'),
                errors: {
                    generic: t("Operator couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("An Operator with that name already exists"),
                    deleteInUse: t("Operator is in use and cannot be deleted"),
                    deleteGeneric: t("Operator couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ currentOperator, cities, operators }}
            entityName='operators'

            tableHeaders={[
                { localizedText: t('Name'), name: 'name', sortable: true },
                { localizedText: t('Country'), name: 'country_name', sortable: true },
                { localizedText: t('City'), name: 'city_name', sortable: true },
                { localizedText: t('Fault report email'), name: 'fault_report_email' },
                { localizedText: t('Hide location'), name: 'hide_location', type: 'boolean'},
                { localizedText: t('Edit'), type: 'edit' },
                { localizedText: t('Delete'), type: 'delete' }
            ]}

            formFields={[
                { label: t('City'), name: 'city_id', allowedValues: { entityName: 'cities', name: 'countryAndCity' } },
                { label: t('Operator Name'), name: 'name', placeholder: t('Enter an operator name'), maxLength: 255, type: 'string' },
                { label: t('Fault report email addresses'), name: 'fault_report_email', placeholder: t('Enter comma-separated list of email addresses for fault report'), maxLength: 320, type: 'multipleEmails' },
                { label: t('Hide vehicle map'), name: 'hide_location', type: 'checkbox' },
                // { label: t('High Backpressure Threshold (greater than)'), name: 'highBackpressureThreshold', visible: isAdmin, placeholder: t('Override default backpressure threshold') },
            ]}
        />
    );
}

export default OperatorManagement;
