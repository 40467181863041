import React, { useState, } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { Entity, NamedEntity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';

interface SubcontractorCompanyEntity extends Entity {
    users?: string[];
    operators?: number[];
}

function SubcontractorCompanyManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
}>) {

    const [companies, setCompanies] = useState([] as Entity[]);
    const [currentCompany, setCurrentCompany] = useState<SubcontractorCompanyEntity>({ id: 0 });
    const [operators, setOperators] = useState([] as NamedEntity[]);
    const [users, setUsers] = useState([] as SubcontractorCompanyEntity[]);

    const restPath = '/v10/subcontractor_company/';

    const { t } = useTranslation();

    function fetchData() {
        Axios.get('/v10/subcontractor_company').then(response => setCompanies(response.data.companies));
        Axios.get('/v10/users').then(response => {
            const installerUsers = response.data.users.filter(user => user.groupIds?.indexOf("role_installer") !== -1 || user.groupIds?.indexOf("role_installerManager") !== -1);
            setUsers(installerUsers);
        });
        fetchOperators();
    }

    async function fetchOperators() {
        const response = await Axios.get('/v10/operators');
        const operatorList = response.data.operators;
        setOperators(operatorList);
    }

    async function startEditing(id) {
        if (id === undefined) {
            setCurrentCompany({ id: 0, name: '', users: [], operators: [] });
        } else {
            const company = companies.find(e => e.id === id);
            if (!company) {
                throw new Error('Internal error');
            }
            setCurrentCompany(deepClone(company));
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentCompany, name, value);
    }

    function validate() {
        return (currentCompany?.name?.trim() === "") ? t("Please specify a company name") : "";
    }

    function getSanitizedEditingValues() {
        return { name: currentCompany?.name?.trim(),
                 users: currentCompany.users,
                 operators: currentCompany.operators };
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentCompany'
            }}

            texts={{
                localizedAddNewText: t('Add new company'),
                confirmDelete: t('Are you sure you want to delete the company?'),
                errors: {
                    generic: t("Company couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("A Company with that name already exists"),
                    deleteInUse: t("Company is in use and cannot be deleted"),
                    deleteGeneric: t("Company couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ companies: companies, currentCompany: currentCompany, operators: operators, users: users }}
            entityName='companies'

            tableHeaders={[{ localizedText: t('Company'), name: 'name' },
                           { localizedText: t('Edit'), type: 'edit' },
                           { localizedText: t('Delete'), type: 'delete' }]}

            formFields={[{ label: t('Company Name'), name: 'name', placeholder: t('Enter a company name'), maxLength: 255 },
                         { label: t('Users'), name: 'users', allowedValues: { entityName: 'users', name: 'id', multi: true }},
                         { label: t('Operators'), name: 'operators', allowedValues: { entityName: 'operators', name: 'name', multi: true }}
                        ]}
        />
    );
}

export default SubcontractorCompanyManagement;
