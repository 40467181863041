import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationFI from './locales/fi/translation.json';
import translationSV from './locales/sv/translation.json';
import translationDE from './locales/de/translation.json';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const resources = {
  en: {
    translation: translationEN
  },
  fi: {
    translation: translationFI
  },
  sv: {
    translation: translationSV
  },
  de: {
    translation: translationDE
  },
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    // debug: true,
    /*saveMissing: false, // send not translated keys to endpoint
    saveMissingTo: 'all',*/
    nsSeparator: ':::', // change key separator from .
    keySeparator: '::', // change key separator from .
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }/*,
    backend: {
      addPath: 'locales/{{lng}}/translation.json',
      jsonIndent: 2
    }*/
  });


export default i18n;