import React, {useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as logger from "../utilities/logger";
import ProgressBar from './ProgressBar';

import Axios from 'axios';
import moment from 'moment';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { constructCancelObject, cancellableAxiosCall, round } from '../utilities/generic';

// TODO: this is ugly, but react complicates quite a bit do it in other ways
let timerId: ReturnType<typeof setTimeout> | undefined;
let testDriveRunning = false;

function InstallationStatus(props: Readonly<{
    auth: AuthState,
    vehicleId: number,
    gatewayId: string,
    startTime: string, // TODO: Make this Date
    onClose: () => void;
}>) {

    const { t } = useTranslation();

    const [state, setState] = useState(
        {
            sensorData: {} as {
                backpressure: number | undefined,
                batteryVoltage: number | undefined,
                defLevel: number | undefined,
                dosingRate: number | undefined,
                maf: number | undefined,
                noxIn: number | undefined,
                noxOut: number | undefined,
                tempDef: number | undefined,
                tempEnv: number | undefined,
                tempIn: number | undefined,
                pumpState: number | undefined,
            },
            faults: [{} as {
                timestamp: string,
                description: string
            }],
            currentNoxReduction: {} as {
                seconds: number | undefined,
                reduction_ppm: number | undefined,
                reduction_perc: number | undefined
            },
            acceptanceCriteria: {} as {
                ureaRule: { status: boolean },
                tempRule: { status: boolean },
                pumpRule: { status: boolean },
                noxInRule: { status: boolean },
                pumpRule3: { status: boolean },
                noxReductionRule: { status: boolean },
                DefTemperatureRule: { status: boolean },
                backpressureRule: { status: boolean },
            },
            errorMessage: '',
            timeLeftInMinutes: 24 * 60
        }
    );

    const [cancelObject] = useState(constructCancelObject());

    useEffect(() => {
        timerId = setInterval(() => {
            getData().then();
        }, 3000);
        getData().then();
        return () => {
            if (cancelObject.cancel) {
                cancelObject.cancel();
            }
            cancelOngoingTimer();
        };
    }, []);

    async function getData() {
        if (timerId && !testDriveRunning) {
            testDriveRunning = true;
            try {
                const result = await cancellableAxiosCall({ url: `/v10/testDrive/${props.vehicleId}` }, cancelObject);
                if (timerId && result.status === 200) {
                    const obj = {...state};
                    obj.sensorData = result.data.data.sensorData;
                    obj.acceptanceCriteria = result.data.data.acceptanceCriteria;
                    obj.timeLeftInMinutes = updateTimer();
                    obj.faults = result.data.data.faults;
                    obj.currentNoxReduction = result.data.data.currentNoxReduction ? result.data.data.currentNoxReduction[0] : undefined;
                    setState(obj);
                }
                if (result.data.data.installationStatusIsSuccess) {
                    cancelOngoingTimer();
                }
            } catch (err) {
                if (!err.isCancel) {
                    logger.err("GET /testDrive failed", err.message || err);
                }
            } finally {
                testDriveRunning = false;
            }
        }
    }

    function updateTimer() {
        const endTime = new Date(props.startTime).getTime() + 24 * 60 * 60 * 1000;
        const timeNow = new Date();
        const timeLeftInMinutes = (endTime - timeNow.getTime()) / (60 * 1000);
        return timeLeftInMinutes;
    }

    function cancelOngoingTimer() {
        if (timerId) {
            clearTimeout(timerId);
            timerId = undefined;
        }
    }

    function closeView() {
        props.onClose();
    }

    function getTimeLeftForInstallation() {
        const left = (state.timeLeftInMinutes / (24 * 60)) * 100;
        return (left > 0) ? left : 0;
    }

    async function deleteInstallation() {
        await Axios.delete(`/v10/installation/${props.vehicleId}/${props.gatewayId}`);
        closeView();
    }

    function renderNoxReduction() {
        if (state.currentNoxReduction && state.currentNoxReduction.reduction_perc && state.currentNoxReduction.seconds) {
            const reduction = Math.round(state.currentNoxReduction.reduction_perc);
            const secondsPerMinute = 60;
            const ageInMinutes = Math.round(state.currentNoxReduction.seconds / secondsPerMinute);
            return (<p>{t(`Last `)}{ageInMinutes}{t(' min NOx reduction')}: {reduction}%</p>);
        } else {
            return (<p>{t('Last 15 min NOx reduction')}: NaN</p>);
        }
    }

    function renderPumpState() {
        if (state.sensorData?.pumpState) {
            switch (state.sensorData.pumpState) {
                case 0: return 'Initializing';
                case 1: return 'Off';
                case 2: return 'Prime';
                case 3: return 'Dose';
                case 4: return 'Purge';
                case 5: return 'Diagnostic Override';
                case 9: return 'Shut OFF';
                default: return 'Not available';
            }
        } else {
            return "NaN";
        }
    }

    function renderFaults() {
        let haveFaults = false;
        const faultsRender = state.faults.map((fault, i) => {
            haveFaults = true;
            return(<div key={i}>{moment(fault.timestamp).format('YYYY-MM-DD HH:MM')} {fault.description}</div>);
        });
        if (haveFaults) {
            return faultsRender;
        } else {
            return(<div><p>{t('None')}</p></div>);
        }
    }

    return (
        <div className="columns is-multiline" style={{background: 'white'}}>
            <div className="column is-12">
                <h1 className="title">{t('Installation status')}</h1>
            </div>
            <div className="column is-12">
                <h2 className="title">{t('Timer')}</h2>
            </div>
            <div className="column is-12">
                <ProgressBar
                    status="is-info"
                    value={(state.timeLeftInMinutes / (24 * 60)) * 100}
                />
                { getTimeLeftForInstallation() === 0 &&
                    <h1 style={{ color: 'red', fontSize: 'x-large' }}>{t('Installation is overdue, you can see the installation success criteria but the installation state wont change')}</h1>
                }
            </div>
            <div className="column is-12">
                <p><strong>{Math.round(state.timeLeftInMinutes)} {t('Minutes left')}</strong></p>
            </div>
            <div className="column is-12">
                <h2 className="title">{t('Sensor values')}</h2>
            </div>
            <div className="column is-12">
                <p>{t('Battery voltage')}: {typeof state.sensorData?.batteryVoltage === 'number' ? round(state.sensorData.batteryVoltage, 1) : "NaN"} V</p>
            </div>
            <div className="column is-12">
                <p>{t('Def level')}: {typeof state.sensorData?.defLevel === 'number' ? round(state.sensorData.defLevel, 1) : "NaN"} %</p>
            </div>
            <div className="column is-12">
                <p>{t('Temp def')}: {typeof state.sensorData?.tempDef === 'number' ? round(state.sensorData.tempDef, 1) : "NaN"} C</p>
            </div>
            <div className="column is-12">
                <p>{t('Pump State')}: {renderPumpState()}</p>
            </div>
            <div className="column is-12">
                <p>{t('Dosing capacity')}: {typeof state.sensorData?.dosingRate === 'number' ? round(state.sensorData.dosingRate, 1) : "NaN" } ml/h</p>
            </div>
            <div className="column is-12">
                <p>{t('MAF')}: {typeof state.sensorData?.maf === 'number' ? round(state.sensorData.maf, 1) : "NaN"} kg/h</p>
            </div>
            <div className="column is-12">
                <p>{t('Exhaust inlet temp')}: {typeof state.sensorData?.tempIn === 'number' ? round(state.sensorData.tempIn, 1) : "NaN"} ⁰C</p>
            </div>
            <div className="column is-12">
                <p>{t('Backpressure')}: {typeof state.sensorData?.backpressure === 'number' ? round(state.sensorData.backpressure, 1) : 'NaN'} mbar</p>
            </div>
            <div className="column is-12">
                <p>{t('NOx In')}: {typeof state.sensorData?.noxIn === 'number' ? round(state.sensorData.noxIn, 1) : "NaN"} ppm</p>
            </div>
            <div className="column is-12">
                <p>{t('NOx Out')}: {typeof state.sensorData?.noxOut === 'number' ? round(state.sensorData.noxOut, 1) : "NaN"} ppm</p>
            </div>
            <div className="column is-12">
                {renderNoxReduction()}
            </div>
            <div className="column is-12">
                <p>{t('Temp env')}: {typeof state.sensorData?.tempEnv === 'number' ? round(state.sensorData.tempEnv, 1) : "NaN"} ⁰C</p>
            </div>
            <div className="column is-12">
                <p>{t('Active fault codes')}&nbsp;(10 min):</p>
                <div style={{ marginLeft: '10px'}}>
                    { renderFaults() }
                </div>
            </div>
            <div className="column is-12">
                <h2 className="title">{t('Installation success criteria statuses')}</h2>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Urea Level')} &gt; 20%</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.ureaRule && state.acceptanceCriteria.ureaRule.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.ureaRule && state.acceptanceCriteria.ureaRule.status ? 100 : 40 }
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Temperature increased after MAF')} &gt; 25</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.tempRule && state.acceptanceCriteria.tempRule.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.tempRule && state.acceptanceCriteria.tempRule.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Pump reached state')} 2</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                           status={state.acceptanceCriteria.pumpRule && state.acceptanceCriteria.pumpRule.status ? "is-success" : "is-danger" }
                           value={state.acceptanceCriteria.pumpRule && state.acceptanceCriteria.pumpRule.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('NOx in has exceeded 800 ppm in 5 minutes after temperature has exceeded 150 C')}</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.noxInRule && state.acceptanceCriteria.noxInRule.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.noxInRule && state.acceptanceCriteria.noxInRule.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Pump reached state 3 in 5 minutes after temperature has exceeded 160 C')}</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.pumpRule3 && state.acceptanceCriteria.pumpRule3.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.pumpRule3 && state.acceptanceCriteria.pumpRule3.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Temperature reached 240 C, 90% temporarily NOx reduction reached and 15 min average reduction')} &gt;= 80%</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.noxReductionRule && state.acceptanceCriteria.noxReductionRule.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.noxReductionRule && state.acceptanceCriteria.noxReductionRule.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Def temperature has plausible values')}</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.DefTemperatureRule && state.acceptanceCriteria.DefTemperatureRule.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.DefTemperatureRule && state.acceptanceCriteria.DefTemperatureRule.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <p>{t('Backpressure > 5 mbar during test drive')}</p>
                    </div>
                    <div className="column is-4">
                        <ProgressBar
                            status={state.acceptanceCriteria.backpressureRule && state.acceptanceCriteria.backpressureRule.status ? "is-success" : "is-danger" }
                            value={state.acceptanceCriteria.backpressureRule && state.acceptanceCriteria.backpressureRule.status ? 100 : 40}
                        ></ProgressBar>
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <div className="columns">
                    <div className="column is-8">
                        <button
                            className="button is-medium is-4 is-rounded"
                            onClick={closeView}
                            style={{ backgroundColor: '#f4c326', color: 'white', marginBottom: '10px', marginTop: '10px' }}
                        >{t('Back')}
                        </button>
                    </div>
                    {props.auth.groups.admin &&
                        <div>
                            <button
                                className="button is-medium is-4 is-rounded"
                                onClick={deleteInstallation}
                                style={{ backgroundColor: '#f4c326', color: 'white', marginBottom: '10px', marginTop: '10px' }}
                            >{t('Delete installation')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default InstallationStatus;
