import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from "react-dropdown-select";

import RouteContext from '../contexts/RouteContext';
import * as actionTypes from '../Actions/Actions';
import { Vehicle, Operator } from '../reducers/filterSelections';
import * as logger from "../utilities/logger";
import { constructCancelObject, cancellableAxiosCall } from '../utilities/generic';
import { deepClone } from '../utilities/generic';

function FilterSelectorsMobile() {

    const FiltersValues = {
        operators: Array<Operator>(),
        vehicle: Array<Vehicle>(),
    };

    const { t } = useTranslation();
    const context = useContext(RouteContext);

    const [cancelObjectVehicles] = useState(constructCancelObject());
    const [cancelObjectOperators] = useState(constructCancelObject());
    const [vehicles, setVehicles] = useState([] as Vehicle[]);
    const [filteredVehicles, setFilteredVehicles] = useState([] as Vehicle[]);
    const [operators, setOperators] = useState([] as Operator[]);
    const [selectedValues, setSelectedValues] = useState(deepClone(FiltersValues));

    useEffect(() => {
        getFilterValues();
    }, []);

    async function getFilterValues() {
        try {
            const [operatorsResult,
                vehiclesResult] = await Promise.all([
                    cancellableAxiosCall({ url: `/v10/operators` }, cancelObjectOperators),
                    cancellableAxiosCall({ url: `/v10/vehicle?type=filterSelectors` }, cancelObjectVehicles)
                ]);
            setOperators(operatorsResult.data.operators);
            const allVehicles = vehiclesResult.data.result.vehicles;
            setVehicles(allVehicles);
            setFilteredVehicles(allVehicles);
        } catch (err) {
            if (!err.isCancel) {
                logger.err(err.message || err);
            }
        }
    }

    useEffect(() => {
        if (context) {
            context.filterSelectionsDispatch({
                type: actionTypes.SET_FILTERS_SELECTED_VALUES,
                operators: selectedValues.operators,
                vehicles: deepClone(selectedValues.vehicle.map(({ id }) => ({ id: id, sourceId: '' })))
            }
            );
        }
    }, [selectedValues]);

    function handleOperatorSelection(operator: Operator[]) {
        setSelectedValues(({
            vehicle: [],
            operators: operator
        }));
        if (Object.keys(operator).length > 0) {
            setFilteredVehicles(vehicles.filter(option => operator.filter(filterValue => filterValue.id === option.operator_id).length > 0));
        } else {
            setFilteredVehicles(vehicles);
        }
    }

    function handleVehicleSelection(vehicle: Vehicle[]) {
        setSelectedValues(prevState => ({
            ...prevState,
            vehicle: vehicle
        }));
    }

    return (
        <>
            <div style={{ marginTop: '8px', marginRight: '25px', marginLeft: '8px', width: '100%' }}>
                <form className="is-pulled-left mobile-filters" style={{ width: '100%' }}>
                    <div className="is-clearfix">
                        { operators.length > 1 && <div style={{ width: "50%" }} className="is-pulled-left">
                            <div style={{ width: '100%', background: '#fff' }} className="is-pulled-left" key={'operators'}>
                                {<Select
                                    options={operators}
                                    values={operators.length === 1 ? operators : selectedValues.operators}
                                    clearable={operators.length > 0}
                                    disabled={operators.length <= 1}
                                    placeholder={t('Operators')}
                                    valueField="id"
                                    labelField={"name"}
                                    searchBy={"name"}
                                    loading={operators.length === 0}
                                    onChange={(vehiclesSelection) => handleOperatorSelection(vehiclesSelection)}
                                    className={"dropdownToLeft"}
                                />}
                            </div>
                        </div>
                        }
                        <div style={{ width: "50%" }} className="is-pulled-left">
                            <div style={{ width: '100%', background: '#fff' }} className="is-pulled-left" key={'vehicles'}>
                                {<Select
                                    options={filteredVehicles}
                                    values={vehicles.length === 1 ? vehicles : selectedValues.vehicle}
                                    clearable={filteredVehicles.length > 0}
                                    clearOnSelect={true}
                                    disabled={filteredVehicles.length <= 1}
                                    placeholder={t('Vehicles')}
                                    loading={filteredVehicles.length === 0}
                                    valueField="id"
                                    labelField={"description"}
                                    searchBy={"description"}
                                    onChange={(vehiclesSelection) => handleVehicleSelection(vehiclesSelection)}
                                    className={operators.length > 1 ? "dropdownToRight" : "dropdownToLeft"}
                                />}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default FilterSelectorsMobile;
