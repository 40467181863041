import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Settings.css';

import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';

import UserManagement from './UserManagement';
import TwoFactorAuthenticator from './TwoFactorAuthenticator';
import BodyManufacturerManagement from './BodyManufacturerManagement';
import BodyModelManagement from './BodyModelManagement';
import EngineManufacturerManagement from './EngineManufacturerManagement';
import EngineModelManagement from './EngineModelManagement';
import AlarmRuleManagement from './AlarmRuleManagement';
import EmissionClassManagement from './emissionClassManagement';
import SubcontractorCompanyManagement from './subcontractorCompanyManagement';
import OperatorManagement from './operatorManagement';
import CountryManagement from './countryManagement';
import CityManagement from './cityManagement';
import SiteManagement from './siteManagement';

function SettingsNew(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    loggedInUser: string,
    onDataUpdated: () => void
}>) {

    const { t } = useTranslation();

    return (
        <div>
            <div className="columns">
                <div className="column is-12">
                    <h1 className="title"
                        style={{ paddingTop: '10px' }}
                    >
                        {t('Settings')}
                    </h1>
                </div>
            </div>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="container">
                        <div className="box">
                            <div className="media-content">
                                <div className="content">
                                    <Tabs>
                                        {/* Parent level tabs */}
                                        <TabList>
                                            <Tab>{t('Users')}</Tab>
                                            {props.groups.admin && <Tab>{t('Two-Factor Authenticator')}</Tab>}
                                            {props.groups.admin && <Tab>{t('Operator Info')}</Tab>}
                                            {props.groups.expert && <Tab>{t('Sites')}</Tab>}
                                            {props.groups.admin && <Tab>{t('Subcontracting Companies')}</Tab>}
                                            {props.groups.admin && <Tab>{t('Vehicle Info')}</Tab>}
                                            {props.groups.admin && <Tab>{t('Regional Info')}</Tab>}
                                            {props.groups.admin && <Tab>{t('Alarm Rule Parameters')}</Tab>}
                                        </TabList>

                                        { /* Users */}
                                        <TabPanel>
                                            <UserManagement
                                                groups={props.groups}
                                                filterSelections={props.filterSelections}
                                                loggedInUser={props.loggedInUser}
                                            />
                                        </TabPanel>

                                        { /* Two-Factor Authenticator */}
                                        {props.groups.admin &&
                                        <TabPanel>
                                            <TwoFactorAuthenticator />
                                        </TabPanel>
                                        }

                                        {/* Sites management in parent tab level for Engineer admin (expert) role */}
                                        {props.groups.expert &&
                                            <TabPanel>
                                                <SiteManagement
                                                    groups={props.groups}
                                                    filterSelections={props.filterSelections}
                                                    onDataUpdated={props.onDataUpdated}
                                                />
                                            </TabPanel>
                                        }

                                        {/* Operator info child tabs */}
                                        {props.groups.admin &&
                                            <TabPanel>
                                                <Tabs forceRenderTabPanel>
                                                    <TabList>
                                                        {props.groups.admin && <Tab>{t('Operator Details')}</Tab>}
                                                        {(props.groups.admin) && <Tab>{t('Sites')}</Tab>}
                                                    </TabList>
                                                    {props.groups.admin &&
                                                        <TabPanel>
                                                            <OperatorManagement
                                                                groups={props.groups}
                                                                filterSelections={props.filterSelections}
                                                                onDataUpdated={props.onDataUpdated}
                                                            />
                                                        </TabPanel>
                                                    }
                                                    {props.groups.admin &&
                                                        <TabPanel>
                                                            <SiteManagement
                                                                groups={props.groups}
                                                                filterSelections={props.filterSelections}
                                                                onDataUpdated={props.onDataUpdated}
                                                            />
                                                        </TabPanel>
                                                    }
                                                </Tabs>
                                            </TabPanel>
                                        }

                                        {/* Subcontractor company management */}
                                        {props.groups.admin &&
                                        <TabPanel>
                                            <SubcontractorCompanyManagement
                                                groups={props.groups}
                                                filterSelections={props.filterSelections}
                                            />
                                        </TabPanel>
                                        }

                                        { /* Vehicle info child tabs */}
                                        {props.groups.admin &&
                                        <TabPanel>
                                            <Tabs forceRenderTabPanel>
                                                <TabList>
                                                    <Tab>{t('Chassis')}</Tab>
                                                    <Tab>{t('Bodies')}</Tab>
                                                    <Tab>{t('Engine Manufacturers')}</Tab>
                                                    <Tab>{t('Engine Models')}</Tab>
                                                    <Tab>{t('Emission Classes')}</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <BodyManufacturerManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <BodyModelManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <EngineManufacturerManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <EngineModelManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <EmissionClassManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                        </TabPanel>
                                        }

                                        {/* Regional info child tabs */}
                                        {props.groups.admin &&
                                        <TabPanel>
                                            <Tabs forceRenderTabPanel>
                                                <TabList>
                                                    <Tab>{t('Cities')}</Tab>
                                                    <Tab>{t('Countries')}</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <CityManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <CountryManagement
                                                        groups={props.groups}
                                                        filterSelections={props.filterSelections}
                                                        onDataUpdated={props.onDataUpdated}
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                        </TabPanel>
                                        }

                                        { /* Alarm rule parameters */}
                                        {props.groups.admin &&
                                        <TabPanel>
                                            <AlarmRuleManagement
                                                groups={props.groups}
                                                filterSelections={props.filterSelections}
                                            />
                                        </TabPanel>
                                        }
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsNew;
