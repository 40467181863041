import React, { useState, useEffect, CSSProperties } from 'react';
import OfficialTable from './OfficialTable';
import Axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import Select from "react-dropdown-select";
import VehicleStats from './VehicleStats';
import { cancellableAxiosCall, constructCancelObject, formatDate } from '../utilities/generic';

const menuControlStyle: CSSProperties = { minWidth: "50px", display: "inline-block", marginRight: 10 };

function Official(props: Readonly<{
    authState: AuthState
}>) {

    const { t } = useTranslation();

    const [state, setState] = useState({
        vehicles: [],
        timeStamp: new Date()
    });

    const [cities, setCities] = useState<unknown>([]);
    const [selectedCity, setSelectedCity] = useState<number[]>([]);

    const [selectedVehicle, setSelectedVehicle] = useState<{ vehicleId: number, now: Date, nowMinus60Days: Date } | undefined>(undefined);

    useEffect(() => {
        getResults();
    }, []);

    function canSelectCity() {
        return props.authState.groups.admin || props.authState.groups.rd;
    }

    const [cancelObject] = useState(constructCancelObject());

    async function getResults() {
        Axios.get('/v10/cities?sort=name').then(response => setCities(response.data.result));
        let useCity = props.authState.userCity;
        if (canSelectCity()) {
            if (selectedCity.length === 0) {
                setState({
                    vehicles: [],
                    timeStamp: new Date()
                });
                return;
            }
            useCity = selectedCity[0];
        }
        const results = await cancellableAxiosCall({ url: `/v10/official/${useCity}` }, cancelObject);

        const vehicles = results.data;

        setState({
            vehicles: vehicles,
            timeStamp: new Date()
        });
    }

    function handleFormChange(items) {
        setSelectedCity((items.length > 0) ? [items[0].id] : []);
    }

    function renderCitySelector() {
        return (<div style={menuControlStyle}>
                    <Select
                        disabled={selectedVehicle}
                        options={cities}
                        values={[]}
                        clearable={true}
                        placeholder={t("Cities")}
                        valueField="id"
                        labelField={"name"}
                        searchBy={"name"}
                        onChange={(theValues) => handleFormChange(theValues)}
                    />
                </div>);
    }

    return (
        <div>
            <div className="columns is-fullheight">
                <div className="column is-fullwidth">
                    <div className="container">
                        <div className="box">
                            <nav className="level">
                                <div className="level-left">
                                    {canSelectCity() &&
                                        renderCitySelector()
                                    }
                                    <div className="level-item">
                                        <div className="control">
                                            <button className="button is-small is-rounded"
                                                style={{ backgroundColor: '#015B9F', color: 'white' }} onClick={() => getResults()}>{t("Update")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="level-right">
                                    <div className="level-item has-text-weight-bold">{t("Last updated") + ': '}</div>
                                    <div className="level-item has-text-weight-normal is-italic">{formatDate(state.timeStamp, "datetimeWithSeconds")}</div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns is-multiline">
                <div className="column is-12">
                    {
                        !selectedVehicle &&
                        <OfficialTable
                            vehicles={state.vehicles}
                            onVehicleSelect={vehicleId => setSelectedVehicle({ vehicleId, now: new Date(), nowMinus60Days: moment().subtract(60, 'days').toDate() })}
                        />
                    }
                    {
                        selectedVehicle &&
                        <>
                            <button
                                className="button is-medium is-4 is-rounded"
                                onClick={() => setSelectedVehicle(undefined)}
                                style={{ backgroundColor: '#f4c326', color: 'white', marginBottom: '10px', marginTop: '10px' }}
                            >{t('Back')}
                            </button>
                            <VehicleStats
                                vehicle={selectedVehicle.vehicleId}
                                startDate={selectedVehicle.nowMinus60Days}
                                endDate={selectedVehicle.now}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Official;
