import React, { useState, Dispatch, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import * as actionTypes from '../Actions/Actions';

import LoginForm, { AuthFailureReason } from './LoginForm';
import axios from 'axios';
import { LoginAction, LogoutAction } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';
import * as logger from "../utilities/logger";

const encodeForm = (data) => {
    return Object.keys(data)
        .filter(key => data[key])
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

export type LoginAndLogoutDispachActions = LoginAction | LogoutAction ;

export async function getClientConfiguration(
    authenticatedDispatch: Dispatch<LoginAndLogoutDispachActions>,
    params?: {
        email: string | undefined,
        password: string | undefined,
        totpToken: string | undefined,
        oneTimePassword: string | undefined,
        newPassword: string | undefined,
    }
) {
    try {
        let postData: string | undefined;
        if (params) {
            if (params.email && typeof params.password) {
                postData = encodeForm({
                    "username": params.email || undefined,
                    "password": params.password || undefined,
                    "authenticator-token": params.totpToken || undefined,
                    "new-password": params.newPassword || undefined,
                    "new-password-confirm": params.newPassword || undefined,
                });
            } else if (params.oneTimePassword) {
                postData = encodeForm({
                    "new-password": params.newPassword || undefined,
                    "new-password-confirm": params.newPassword || undefined,
                });
            }
        }
        const config = await axios.post(
            `/v10/clientconfig${ params && params.oneTimePassword ? "?access_token=" + params.oneTimePassword : ''}`,
            postData,
        );
        logger.setLogLevelFromServer(config.data.logLevel);
        logger.info("Procare client config: ", config ? config.data : undefined);
        authenticatedDispatch({
            type: actionTypes.SET_CLIENTCONFIG_AND_LOGIN,
            serverVersion: config.data.serverVersion,
            userName: config.data.userName,
            userCity: config.data.userCity,
            groups: config.data.groups,
            hideLocation: config.data.hideLocation,
            eppMapScriptUrl: config.data.eppMapScriptUrl,
            eppRulesToListen: config.data.eppRulesToListen,
            maxBucketCountForSensorDataFetch: config.data.maxBucketCountForSensorDataFetch,
        });
        const pageTitleElem = document.getElementById('page-title');
        if (pageTitleElem && config.data.envTitle) {
            pageTitleElem.innerText = config.data.envTitle;
        }
    } catch (err) {
        const responseData = err.response && err.response.data ? err.response.data : {};
        logger.setLogLevelFromServer(responseData.logLevel);
        logger.warn(err.message || err);
        authenticatedDispatch({
            type: actionTypes.LOGOUT,
            serverVersion: responseData.serverVersion,
        });
        throw err;
    }
}

function Login(props: RouteComponentProps & Readonly<{
    authenticatedDispatch,
}>) {

    const { t } = useTranslation();

    const oneTimePassword = logger.getUrlParameterByName("otp");
    const [state, setState] = useState(
        {
            email: undefined as string | undefined,
            password: undefined as string | undefined,
            totpToken: undefined as string | undefined,
            oneTimePassword: oneTimePassword as string | undefined,
            newPassword: undefined as string | undefined,
            newPasswordConfirm: undefined as string | undefined,
            errorMessage: '',
            authFailureReason: undefined as AuthFailureReason | undefined,
        }
    );

    useEffect(
        () => {
            if (state.oneTimePassword) {
                attemptLogin();
            }
        },
        []
    );

    function attemptLogin() {
        getClientConfiguration(props.authenticatedDispatch, state).then(() => {
            props.history.push('/');
        }).catch(err => {
            logger.debug("Login failed, not redirecting");
            const obj = { ...state };
            if (err.response && err.response.status && err.response.status === 401) {
                const responseData = err.response && err.response.data ? err.response.data : {};
                obj.authFailureReason = responseData.authFailureReason;
                if (obj.authFailureReason) {
                    obj.errorMessage = '';
                } else {
                    obj.errorMessage = t('Wrong username or password!');
                    obj.password = undefined;
                    obj.oneTimePassword = undefined;
                    obj.newPassword = undefined;
                    obj.newPasswordConfirm = undefined;
                }
                obj.totpToken = undefined;
             } else {
                obj.errorMessage = t('Not able to login. Please check internet connection or contact administrator!');
             }
            setState(obj);
        });
    }

    function handleFormChange(event) {
        const obj = { ...state };
        obj[event.target.name] = event.target.value;
        setState(obj);
    }

    function handleUserFormSubmit(event) {
        event.preventDefault();
        attemptLogin();
    }

    return (
        <div
            style={
                {
                    opacity: '0.70',
                    backgroundColor: 'white',
                    paddingBottom: '20px',

                }
            }>
            <LoginForm
                handleFormChange={handleFormChange}
                handleUserFormSubmit={handleUserFormSubmit}
                onCancel={() => {
                    setState(oldState => {
                        const obj = { ...oldState };
                        obj.authFailureReason = undefined;
                        obj.errorMessage = '';
                        obj.totpToken = undefined;
                        obj.newPassword = undefined;
                        obj.newPasswordConfirm = undefined;
                        return obj;
                    });
                }}
                state={state}
                enableSubmit={ !!(
                    (state.authFailureReason !== 'totpRequired' || state.totpToken?.length === 6) &&
                    state.newPassword === state.newPasswordConfirm
                ) }
            />
        </div>
    );
}

export default withRouter(Login);
