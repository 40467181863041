import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { Entity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from 'react-i18next';

function EngineManufacturerManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    onDataUpdated: () => void,
}>) {

    const [engineManufacturers, setEngineManufacturers] = useState([] as Entity[]);
    const [currentEngineManufacturer, setCurrentEngineManufacturer] = useState<Entity>({ id: 0, name: '' });

    const restPath = '/v10/engineManufacturer/';

    const { t } = useTranslation();

    function fetchData() {
        Axios.get('/v10/engineManufacturer').then(response => setEngineManufacturers(response.data.result));
    }

    async function startEditing(id) {
        if (id === undefined) {
            setCurrentEngineManufacturer({ id: 0, name: '' });
        } else {
            const manufacturer = engineManufacturers.find(e => e.id === id);
            if (!manufacturer) {
                throw new Error('Internal error');
            }
            setCurrentEngineManufacturer(deepClone(manufacturer));
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentEngineManufacturer, name, value);
    }

    function validate() {
        if (currentEngineManufacturer?.name?.trim() === "") {
            return t("Please specify an engine manufacturer name");
        }
        return "";
    }

    function getSanitizedEditingValues() {
        return { name: currentEngineManufacturer?.name?.trim() };
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}
            onDataUpdated={props.onDataUpdated}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentEngineManufacturer'
            }}

            texts={{
                localizedAddNewText: t('Add new engine manufacturer'),
                confirmDelete: t('Are you sure you want to delete the engine manufacturer?'),
                errors: {
                    generic: t("Engine manufacturer couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("An engine manufacturer with that name already exists for the given country"),
                    deleteInUse: t("Engine manufacturer is in use and cannot be deleted"),
                    deleteGeneric: t("Engine manufacturer couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ engineManufacturers: engineManufacturers, currentEngineManufacturer: currentEngineManufacturer }}
            entityName='engineManufacturers'

            tableHeaders={[{ localizedText: t('Engine Manufacturer'), name: 'name', sortable: true },
            { localizedText: t('Edit'), type: 'edit' },
            { localizedText: t('Delete'), type: 'delete' }]}

            formFields={[{ label: t('Engine Manufacturer Name'), name: 'name', placeholder: t('Enter an engine manufacturer name'), maxLength: 255 }]}
        />
    );
}

export default EngineManufacturerManagement;
