import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { Entity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { useTranslation } from "react-i18next";

function BodyManufacturerManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    onDataUpdated: () => void,
}>) {

    const [bodyManufacturers, setBodyManufacturers] = useState([] as Entity[]);
    const [currentBodyManufacturer, setCurrentBodyManufacturer] = useState<Entity>({ id: 0, name: '' });

    const restPath = '/v10/bodyManufacturer/';

    const { t } = useTranslation();

    function fetchData() {
        Axios.get('/v10/bodyManufacturer').then(response => setBodyManufacturers(response.data.result));
    }

    async function startEditing(id) {
        if (id === undefined) {
            setCurrentBodyManufacturer({ id: 0, name: '' });
        } else {
            const manufacturer = bodyManufacturers.find(e => e.id === id);
            if (!manufacturer) {
                throw new Error("Internal error");
            }
            setCurrentBodyManufacturer(deepClone(manufacturer));
        }
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentBodyManufacturer, name, value);
    }

    function validate() {
        if (currentBodyManufacturer?.name?.trim() === "") {
            return t("Please specify a chassis name");
        }
        return "";
    }

    function getSanitizedEditingValues() {
        return { name: currentBodyManufacturer?.name?.trim() };
    }

    return (
        <EntityManagement
            groups={props.groups}
            filterSelections={props.filterSelections}
            onDataUpdated={props.onDataUpdated}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentBodyManufacturer'
            }}

            texts={{
                localizedAddNewText: t('Add new chassis make and model'),
                confirmDelete: t('Are you sure you want to delete the chassis?'),
                errors: {
                    generic: t("Chassis couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("A chassis with that name already exists"),
                    deleteInUse: t("Chassis is in use and cannot be deleted"),
                    deleteGeneric: t("Chassis couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            entities={{ bodyManufacturers: bodyManufacturers, currentBodyManufacturer: currentBodyManufacturer }}
            entityName='bodyManufacturers'

            tableHeaders={[{ localizedText: t('Chassis'), name: 'name', sortable: true },
            { localizedText: t('Edit'), type: 'edit' },
            { localizedText: t('Delete'), type: 'delete' }]}

            formFields={[{ label: t('Chassis Name'), name: 'name', placeholder: t('Enter a chassis make and model name'), maxLength: 255 }]}
        />
    );
}

export default BodyManufacturerManagement;
