import React, { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as logger from "../utilities/logger";
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RouteContext from '../contexts/RouteContext';
import * as actionTypes from '../Actions/Actions';
import { Alarm } from '../utilities/epp-client';
import styles from "./NavBar.module.css";
import { formatDate } from '../utilities/generic';

export interface AlarmsState {
    alarms: Alarm[];
    updatedTimestamp: Date;
}

function Alarms(props: RouteComponentProps & Readonly<{
    authState: AuthState,
    alarms: AlarmsState,
    setAlarms,
}>) {

    const { t } = useTranslation();

    const context = useContext(RouteContext);

    function acknowledge(alarm: Alarm) {
        const eppClientInstance = context ? context.eppClientInstance : undefined;
        if (eppClientInstance && alarm && alarm.eventId) {
            eppClientInstance.ackAlarm(alarm.eventId, err => {
                if (!err) {
                    if (alarm.ruleStateOwnerProcessorId && alarm.vehicleId) {
                        eppClientInstance.resetRuleProcessorState(
                            alarm.ruleStateOwnerProcessorId, alarm.vehicleId, err2 => {
                                if (!err2) {
                                    props.setAlarms((oldState: AlarmsState) => {
                                        alarm.ackTime = new Date().toISOString();
                                        alarm.ackUserId = props.authState.userName;
                                        return {
                                            alarms: [ ...oldState.alarms ],
                                            updatedTimestamp: new Date(),
                                        };
                                    });
                                } else {
                                    logger.err('eppClientInstance.resetRuleProcessorState failed:', err2.message || err2);
                                }
                            }
                        );
                    } else {
                        logger.err('eppClientInstance.resetRuleProcessorState: alarm have no ruleStateOwnerProcessorId or vehicleId');
                    }
                } else {
                    logger.err('eppClientInstance.ackAlarm failed:', err.message || err);
                }
            });
        }
    }

    const alarms = props.alarms.alarms;

    return (
        <div>
            <div className="columns is-multiline">
                <div className="column is-12">
                    <div className="level-right">
                        <div className="level-item has-text-weight-bold">{t("Last updated") + ': '}</div>
                        <div className="level-item has-text-weight-normal is-italic">{props.alarms.updatedTimestamp ? formatDate(props.alarms.updatedTimestamp, "datetimeWithSeconds") : 'N/A'}</div>
                    </div>
                <table className="table is-hoverable is-fullwidth is-narrow is-bordered">
                    <thead>
                        <tr>
                            <th>{t('Time')}</th>
                            <th>{t('Alarm')}</th>
                            <th>{t('Vehicle')}</th>
                            <th>{t('Operator')}</th>
                            <th>{t('Chassis')}</th>
                            <th>{t('Body')}</th>
                            <th>{t('Acknowledge Time')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alarms.length > 0 &&
                            alarms.map((alarm, i) => {
                                const alarmMoment = moment(alarm.sensorDataTime || alarm.time);
                                return (
                                    <tr key={i}>
                                        <td>{ formatDate(alarmMoment, "datetimeWithSeconds") }</td>
                                        <td>{ alarm.alarmText }</td>
                                        <td><button className="islink" onClick={() => {
                                                if (context) {
                                                    context.filterSelectionsDispatch(
                                                        {
                                                            type: actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION,
                                                            fixedVehiclesSelection: [ { id: alarm.vehicleId } ],
                                                        }
                                                    );
                                                    context.timerangeDispatch(
                                                        {
                                                            type: actionTypes.SET_TIMERANGE,
                                                            startDate: alarmMoment.clone().subtract(2, 'hours').toDate(),
                                                            endDate: alarmMoment.clone().toDate(),
                                                        }
                                                    );
                                                    props.history.push('/');
                                                }
                                            }}
                                        >{ alarm.vehicleDescription }</button></td>
                                        <td>{ alarm.operatorName }</td>
                                        <td>{ alarm.manufacturerName }</td>
                                        <td>{ alarm.modelName }</td>
                                        {
                                            alarm.ackTime ?
                                            <td title={alarm.ackUserId}>
                                                { formatDate(alarm.ackTime, "datetimeWithSeconds") }
                                            </td> :
                                            <td className={styles.notificationColor}>
                                                <button className="button is-small is-rounded" style={{height: 24}} onClick={(e) => { e.preventDefault(); acknowledge(alarm); }}>{t('Acknowledge')}</button>
                                            </td>
                                        }
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Alarms);
