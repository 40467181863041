import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

export default function LoginForm() {

    const { t } = useTranslation();

    const [user, setUser] = useState<{
        id: string,
        hasTotpAuthSecret?: boolean,
    }>();
    const [totpSecretAndQr, setTotpSecretAndQr] = useState<{
        secret: string,
        qrSvg: string
    }>();
    const [totpAuthToken, setTotpAuthToken] = useState<string>();

    function refreshUser() {
        Axios.get('/v10/user').then(response => setUser(response.data.user));
    }

    useEffect(
        () => {
            refreshUser();
        },
        []
    );

    useEffect(
        () => {
            if (user && !user.hasTotpAuthSecret) {
                Axios.get('/v10/user/generateTotpSecretAndQr').then(response => {
                    setTotpSecretAndQr(response.data);
                });
            }
        },
        [user, user?.hasTotpAuthSecret]
    );

    function onFormSubmit() {
        if (user) {
            let secret: string;
            if (user.hasTotpAuthSecret) {
                secret = ''; // Empty string will deactivate the 2FA
            } else if (totpSecretAndQr?.secret && totpAuthToken) {
                secret = totpSecretAndQr?.secret;
            } else {
                throw new Error("Internal error");
            }
            Axios.patch('/v10/user/updateTotpAuthToken', {
                newTotpAuthSecret: secret,
                newTotpAuthToken: !user.hasTotpAuthSecret ? totpAuthToken : undefined,
            }).then(response => {
                refreshUser();
            });
        }
    }

    return user ? (
        <div>
            <div>&nbsp;</div>
            <div className="columns is-multiline is-mobile">
                <div className="column is-12 is-mobile">
                    <form onSubmit={event => { event.preventDefault(); onFormSubmit(); }}>
                        {user.hasTotpAuthSecret ?
                        <>
                        <div className="field">
                            <label className="label">{
                                t('Remove Two-Factor Authntication from user')
                            }&nbsp;{ user.id }</label>
                            <span></span>
                        </div>
                        </> :
                        <>
                        <div className="field">
                            <label className="label">{
                                t('Set Two-Factor Authntication for user')
                            }&nbsp;{ user.id }</label>
                        </div>
                        { totpSecretAndQr?.qrSvg && <div>
                            <div>{t('To activate, scan the QR code by authnticator app and give the authentication code from app')}</div>
                            <div style={{width: 200}} dangerouslySetInnerHTML={{ __html: totpSecretAndQr.qrSvg ?? '' }} />
                            <input
                                className={"input is-medium"}
                                type={"text"}
                                placeholder={t('code from authenticator app')}
                                onChange={event => setTotpAuthToken(event.target.value)}
                                autoFocus={true}
                                maxLength={6}
                            />
                        </div>}
                        </>}
                        <input
                            type='submit'
                            className="button is-medium is-4 is-rounded is-pulled-left"
                            value={t("Submit") as string}
                            disabled={ !user || (!user.hasTotpAuthSecret && (totpAuthToken?.length !== 6 )) }
                            style={{
                                backgroundColor: '#015B9F',
                                color: 'white',
                                marginBottom: '10px',
                                marginTop: '10px',
                                marginRight: '10px'
                            }}
                        />
                    </form>
                </div>
            </div>
        </div>
    ) : null;
}
