import React from 'react';
import { useTranslation } from 'react-i18next';

import HeaderLogo from './resources/proventia_logo_1_no_bg_208x102.png';

function AppHeader(props: Readonly<{
    currentUser: { language: string }
    handleLangFormChange: (event: { target: { value: string; }; }) => void,
    updateRequired: boolean,
}>) {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    return (
        <div style={
            {
                backgroundColor: '#FFFFFF'
            }
        }>
            <form>
                <div className="columns is-marginless is-vcentered is-mobile">
                    <div className="column is-one-third is-paddingless is-pulled-left is-mobile">
                        <img src={HeaderLogo} alt='HeaderLogo' style={
                            {
                                paddingLeft: '2%',
                                paddingTop: '1%',
                                marginBottom: '1%'
                            }} />
                    </div>
                    <div className="column is-two-third is-paddingless is-mobile ">
                        <div className="select is-pulled-right language-selection">
                            { props.updateRequired &&
                                <div style={{marginRight: "5px", cursor: 'pointer'}} onClick={() => { window.location.reload(); }}>
                                    <p style={{ color: '#ff8080'}}>{t('Update')}</p>
                                </div>
                            }
                            <select className="select"
                                name="language"
                                value={i18n.language}
                                onChange={props.handleLangFormChange}>
                                <option value="en-EN">English (United Kingdom)</option>
                                <option value="fi-FI">suomi (Suomi)</option>
                                <option value="sv-SV">svenska (Sverige)</option>
                                <option value="de-DE">Deutsch (Deutschland)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AppHeader;
