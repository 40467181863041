
import React, { useState } from 'react';
import Axios from 'axios';
import EntityManagement from './entityManagement';
import { AbstractEntity, NamedEntity } from './entityForm';
import { mergeStateValue, deepClone } from '../utilities/generic';
import { useTranslation } from 'react-i18next';
import { FilterSelections } from '../reducers/filterSelections';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import { EntityTableHeader } from './entityTable';

interface UserManagementEntity extends AbstractEntity<string> {
    newUser: boolean;
    description: string;
    secret: string | undefined;
    generateOneTimePasswordAndSendEmail: boolean;
    groupIds: string[];
    operatorId: number;
    operators: number[];
    role?: string;
    countryId?: number | null;
    cityId?: number | null;
    operatorName?: string;
    companyId?: number | null;
    disabled?: boolean;
}

function UserManagement(props: Readonly<{
    filterSelections: FilterSelections,
    groups: Groups,
    loggedInUser: string,
}>) {
    const { t } = useTranslation();

    const isAdmin = props.groups.admin;
    const isRd = props.groups.rd;

    const roles = [
        {id: 'admin', name: t('R&D Engineer Admin')},
        {id: 'rd', name: t('R&D Engineer')},
        {id: 'official', name: t('Public Official')},
        {id: 'regionalEngineer', name: t('Regional Engineer')},
        {id: 'expert', name: t('Engineer Admin')},
        {id: 'technician', name: t('Engineer')},
        {id: 'installer', name: t('Installer')},
        {id: 'installerManager', name: t('Installer Manager')},
    ];

    if (!isAdmin) {
        roles.shift();
    }

    const [operators, setOperators] = useState([] as NamedEntity[]);
    const [users, setUsers] = useState([] as UserManagementEntity[]);
    const [currentUser, setCurrentUser] = useState<UserManagementEntity>(constructNewUserObj());
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [showDisabled, setShowDisabled] = useState(false);

    const restPath = '/v10/user/'; // For adding or editing user

    function fetchData() {
        Axios.get('/v10/users').then(response => setUsers(response.data.users));
        Axios.get('/v10/countries').then(response => setCountries(response.data.result));
        Axios.get('/v10/cities?sort=name').then(response => setCities(response.data.result));
    }

    async function fetchOperators() {
        const response = await Axios.get('/v10/operators');
        const operatorList = response.data.operators;
        setOperators(operatorList);
    }

    function canCreateUser() {
        return isAdmin || isRd;
    }

    function shouldShowOperators() {
        return !(isCurrentUserRd() || isCurrentUserAdmin() || isCurrentUserOfficial() || isCurrentUserInstaller() );
    }

    function getOperatorIdOfUser(user) {
        const currentGroupIds = user.groupIds;
        const currentRoleGroup = currentGroupIds.find(g => g.startsWith(roleGroupPrefix));
        if (!currentRoleGroup) {
            throw new Error("Internal error");
        }
        const currentOperatorGroup = currentGroupIds.find(g => g.startsWith(operatorGroupPrefix));
        if (!currentOperatorGroup) {
            throw new Error("Internal error");
        }
        return Number(currentOperatorGroup.substr(operatorGroupPrefix.length));
    }

    const roleGroupPrefix = "role_";
    const operatorGroupPrefix = "operator_";

    async function startEditing(id) {
        if (canCreateUser()) {
            fetchOperators();
        } else {
            let hasOperatorAttribute = false;
            if (id !== undefined) {
                const user = users.find(u => u.id === id);
                if (!user) {
                    throw new Error("Internal error");
                }
                hasOperatorAttribute = user.groupIds.indexOf('role_installer') === -1 &&
                                       user.groupIds.indexOf('role_installerManager') === -1 &&
                                       user.groupIds.indexOf('role_official') === -1;
            }
            if (hasOperatorAttribute) {
                // We dont have operators list, we need this one so we can render properly the operator name
                const oneOperator = [{id: getOperatorIdOfUser(users[0]), name: users[0].operatorName || ""}];
                setOperators(oneOperator);
            } else {
                setOperators([]);
            }
        }
        if (id === undefined) {
            setCurrentUser(constructNewUserObj());
        } else {
            const user = users.find(u => u.id === id);
            if (!user) {
                throw new Error("Internal error");
            }
            const currentGroupIds = user.groupIds;
            const currentRoleGroup = currentGroupIds.find(g => g.startsWith(roleGroupPrefix));
            if (!currentRoleGroup) {
                throw new Error("Internal error");
            }
            user.role = currentRoleGroup.substr(roleGroupPrefix.length);
            const currentOperatorGroup = currentGroupIds.find(g => g.startsWith(operatorGroupPrefix));
            if (currentOperatorGroup) {
                user.operatorId = Number(currentOperatorGroup.substr(operatorGroupPrefix.length));
            }
            setCurrentUser(deepClone(user));
        }
    }

    function constructNewUserObj() {
        return {
            newUser: true,
            id: '',
            description: '',
            groupIds: [],
            role: roles[0].id,
            secret: undefined,
            generateOneTimePasswordAndSendEmail: true,
            disabled: false,
            operatorId: 0,
            operators: [],
            operatorName: '',
            countryId: 0,
            cityId: 0,
            companyId: 0,
        };
    }

    function changeValue(name, value) {
        mergeStateValue(setCurrentUser, name, value);
    }

    function getSanitizedEditingValues() {
        return {
            id: currentUser.id.trim(),
            description: (currentUser.description) ? currentUser.description.trim() : "",
            role: currentUser.role,
            password: currentUser.secret || undefined,
            generateOneTimePasswordAndSendEmail: currentUser.generateOneTimePasswordAndSendEmail || undefined,
            disabled: currentUser.disabled,
            operator: roleShouldHaveOneOperator() ? currentUser.operatorId : undefined,
            operators: roleShouldHaveMultipleOperators() ? currentUser.operators : roleShouldHaveOneOperator() ? currentUser.operatorId ? [currentUser.operatorId] : [] : [],
            countryId: !currentUser.countryId ? null : Number(currentUser.countryId),
            cityId: !currentUser.cityId ? null : Number(currentUser.cityId)
        };
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    // TODO: The last addition "strong enough" is too fuzzy. Consider changing that to be more exact
    const localizedPasswordRequirementString = t("Please enter a password of at least 7 characters long and strong enough");

    function validate() {
        if (currentUser.id.trim() === "") {
            return t("Please specify a user ID");
        } else if (currentUser.newUser && !currentUser.secret && !currentUser.generateOneTimePasswordAndSendEmail) {
            return t("Please enter a password or let system system send password change email");
        } else if (currentUser.secret) {
            if (currentUser.secret.length >= 128) {
                return t("Please enter a password shorter than 128 characters");
            }
            if (currentUser.secret.length < 7 && currentUser.role === "admin") {
                return localizedPasswordRequirementString;
            }
        } else if (!validateEmail(currentUser.id.trim())) {
            return t("User ID must be an email address");
        } else if (canCreateUser() && currentUser.role === "") {
            return t("Please specify a role");
        } else if (canCreateUser() && isCurrentUserOfficial() && !currentUser.cityId) {
            return t("Please specify a city for the official");
        }
        // Validate if operator is provided when it must be
        if (canCreateUser()) {
            if (roleShouldHaveMultipleOperators()) {
                if (currentUser.operators.length === 0) {
                    return t("Please specify at least one operator for this user");
                }
            } else if (roleShouldHaveOneOperator()) {
                if (!currentUser.operatorId) {
                    return t("Please specify an operator name");
                }
            }
        }
        return "";
    }

    function isCurrentUserOfficial() {
        return currentUser && currentUser.role === 'official';
    }

    function isCurrentUserRd() {
        return currentUser && currentUser.role === 'rd';
    }

    function isCurrentUserAdmin() {
        return currentUser && currentUser.role === 'admin';
    }

    function isCurrentUserInstaller() {
        return currentUser && (currentUser.role === 'installer' || currentUser.role === 'installerManager');
    }

    function isCurrentUserRegionalEngineer() {
        return currentUser && currentUser.role === 'regionalEngineer';
    }

    function canEditUser(user) {
        if (props.loggedInUser === user.id) {
            return true;
        }
        if (user.groupIds) {
            if (user.groupIds.find(e => e === "role_admin")) {
                return false;
            }
        }
        return true;
    }

    function roleShouldHaveOneOperator() {
        return (isCurrentUserAdmin() || isCurrentUserRd() || isCurrentUserInstaller() || isCurrentUserOfficial() || isCurrentUserRegionalEngineer()) ? false : true;
    }

    function roleShouldHaveMultipleOperators() {
        return isCurrentUserRegionalEngineer();
    }

    function shouldShowDisableAccountOption() {
        if (typeof currentUser === 'undefined') {
            return isAdmin;
        }
        return isAdmin && (props.loggedInUser !== currentUser.id);
    }

    const tableHeaders: EntityTableHeader[] = [
        { localizedText: t('User ID'), name: 'id', sortable: true },
    ];
    if (isAdmin && showDisabled) {
        tableHeaders.push({ localizedText: t('Account Disabled'), name: 'disabled', type: 'boolean' as const });
    }
    tableHeaders.push({ localizedText: t('Edit'), type: 'edit' as const });

    return (
        <EntityManagement<number | string>
            groups={props.groups}
            filterSelections={props.filterSelections}

            restPath={restPath}
            fetchData={fetchData}

            editor={{
                edit: startEditing,
                getSanitizedValues: getSanitizedEditingValues,
                set: changeValue,
                validate: validate,
                entityName: 'currentUser'
            }}
            errorCodeToMessage={(err => err && err.response && err.response.data && err.response.data.passwordStrenghtValidation ? localizedPasswordRequirementString : undefined)}

            texts={{
                localizedAddNewText: (canCreateUser()) ? t('Add new user') : undefined,
                confirmDelete: t("Are you sure you want to delete user?"),
                errors: {
                    generic: t("User couldn't be saved! Please try again or contact administrator!"),
                    alreadyExists: t("A user with that name already exists"),
                    deleteInUse: t("User is in use and cannot be deleted"),
                    deleteGeneric: t("User couldn't be deleted! Please try again or contact administrator!")
                }
            }}

            additionalTopContent={isAdmin &&
                <div>
                    <input
                        id="showDisabledCheckbox"
                        type="checkbox"
                        checked={showDisabled}
                        onChange={newValue => setShowDisabled(newValue.target.checked)}
                    />
                    &nbsp;
                    <label htmlFor="showDisabledCheckbox">{t('Show Disabled Users')}</label>
                </div>
            }

            parseId={id => id}
            stripStringId={id => ''}

            entities={{ users: users.filter(u => showDisabled || !u.disabled), currentUser: currentUser, operators: operators, roles: roles, countries: countries, cities: cities }}
            entityName='users'

            tableHeaders={tableHeaders}

            isEntityEditable={canEditUser}
            formFields={[
                { label: t('User email'), name: 'id', placeholder: t('Enter user email address'), maxLength: 255, readOnly: "readOnlyOnEdit", type: 'email' },
                { label: t('User full name'), name: 'description', placeholder: t('Enter user full name'), maxLength: 255, autofocusOnEdit: true },
                { label: t('User password'), name: 'secret', type: 'string', placeholder: t('Enter new password') }, // Note: Not limiting the password length here because in case of copy-pasting the password to the field user will not notice it's chopped
                { label: t('Send password change email'), name: 'generateOneTimePasswordAndSendEmail', type: 'checkbox', visible: canCreateUser() },
                { label: t('User role'), name: 'role', readOnly: canCreateUser() ? undefined : "readOnly", allowedValues: { entityName: 'roles', name: 'name', } },
                { label: t('City for Official'), name: 'cityId', readOnly: canCreateUser() ? undefined : "readOnly", allowedValues: { entityName: 'cities', name: 'name', }, visible: isCurrentUserOfficial() },
                { label: t('Operator'), name: 'operatorId', readOnly: canCreateUser() ? undefined : "readOnly",
                    allowedValues: { entityName: 'operators', name: 'name', preSelectOnlyOption: true },
                    visible: shouldShowOperators() && !roleShouldHaveMultipleOperators() },
                { label: t('Operators'), name: 'operators', readOnly: canCreateUser() ? undefined : "readOnly",
                    allowedValues: { entityName: 'operators', name: 'name', preSelectOnlyOption: true, multi: true },
                    visible: roleShouldHaveMultipleOperators() && canCreateUser() },
                { label: t('Disable user account'), name: 'disabled', type: 'checkbox',
                    visible: shouldShowDisableAccountOption() },
            ]}
        />
    );
}
export default UserManagement;
