import moment from 'moment';

enum LogLevels {
    'error' = 1,
    'warn' = 2,
    'info' = 3,
    'debug' = 4,
}

let logLevel = LogLevels.warn;
const logLevelFromUrlParameter = LogLevels[getUrlParameterByName('logLevel') as string];
if (logLevelFromUrlParameter) {
    logLevel = logLevelFromUrlParameter;
}

export function setLogLevelFromServer(level: string) {
    if (!logLevelFromUrlParameter) {
        const newLogLevel = LogLevels[level];
        if (newLogLevel) {
            logLevel = newLogLevel;
        }
    }
}

function log(level: LogLevels, ...args: unknown[]) {
    if (logLevel < level) {
        return;
    }
    const argsWithTsAndLevel = ([ moment().format('HH:mm:ss:SSS'), LogLevels[level.toString()].toUpperCase() ] as unknown[]).concat(args);
    // tslint:disable-next-line: no-console
    console.log(...argsWithTsAndLevel);
}

export const err = (...args) => log(LogLevels.error, ...args);
export const warn = (...args) => log(LogLevels.warn, ...args);
export const info = (...args) => log(LogLevels.info, ...args);
export const debug = (...args) => log(LogLevels.debug, ...args);

export function getUrlParameterByName(name: string) {
    const windowLocation = window.location;
    if (!windowLocation || !windowLocation.search) {
        return undefined;
    }
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(windowLocation.search);
    let retVal;
    if (results !== null) {
        retVal = decodeURIComponent(results[1].replace(/\+/g, " "));
    }
    return retVal;
}
