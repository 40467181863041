import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import * as logger from "../utilities/logger";
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { FilterSelections } from '../reducers/filterSelections';
import VehicleStatus from './VehicleStatus';
import ScaleSelectButtons, { ScaleOptions, generateTimestamps, formatTimestamps } from './ScaleSelectButtons';
import { round, authenticatedFilteredAxiosGet, constructCancelObject, groupBy, getSectionBackgroundColor } from '../utilities/generic';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import SectionPanel from './SectionPanel';
import moment from 'moment';

function FleetStatusSection(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    authState: AuthState,
    filterSelections: FilterSelections,
    vehicleCount: number | undefined,
    noxReduction: {
        startTimestamp: moment.Moment | null;
        fromPpm: number | null;
        kg: number | null;
        noxOut?: number;
        noxIn?: number;
        runningTime: number | null;
      } | null | undefined
}>) {

    const { t } = useTranslation();

    const [state, setState] = useState(
        {
            labels: [] as unknown[],
            datasets: [] as Chart.ChartDataSets[],
            scale: 'day' as ScaleOptions,
            loading: false,
            error: false
        }
    );

    useEffect(
        () => {
            getUtilityIntervals(state.scale);
        },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            props.startDate,
            props.endDate,
            props.authState.isAuthenticated,
        ])
    );

    const [cancelObject] = useState(constructCancelObject());

    const datasetDef = [
        {
            order: 2,
            type: 'bar',
            label: t('In Use'),
            data: [] as Array<number | undefined>,
            yAxisID: 'persentage',
            backgroundColor: '#4F75BD',
        },
        {
            order: 3,
            label: t('Idle'),
            type: 'bar',
            data: [] as Array<number | undefined>,
            yAxisID: 'persentage',
            backgroundColor: '#E57B3E',
        },
        {
            order: 1,
            type: 'line',
            fill: false,
            lineTension: 0.5,
            pointHoverBorderWidth: 2,
            pointRadius: 10,
            pointHitRadius: 10,
            label: t('Have Faults'),
            data: [] as Array<number | undefined>,
            yAxisID: 'vehicleCount',
            backgroundColor: '#EAE70080',
            borderColor: '#EAE700',
        },
        {
            order: 0,
            type: 'line',
            fill: false,
            lineTension: 0.5,
            pointHoverBorderWidth: 2,
            pointRadius: 10,
            pointHitRadius: 10,
            label: t('Tank Empty'),
            data: [] as Array<number | undefined>,
            yAxisID: 'vehicleCount',
            backgroundColor: '#0000FF80',
            borderColor: '#0000FF80',
        },
    ];

    async function getUtilityIntervals(scale: ScaleOptions) {
        if (props.startDate && props.endDate) {
            try {
                const startTimestamps = generateTimestamps({ startDate: props.startDate, endDate: props.endDate, scale });
                datasetDef[0].data = [];
                datasetDef[1].data = [];
                datasetDef[2].data = [];
                datasetDef[3].data = [];
                setState({labels: formatTimestamps({ startTimestamps, scale }),
                          datasets: datasetDef,
                          scale: 'day', loading: true, error: false});
                const startDate = props.startDate.toISOString();
                const end = props.endDate.toISOString();
                const utilities = await authenticatedFilteredAxiosGet(`/v10/utility/${startDate}/${end}/${scale}`, props.authState.isAuthenticated, props.filterSelections, cancelObject);
                const utilityDataGroupedByTime = groupBy<{ inUse: number, vehicleWithIssueCount: string, vehicleWithTankEmptyCount: string }>(utilities.data, 'startTimestamp');
                const series = startTimestamps.map(ts => ts.toISOString()).map(isoDateStr => utilityDataGroupedByTime[isoDateStr] ? utilityDataGroupedByTime[isoDateStr][0] : undefined);
                datasetDef[0].data = series.map(item => typeof item !== 'undefined' ? round(item.inUse * 100.0, 2) : 0);
                datasetDef[1].data = series.map(item => typeof item !== 'undefined' ? round(100.0 - item.inUse * 100.0, 2) : 100);
                datasetDef[2].data = series.map(item => typeof item !== 'undefined' ? parseInt(item.vehicleWithIssueCount) : 0);
                datasetDef[3].data = series.map(item => typeof item !== 'undefined' ? parseInt(item.vehicleWithTankEmptyCount) : 0);
                setState({
                    labels: formatTimestamps({ startTimestamps, scale }),
                    datasets: datasetDef,
                    scale: scale,
                    loading: false,
                    error: false
                });
            } catch (err) {
                if (!err.isCancel) {
                    logger.err(err.message || err);
                    setState({labels: [], datasets: [], scale: 'day', loading: false, error: true});
                }
            }
        }
    }

    const [vehicleStatusLoadStatus, setVehicleStatusLoadStatus] = useState(
        {
            loading: false,
            error: false
        }
    );

    function isBusy() {
        return (state.loading || vehicleStatusLoadStatus.loading) && !inError();
    }

    function inError() {
        return state.error || vehicleStatusLoadStatus.error;
    }

    function getSectionState() {
        if (inError()) {
            return "error";
        } else if (isBusy()) {
            return "busy";
        } else {
            return "ok";
        }
    }

    return (
        <div className="box" style={{ height: '100%', position: 'relative' }}>
            <div className="media-content">
                <div className="content">
                    <div className="columns is-multiline">
                        <div className="column is-12 is-paddingless is-centered" style={{ marginBottom: '10px' }}>
                            <VehicleStatus
                                startDate={props.startDate}
                                endDate={props.endDate}
                                authState={props.authState}
                                filterSelections={props.filterSelections}
                                vehicleCount={props.vehicleCount}
                                noxReduction={props.noxReduction}
                                onLoadStatusChange={setVehicleStatusLoadStatus}
                            />
                            <div className="column is-mobile is-centered">
                                { !inError() &&
                                    <ScaleSelectButtons
                                        selectedScale={state.scale}
                                        disabledButtons={isBusy()}
                                        onScaleSelect={getUtilityIntervals}
                                    />
                                }
                            </div>
                        </div>
                        <div className="column is-12" style={{ backgroundColor: getSectionBackgroundColor(inError(), isBusy()) }}>
                            <SectionPanel
                                state={getSectionState()}
                                child={
                                    <Bar
                                        data={{
                                            datasets: state.datasets
                                        }}
                                        options={{
                                            // tslint:disable-next-line: no-any
                                            responsive: true,
                                            tooltips: {
                                            mode: 'label'
                                            },
                                            scales: {
                                                xAxes: [{
                                                    stacked: true,
                                                    labels: state.labels,
                                                    // tslint:disable-next-line: no-any
                                                } as any],
                                                yAxes: [
                                                    {
                                                        stacked: true,
                                                        ticks: {
                                                            beginAtZero: true,
                                                            min: 0,
                                                            max: 100,
                                                            precision: 0,
                                                            // tslint:disable-next-line: no-any
                                                        } as any,
                                                        type: 'linear',
                                                        id: 'persentage',
                                                        scaleLabel: {
                                                            display: true,
                                                            labelString: '%'
                                                        }
                                                    },
                                                    {
                                                        stacked: false,
                                                        ticks: {
                                                            beginAtZero: true,
                                                            precision: 0,
                                                            // tslint:disable-next-line: no-any
                                                        } as any,
                                                        type: 'linear',
                                                        id: 'vehicleCount',
                                                        position: 'right',
                                                        scaleLabel: {
                                                            display: true,
                                                            labelString: t('Vehicle Count')
                                                        }
                                                    },
                                                ]
                                            },
                                            legend: {
                                                reverse: true,
                                            }
                                        }}
                                    />
                                }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FleetStatusSection;
