import React, { useState, useMemo, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Groups } from '../reducers/authenticated/authenticatedReducer';
import moment from 'moment';
import pencilEdit from '../resources/pencil.svg';
import trashDelete from '../resources/trash.svg';
import plusAddNew from '../resources/add-circle.svg';
import { formatDate } from '../utilities/generic';
import { Entities } from './entityForm';

export interface EntityTableHeader {
    type?: 'boolean' | 'edit' | 'delete' | 'date';
    localizedText: string;
    name?: string;
    sortable?: boolean;
}

type SortDirection = "ascending" | "descending" | undefined;

interface SortConfig {
    key: string;
    direction: SortDirection;
}

function useSortableData<T>(items: T[]) {
    const [sortConfig, setSortConfig] = useState<SortConfig | undefined>();
    const sortedItems = useMemo(() => {
        const sortableItems = [...items];
        if (sortConfig) {
            sortableItems.sort(function (a, b) {
                const aValue = a && a[sortConfig.key];
                const bValue = b && b[sortConfig.key];
                if (typeof aValue === 'string' && typeof bValue  === 'string') {
                    return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
                } else {
                    return aValue - bValue;
                }
            });

            if (sortConfig.direction === "ascending") {
                sortableItems.reverse();
            }
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key: string) => {
        let newConfig: SortConfig | undefined;
        if (sortConfig && sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                newConfig = {
                    key,
                    direction: "descending"
                };
            } else if (sortConfig.direction === "descending") {
                newConfig = undefined;
            }
        } else {
            newConfig = {
                key,
                direction: "ascending"
            };
        }
        setSortConfig(newConfig);
    };

    return { items: sortedItems, requestSort, sortConfig };
}

function EntityTable<IdType extends (string | number)>(props: Readonly<{
    groups: Groups,
    onAddNew: (() => void) | undefined,
    onEdit: (id: string) => void,
    onDelete: ((id: string, name: string | undefined) => void) | undefined,
    localizedAddNewText: string | undefined,
    additionalTopContent: JSX.Element | undefined,
    headers: EntityTableHeader[],
    entities: Entities<IdType>,
    entityName: string,
    isEntityEditable: ((entity: unknown) => boolean) | undefined,
    isEntityDeletable: ((entity: unknown) => boolean) | undefined,
    // allow a limit to prevent listing of entities to blow up
    limitLongList?: number,
}>) {

    const { t } = useTranslation();
    const [showAllEntries, setShowAllEntries] = useState(false);

    // Note: The following Array.isArray wouldn't be needed if the "current entity" wouldn't be in the same data structure
    // with the entity lists:
    const entityToBeSorted = props.entities[props.entityName];
    const { items, requestSort, sortConfig } = useSortableData(Array.isArray(entityToBeSorted) ? entityToBeSorted : []);

    function entityIsEditable(entity: unknown) {
        return (props.isEntityEditable) ? props.isEntityEditable(entity) : true;
    }

    function entityIsDeletable(entity: unknown) {
        return (props.isEntityDeletable) ? props.isEntityDeletable(entity) : true;
    }

    function getSortDir(name: string | undefined) {
        if (!sortConfig) {
            return;
        }
        if (sortConfig.key === name) {
            return sortConfig.direction;
        }
        return undefined;
    }

    const leftMiddleStyle: CSSProperties = { textAlign: 'left', verticalAlign: 'middle' };
    const centerMiddleStyle: CSSProperties = { textAlign: 'center', verticalAlign: 'middle' };
    const headerTrColor: CSSProperties = { backgroundColor: '#EBEEF7' };

    return (
        <>
            {props.onAddNew && props.localizedAddNewText &&
                <button className="addNew" onClick={props.onAddNew}>
                    {props.localizedAddNewText}
                    <span>
                        &nbsp;<img className="icon is-small" src={plusAddNew} alt={props.localizedAddNewText} />
                    </span>
                </button>
            }
            {props.additionalTopContent ? <>
                <div>&nbsp;</div>
                {props.additionalTopContent}
            </> : undefined}
            <div>&nbsp;</div>
            <div className={"stickyEntityTableHeader" + props.entityName}>
                <table className={"table is-hoverable is-fullwidth is-narrow  is-bordered " + props.entityName} style={{tableLayout: 'fixed', width: '100%', wordBreak: 'break-word'}}>
                    <thead>
                        <tr style={headerTrColor} >
                        {props.headers.map((header, index) => {
                                return (
                                    header.sortable ?
                                        <th key={index} style={leftMiddleStyle}>
                                            <button type="button" onClick={() => header.name && requestSort(header.name)} className={getSortDir(header.name)}>
                                                {header.localizedText}
                                            </button>
                                        </th> :
                                        <th style={ (header.type === 'edit' || header.type === 'delete') ? centerMiddleStyle : leftMiddleStyle} key={index}> {header.localizedText}</th>
                                );
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (
                                typeof props.limitLongList === 'undefined' || items.length <= props.limitLongList || showAllEntries === true ?
                                items :
                                items.slice(0, props.limitLongList)
                            ).map((entity) => {
                                return (
                                    <tr key={entity.id} style={{wordWrap: 'break-word'}}>
                                        {props.headers.map((header, index) => {
                                            const entityidStr = entity.id.toString();
                                            return (
                                                <React.Fragment key={entityidStr + "," + index}>
                                                    {header.type === "boolean" && header.name &&
                                                        <td
                                                            id={entityidStr}
                                                        > {entity[header.name] ? "True" : "False"}
                                                        </td>
                                                    }
                                                    {header.type === "edit" &&
                                                        <td style={centerMiddleStyle}>
                                                            {entityIsEditable(entity) &&
                                                                <button className="islink" id={entityidStr} onClick={(e) => props.onEdit && props.onEdit(entityidStr)}>
                                                                    <div className="tooltip">
                                                                        <img id={entityidStr} className="icon is-small" src={pencilEdit} alt={t('Edit')} />
                                                                        <span id={entityidStr} className="tooltiptext">
                                                                            {t('Edit')}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            }
                                                        </td>
                                                    }
                                                    {header.type === "delete" &&
                                                        <td style={centerMiddleStyle}>
                                                            {entityIsDeletable(entity) &&
                                                                <button className="islink" id={entityidStr} onClick={(e) => props.onDelete && props.onDelete(entityidStr, entity.name)}>
                                                                    <div className="tooltip">
                                                                        <img className="icon is-small" src={trashDelete} alt={t('Delete')} />
                                                                        <span className="tooltiptext">
                                                                            {t('Delete')}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            }
                                                        </td>
                                                    }
                                                    {(header.type === "date" && header.name) &&
                                                        <td>{entity[header.name] ? formatDate(moment(entity[header.name]), "date") : ''}</td>
                                                    }
                                                    {(!header.type && header.name) &&
                                                        <td>{entity[header.name]}</td>
                                                    }
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        }
                        {typeof props.limitLongList !== 'undefined' && items.length > props.limitLongList && showAllEntries === false &&
                            <tr>
                                <td colSpan={12}>
                                    <span>
                                        <button className="button is-small is-rounded" onClick={() => setShowAllEntries(true)}>
                                            {t('Show all entries...')}
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default EntityTable;
