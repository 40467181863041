import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionLoadingIndicator from './SectionLoadingIndicator';

export type StateState = "busy" | "error" | "ok";

function SectionPanel(props: Readonly<{
    child: JSX.Element,
    state: StateState,
}>) {
    const { t } = useTranslation();

    function getChildComponent() {
        return (<>
                    {props.state === 'busy' && <SectionLoadingIndicator/>}
                    {props.child}
                </>);
    }

    return (props.state !== 'error') ?
            getChildComponent()
        :
            (<div style={{backgroundColor: "#e09090",
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'center',
                          justifyContent: 'center'}}>
                {t('The server is very busy, please submit again the filters or reload the page')}
            </div>);
}

export default SectionPanel;
