import * as logger from "../utilities/logger";

let scriptLoaded = false;
let eppservice;
let eppEventListener;
let map;
let mapDiv;
let mapInitDoneCb;

const eppMapScriptId = 'eppMapScript';
export const eppVehicleTargetPrefix = 'v-';

export interface Alarm {
    ruleStateOwnerProcessorId: string;
    eventId: number;
    time: string | number;
    sensorDataTime: string | number | undefined;
    alarmText: string | undefined;
    ackUserId?: string;
    ackTime?: string;
    sourceId?: string;
    vehicleId: number;
    vehicleDescription: string | undefined;
    operatorName: string | undefined;
    manufacturerName: string | undefined;
    modelName: string | undefined;
}

export default function (options: Readonly<{
    eppMapScriptUrl: string,
}>) {
    const api = {
        init: (doneCb: () => void, notificationHandler: () => void) => {
            logger.debug('epp-client init');
            const initialize = () => {
                // tslint:disable-next-line: no-any
                eppservice = new (window as any).eppmap.EppServiceClient();
                if (eppservice.joinUpdateClientNotifications) {
                    eppservice.joinUpdateClientNotifications(notificationHandler);
                }
                eppservice.connectService(options.eppMapScriptUrl);
                eppEventListener = eppservice.getEventListener();
                logger.debug('epp-client initialize done');
                doneCb();
            };
            const existingScript = document.getElementById(eppMapScriptId);
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = options.eppMapScriptUrl;
                script.id = eppMapScriptId;
                document.body.appendChild(script);
                script.addEventListener('load', () => {
                    scriptLoaded = true;
                    initialize();
                });
            } else if (scriptLoaded) {
                initialize();
            }
        },
        getMap: (doneCb: (map, mapDiv: HTMLDivElement) => void) => {
            logger.debug('getMap eppservice:', !!eppservice);
            const createMapIfNotDone = () => {
                logger.debug('getMap createMapIfNotDone:', !!eppservice, !!map);
                if (!map) {
                    mapInitDoneCb = doneCb;
                    const initingMapDiv = document.createElement('div');
                    initingMapDiv.setAttribute("style", "min-height: 60vh;");
                    // tslint:disable-next-line: no-any
                    map = new (window as any).eppmap.EppMap(eppservice);
                    map.initMap({
                        mapElement: initingMapDiv,
                        // verboseMarkerPopUp: true,
                    }, () => {
                        /*map.on('historyUpdateRunning', function (running) {
                            logger.debug('historyUpdateRunning:', running);
                            * TODO:
                            if (running) {
                                props.increaseLoaderCount();
                            } else {
                                props.decreaseLoaderCount();
                            }*
                        });*/
                        mapDiv = initingMapDiv;
                        mapInitDoneCb(map, mapDiv);
                    });
                } else if (map) {
                    if (!mapDiv) {
                        mapInitDoneCb = doneCb;
                    } else {
                        doneCb(map, mapDiv);
                    }
                }
            };
            if (eppservice) {
                createMapIfNotDone();
            } else {
                const existingScript = document.getElementById(eppMapScriptId);
                if (existingScript && !scriptLoaded) {
                    existingScript.addEventListener('load', () => {
                        createMapIfNotDone();
                    });
                }
            }
        },
        destroyMap: () => {
            logger.debug('Map destroy');
            if (map) {
                map.destroy();
            }
            map = undefined;
            mapDiv = undefined;
        },
        listenAlarm(processorId: string, alarmHandler: (alarm: Alarm) => void, ackHandler: (ackEvent: {eventId: number, ackTime: number, ackUserId: string}) => void) {
            if (eppEventListener) {
                eppEventListener.joinGeneratedEvents(
                    {
                        processorId
                    },
                    (event) => {
                        /*{
                            "time": 1579790714348, "eventTypeId": "pcp", "processorId": "testRule1",
                            "context": {
                                "targetEvent": {
                                    "targetId": "9353738081331660", "propertyTypeId": "sensorData",
                                    "value": {
                                        "def_level_pct": 70, "nox_out": 206.20000000000005, "o2_out": 17.730788, "backpressure": 13.200000000000001,
                                        "timestamp": 1579673938, "temp_in": 232, "speed_from_can": 39, "def_level_state": 0,
                                        "ignition": true, "temp_def": 12, "battery_voltage": 27.200000000000003, "distance": 11.331, "doser_absolute_pressure": 0.039, "pump_state": 1,
                                        "nox_in": 210.60000000000002, "edr": 0, "maf": 106, "o2_in": 17.852092000000003, "speed": 42
                                    },
                                    "time": 1579790714257, "eventId": 376893, "previousEventId": 376892
                                },
                                "lock": 1, "lockOwner": "testRule1", "newStateValueStateType": "target",
                                "vehicleDetails": {
                                    "vehicle_id": 528,
                                    "vehicle_name": "LT32",
                                    "operator_id": 8,
                                    "operator_name": "Metroline",
                                    "body_manufacturer_id": 4,
                                    "body_manufacturer_name": "Wrightbus",
                                    "body_model_id": 14,
                                    "body_model_name": "NRM"
                                }
                            },
                            "notify": true, "notifyMessage": "Backpressure high", "eventId": 376894, "indexByValue": "1"
                        };
                        logger.debug('generatedEvents:', event);*/
                        try {
                            if (event.context &&
                                event.context.targetEvent
                            ) {
                                if (event.context.vehicleDetails &&
                                    event.context.vehicleDetails.vehicle_id
                                ) {
                                    alarmHandler({
                                        ruleStateOwnerProcessorId: event.context.lockOwner || event.processorId,
                                        eventId: event.eventId,
                                        time: event.context.targetEvent.time,
                                        sensorDataTime: event.context.targetEvent.time,
                                        sourceId: event.context.vehicleDetails.source_id,
                                        vehicleId: event.context.vehicleDetails.vehicle_id,
                                        vehicleDescription: event.context.vehicleDetails.vehicle_name,
                                        operatorName: event.context.vehicleDetails.operator_name,
                                        manufacturerName: event.context.vehicleDetails.body_manufacturer_name,
                                        modelName: event.context.vehicleDetails.body_model_name,
                                        alarmText: event.notifyMessage || `(Alarm message missing for rule ${event.processorId})`,
                                    });
                                } else {
                                    logger.info('listenAlarm: alarm event does not have have vechileDetails, skipping:', event);
                                }
                            } else {
                                logger.warn('listenAlarm: malformed alarm event:', event);
                            }
                        } catch (err) {
                            logger.err('listenAlarm: error:', err.message || err);
                        }
                    },
                    (err: Error | undefined, subscriptionKeys?: string | string[]) => {
                        if (err) {
                            logger.err('joinGeneratedEvents error:', err, subscriptionKeys);
                        }
                    }
                );
                eppEventListener.joinEventNotificationAcks(
                    undefined,
                    (event) => {
                        if (event.eventId && event.ackTime && event.ackUserId) {
                            ackHandler(event);
                        }
                    }
                );
            }
        },
        ackAlarm: (eventId: number, doneCb: (err: Error | undefined) => void) => {
            if (eppservice) {
                eppservice.ackNotification({ eventIds: [eventId] }, doneCb);
            }
        },
        resetRuleProcessorState: (ruleStateOwnerProcessorId: string, vehicleId: number, doneCb: (err: Error | undefined) => void) => {
            if (eppservice) {
                eppservice.resetProcessorLockStates({ processorId: ruleStateOwnerProcessorId, targetId: eppVehicleTargetPrefix + vehicleId }, doneCb);
            }
        },
        shutdown: () => {
            api.destroyMap();
            eppservice = undefined;
            eppEventListener = undefined;
        }
    };

    return api;
}
