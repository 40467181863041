import React from 'react';
import { useTranslation } from 'react-i18next';

export type AuthFailureReason = 'totpRequired' | 'newPasswordRequired';

function LoginForm(props: Readonly<{
    handleUserFormSubmit: (event) => void,
    handleFormChange: (event) => void,
    onCancel: () => void,
    state: {
        errorMessage: string,
        authFailureReason: AuthFailureReason | undefined,
    },
    enableSubmit: boolean,
}>) {

    const { t } = useTranslation();

    return (
        <div className="columns is-mobile is-centered">
            <div className="column is-four-fifths">
                <form onSubmit={(event) => props.handleUserFormSubmit(event)}>
                    <div className="field">

                    </div>
                    { !props.state.authFailureReason &&
                    <>
                        <div className="field">
                            <input
                                name="email"
                                className="input is-medium"
                                type="email"
                                placeholder={t('email')}
                                required
                                onChange={props.handleFormChange}
                            />
                        </div>
                        <div className="field">
                            <input
                                name="password"
                                className="input is-medium"
                                type="password"
                                placeholder={t('password')}
                                required
                                onChange={props.handleFormChange}
                            />
                        </div>
                    </>
                    }
                    { props.state.authFailureReason === 'totpRequired' &&
                    <>
                        <div className="field">
                            <input
                                name="totpToken"
                                className="input is-medium"
                                type="text"
                                maxLength={6}
                                placeholder={t('code from authenticator app')}
                                required
                                onChange={props.handleFormChange}
                            />
                        </div>
                    </>
                    }
                    { props.state.authFailureReason === 'newPasswordRequired' &&
                    <>
                        <div className="field">
                            <input
                                name="newPassword"
                                className="input is-medium"
                                type="password"
                                placeholder={t('new password')}
                                required
                                onChange={props.handleFormChange}
                            />
                        </div>
                        <div className="field">
                            <input
                                name="newPasswordConfirm"
                                className="input is-medium"
                                type="password"
                                placeholder={t('confirm new password')}
                                required
                                onChange={props.handleFormChange}
                            />
                        </div>
                    </>
                    }
                    {props.state.errorMessage &&
                        <div className="field">
                            <article className="message is-danger">
                                <div className="message-body">
                                    <strong>{props.state.errorMessage}</strong>
                                </div>
                            </article>
                        </div>
                    }
                    <input
                        type="submit"
                        id="login"
                        className="button is-large is-rounded"
                        disabled={!props.enableSubmit}
                        value={t('Login') as string}
                        style={{ backgroundColor: '#015B9F', color: 'white' }}
                    />
                    { props.state.authFailureReason &&
                        <button
                            className="button is-large is-rounded"
                            type="button"
                            onClick={props.onCancel}
                            style={{ backgroundColor: '#f4c326', color: 'white' }}
                        >{t('Cancel')}
                        </button>
                    }
                    <div className="columns">
                        <div className="column is-centered"></div>

                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginForm;
