import * as actionTypes from '../Actions/Actions';

export interface VehicleAndSourceId {
    id: number;
}

// FIXME: need to rename those as Id's (operatorId, countryId, etc) for native to disambiguate from entities
export interface FilterSelections {
    operators?: Operator[];
    country?: number;
    city?: number;
    site?: number;
    bodyManufacturer?: number;
    bodyModel?: number;
    engineManufacturer?: number;
    engineModel?: number;
    vehicles: VehicleAndSourceId[];
    fixedVehiclesSelection?: VehicleAndSourceId[];
}

export const initialState: FilterSelections = {
    vehicles: [],
};

export type FilterSelectionActions = {
    type: typeof actionTypes.SET_FILTERS_SELECTED_VALUES,
    countryId?: number | null,
    cityId?: number | null,
    operatorId?: number | null,
    operators?: Operator[];
    siteId?: number | null,
    bodyManufacturerId?: number | null,
    bodyModelId?: number | null,
    engineManufacturerId?: number | null,
    engineModelId?: number | null,
    vehicles: VehicleAndSourceId[],
} | {
    type: typeof actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION,
    fixedVehiclesSelection?: VehicleAndSourceId[],
}
;

function resolveValue<T>(currentValue: T | undefined, newValue?: T | null | null): T | undefined {
    if (typeof newValue === 'undefined') {
        return currentValue;
    } else {
        return (newValue === null) ? undefined : newValue;
    }
}

export function reducer(state: FilterSelections, action: FilterSelectionActions): FilterSelections {
    let newState: FilterSelections;
    switch (action.type) {
        case actionTypes.SET_FILTERS_SELECTED_VALUES:
            // use null to clear the current value, undefined to preserve it
            newState = { ...state };
            newState.country = resolveValue(newState.country, action.countryId);
            newState.city = resolveValue(newState.city, action.cityId);
            newState.operators = resolveValue(newState.operators, action.operators);
            newState.site = resolveValue(newState.site, action.siteId);
            newState.bodyManufacturer = resolveValue(newState.bodyManufacturer, action.bodyManufacturerId);
            newState.bodyModel = resolveValue(newState.bodyModel, action.bodyModelId);
            newState.engineManufacturer = resolveValue(newState.engineManufacturer, action.engineManufacturerId);
            newState.engineModel = resolveValue(newState.engineModel, action.engineModelId);
            newState.vehicles = action.vehicles;
            return newState;
        case actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION:
            newState = { ...state };
            newState.fixedVehiclesSelection = action.fixedVehiclesSelection;
            return newState;
    }
}

// FIXME: this are the rest return types, they should be declared in server side and shared somehow
// instead of being declared / defined in client side
export interface Entity {
    id: number;
    name: string;
}

export interface City extends Entity {
    country_id: number;
    country_name: string;
}

export interface Operator extends Entity {
    country_id: number;
    country_name: string;
    city_id: number;
    city_name: string;
}

export interface Site extends Entity {
    country_name: string;
    city_id: number;
    city_name: string;
    operator_id: number;
    operator_name: string;
}

export interface BodyModel extends Entity {
    body_manufacturer_id: number;
    body_manufacturer_name: string;
}

export interface EngineModel extends Entity {
    engine_manufacturer_id: number;
    engine_manufacturer_name: string;
}

export interface Vehicle {
    id: number;
    description: string;
    reg_number: string | null;
    country_id: number;
    city_id: number;
    operator_id: number | null;
    operator_name: string | null;
    site_id: number | null;
    body_manufacturer_id: number | null;
    body_manufacturer_name: string | null;
    body_model_id: number | null;
    body_model_name: string | null;
    engine_manufacturer_id: number | null;
    engine_manufacturer_name: string | null;
    engine_model_id: number | null;
    engine_model_name: string | null;

    gateway_id: string | null;
    emission_class_name: string | null;
    authority_visibility: string | null;
    production_date: string | null;
    vehicle_kms: number | null;
    emission_class_id: number | null;
    vin: string | null;
}
// end of FIXME
