import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import RedGreenIndicator from './RedGreenIndicator';
import { useTranslation } from 'react-i18next';
import { round } from '../utilities/generic';

interface Vehicle {
    vehicle_id: number;
    description: string;
    reg_number: string;
    body_manufacturer_name: string;
    name: string;
    nox_out: number | null;
    backpressure: number | null;

    dayAverages?: {
        noxEmission: number;
        adblueConsumption: number;
        tankEmpty: number | null;
    };
    tenMinutesAverage?: {
        noxOut: number;
        noxReduction: number;
    };
}

function OfficialTable(props: RouteComponentProps & {
    vehicles: Vehicle[],
    onVehicleSelect: (vehicleId: number) => void,
}) {

    const { t } = useTranslation();

    return (
        <>
            <table className="table is-hoverable is-fullwidth is-narrow is-bordered">
                <thead>
                    <tr>
                        <th>{t('Vehicle fleet number')}</th>
                        <th>{t('Body')}</th>
                        <th>{t('Bus Operator')}</th>
                        <th>24h {t('average NOx Emissions')} (g/km)</th>
                        <th>{t('24h Average Adblue Consumption')} (l/km)</th>
                        <th>{t('Live 10 Min Average NOx Reduction')} (%)</th>
                        <th>{t('Live 10 min Average Tailpipe NOx')} (ppm)</th>
                        <th>{t('Urea Level')}</th>
                        <th>{t('Tailpipe NOx below')} 100 ppm</th>
                        <th>DPF {t('Backpressure below')} 20 KPa</th>
                    </tr>
                </thead>
                <tbody>
                    {props.vehicles.length > 0 &&
                        props.vehicles.map((vehicle, i) => {
                            return (
                                <tr
                                    key={i}
                                    onClick={() => props.onVehicleSelect(vehicle.vehicle_id)}
                                    style={{cursor: 'pointer'}}
                                >
                                    <td>{vehicle.description}</td>
                                    <td>{vehicle.body_manufacturer_name}</td>
                                    <td>{vehicle.name}</td>
                                    <td>{vehicle.dayAverages && vehicle.dayAverages.noxEmission ?
                                        round(vehicle.dayAverages.noxEmission, 2) :
                                        'N/A'}</td>
                                    <td>{vehicle.dayAverages && vehicle.dayAverages.adblueConsumption ?
                                        round(vehicle.dayAverages.adblueConsumption, 5) :
                                        'N/A'}</td>
                                    <td>{vehicle.tenMinutesAverage && vehicle.tenMinutesAverage.noxReduction ?
                                        round(vehicle.tenMinutesAverage.noxReduction, 1) :
                                        "N/A"}</td>
                                    <td>{vehicle.tenMinutesAverage && vehicle.tenMinutesAverage.noxOut ?
                                        round(vehicle.tenMinutesAverage.noxOut, 1) :
                                        "N/A"}</td>
                                    <td><RedGreenIndicator showGreen={vehicle.dayAverages && vehicle.dayAverages.tankEmpty !== null ?
                                        !vehicle.dayAverages.tankEmpty :
                                        undefined} /></td>
                                    <td><RedGreenIndicator showGreen={vehicle.nox_out !== null ? vehicle.nox_out < 100 : undefined} /></td>
                                    <td><RedGreenIndicator showGreen={vehicle.backpressure !== null ? vehicle.backpressure < 200 : undefined} /></td>
                                </tr>
                            );
                        }
                        )
                    }
                </tbody>
            </table>
        </>
    );
}

export default withRouter(OfficialTable);
