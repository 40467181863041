// Authentication actions
export const LOGOUT = 'LOGOUT';
export const SET_CLIENTCONFIG_AND_LOGIN = 'SET_CLIENTCONFIG_AND_LOGIN';

// FIXME: constants needs some namespace, this are global variables, something like filterSelection.SET_SELECTED_VALUES
export const SET_FILTERS_SELECTED_VALUES = 'SET_FILTERS_SELECTED_VALUES';
export const SET_FILTERS_FIXED_VEHICLES_SELECTION = 'SET_FILTERS_FIXED_VEHICLES_SELECTION';

// Timerange actions
export const SET_TIMERANGE = 'SET_TIMERANGE';
// TimerangeScale actions
export const SET_TIMERANGESCALE = 'SET_TIMERANGESCALE';
// Sites Actions
export const SET_SITES = 'SET_SITES';
// Distance actions
export const SET_DISTANCES = 'SET_DISTANCES';
// Fleet utility
export const SET_FLEET_UTILITY = 'SET_FLEET_UTILITY';
// Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
