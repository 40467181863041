import React, {useContext, useState, useEffect, CSSProperties} from 'react';
import { useTranslation } from 'react-i18next';
import Select from "react-dropdown-select";
import Axios from 'axios';

import RouteContext from '../contexts/RouteContext';
import * as actionTypes from '../Actions/Actions';
import TimeRange from './TimeRange';
import { deepClone } from '../utilities/generic';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { BodyModel, City, EngineModel, Entity, FilterSelections, Operator, Site, Vehicle } from '../reducers/filterSelections';
import { writeItemToLocalStorage, getItemsFromLocalStorage, removeItemFromLocalStorage } from '../utilities/local-storage-helper';

/**
 * This is used to detect changes in filter selections by using useEffect
 */
export function constructArrayOfFilterSelections(filterSelections: FilterSelections, includeFixedVehiclesSelection: boolean = true): unknown[] {
    return ([
        filterSelections.operators,
        filterSelections.country,
        filterSelections.city,
        filterSelections.vehicles,
        filterSelections.site,
        filterSelections.bodyManufacturer,
        filterSelections.bodyModel,
        filterSelections.engineManufacturer,
        filterSelections.engineModel,
    ] as unknown[]).concat(includeFixedVehiclesSelection ? [
        filterSelections.fixedVehiclesSelection,
    ] : []);
}

const menuControlStyle: CSSProperties = { minWidth: "50px", display: "inline-block", marginRight: 10 };

// TODO: unsure, interface vs inlined definition here
const FilterDefinitions = {
    country: {restPath: '/v10/countries', restResultField: undefined, label: 'Countries', filters: 'country_id', multiSelect: false, dataType: {} as Entity},
    city: {restPath: '/v10/cities?sort=name', restResultField: undefined, label: 'Cities', filters: 'city_id', multiSelect: false, dataType: {} as City},
    operators: {restPath: '/v10/operators?', restResultField: (result) => result.data.operators as Operator[], label: 'Operators', filters: 'operator_id', multiSelect: true, dataType: {} as Operator},
    site: {restPath: '/v10/sites?sort=sites.name', restResultField: undefined, label: 'Sites', filters: 'site_id', multiSelect: false, dataType: {} as Site},
    bodyManufacturer: {restPath: '/v10/bodyManufacturer', restResultField: undefined, label: 'Chassis', filters: 'body_manufacturer_id', multiSelect: false, dataType: {} as Entity},
    bodyModel: {restPath: '/v10/bodyModel?sort=name', restResultField: undefined, label: 'Bodies', filters: 'body_model_id', multiSelect: false, dataType: {} as BodyModel},
    engineManufacturer: {restPath: '/v10/engineManufacturer', restResultField: undefined, label: 'Engine Manufacturers', filters: 'engine_manufacturer_id', multiSelect: false, dataType: {} as Entity},
    engineModel: {restPath: '/v10/engineModel?sort=name', restResultField: undefined, label: 'Engine Models', filters: 'engine_model_id', multiSelect: false, dataType: {} as EngineModel},
    vehicle: {restPath: '/v10/vehicle?type=filterSelectors', restResultField: (result) => result.data.result.vehicles as Site[], label: 'Vehicles', filters: '', multiSelect: true, displayField: 'description', dataType: {} as Vehicle},
};

// FIXME: this should be built from filters definition, not duplicating the list
type FilterEntityType = typeof FilterDefinitions.country.dataType
                        | typeof FilterDefinitions.city.dataType
                        | typeof FilterDefinitions.operators.dataType
                        | typeof FilterDefinitions.site.dataType
                        | typeof FilterDefinitions.bodyManufacturer.dataType
                        | typeof FilterDefinitions.bodyModel.dataType
                        | typeof FilterDefinitions.engineManufacturer.dataType
                        | typeof FilterDefinitions.engineModel.dataType
                        | typeof FilterDefinitions.vehicle.dataType;

// FIXME: this should be built from filters definition, not duplicating the list
// some kind of ordered map that is friendly with typescript types
// maybe add an order number in the filter definitions above
const FiltersOrder = [FilterDefinitions.country,
                      FilterDefinitions.city,
                      FilterDefinitions.operators,
                      FilterDefinitions.site,
                      FilterDefinitions.bodyManufacturer,
                      FilterDefinitions.bodyModel,
                      FilterDefinitions.engineManufacturer,
                      FilterDefinitions.engineModel,
                      FilterDefinitions.vehicle];

function getFilterName(filterDef) {
    // TODO: there may be a better way to do this
    for (const key of Object.keys(FilterDefinitions)) {
        if (FilterDefinitions[key].restPath === filterDef.restPath) {
            return key;
        }
    }
    return "country"; // Fallback, should not be possible to reach here
}

// FIXME: there are overlapped definitions in filterSelections, for example FilterSelections there is a superset of FiltersSelectionState
// and ambiguity between the internal states (given by dropdown states) and external (component state view from outside)
const FiltersSelectionState = {
    country: undefined as number | undefined,
    city: undefined as number | undefined,
    operators: Array<Operator>(),
    site: undefined as number | undefined,
    bodyManufacturer: undefined as number | undefined,
    bodyModel: undefined as number | undefined,
    engineManufacturer: undefined as number | undefined,
    engineModel: undefined as number | undefined,
    vehicles: Array< {id: number} >(),
};

const FiltersValues = {
    country: Array< typeof FilterDefinitions.country.dataType >(),
    city: Array< typeof FilterDefinitions.city.dataType >(),
    operators: Array< typeof FilterDefinitions.operators.dataType >(),
    site: Array< typeof FilterDefinitions.site.dataType >(),
    bodyManufacturer: Array< typeof FilterDefinitions.bodyManufacturer.dataType >(),
    bodyModel: Array< typeof FilterDefinitions.bodyModel.dataType >(),
    engineManufacturer: Array< typeof FilterDefinitions.engineManufacturer.dataType >(),
    engineModel: Array< typeof FilterDefinitions.engineModel.dataType >(),
    vehicle: Array< typeof FilterDefinitions.vehicle.dataType >(),
};

function FilterSelectors(props: Readonly<{
    authState: AuthState,
    filterSelections: FilterSelections,
    startDate: Date | undefined,
    endDate: Date | undefined,
    alarmStartDate: Date | undefined,
    alarmEndDate: Date | undefined,
    showHomeViewTimeRangeSelector: boolean,
    visible: boolean,
    reloadId: number,
}>) {

    const { t } = useTranslation();

    const context = useContext(RouteContext);

    // To be used exclusively by inner components! submittedSelection stores last values but not including dates
    const [dirty, setDirty] = useState(false);

    // Cached values from db to reduce number of rest calls, TODO: this should be done by http catching instead
    const [cachedValues, setCachedValues] = useState(deepClone(FiltersValues));
    // FIXME: values and selected values would have to be atomic, so in the same useState
    // all currently selectable values in filters
    const [values, setValues] = useState(deepClone(FiltersValues));
    // selected values in each filter
    const [selectedValues, setSelectedValues] = useState(deepClone(FiltersValues));
    // submitted values, used to check if current selection has changed
    // FIXME: selectedValues and submittedValues should use same interface
    const [submittedValues, setSubmittedValues] = useState(deepClone(FiltersSelectionState));

    async function loadEntities() {
        return await Promise.all(
            FiltersOrder.map(filterDef => Axios.get(filterDef.restPath))
        ).then(results => {
            const loadedValues = deepClone(FiltersValues);
            results.forEach((result, i) => {
                const extractionFn = FiltersOrder[i].restResultField; // linter glitches out, needs this hack
                loadedValues[getFilterName(FiltersOrder[i])] = (extractionFn) ? extractionFn(result) : result.data.result;
            });
            const inUseValues = deepClone(loadedValues);
            // Next line can be commented out when testing crud changes for values not in use, like adding / removing / modifying unused values
            // and see them changing in filters
            recomputeSelectableOptions(-1, inUseValues, deepClone(FiltersValues));
            setCachedValues(inUseValues);
            return inUseValues;
        });
    }

    function filter(options: FilterEntityType[], aFilter: FilterEntityType, filters: string) {
        return options.filter(option => option[filters] === aFilter.id);
    }

    function filterMulti(options: FilterEntityType[], aFilter: FilterEntityType[], filters: string) {
        return options.filter(option => aFilter.filter(filterValue => filterValue.id === option[filters]).length > 0);
    }

    function removeOptionsNotPresent(options: FilterEntityType[], selectableOptions: FilterEntityType[], filters: string) {
        if (filters !== '') {
            /*
             * Ideally should be done the other way by mainly traversing the longer array then searching in the
             * smaller array, but it has no significant impact in the data volume we handle
             */
            return options.filter(option => selectableOptions.find(selectable => selectable[filters] === option.id));
        } else {
            return options;
        }
    }

    /*
     * need to rethink this one
     *  selectedValues after afterFilterIndex need to be cleared
     *  values after afterFilterIndex need to be reevaluated
     *  candidate values (allResultingOptions) needs to be refiltered from beginning
     * Note: a side effect of reducing values to those in use by vehicles
     *   will reduce selectable options of parent/child relationships, for example will list only
     *   cities of such country, or only engine models of such engine manufacturer
     *   because of that, there's no need to directly filter selectable values based on their parent entity
     */
    function recomputeSelectableOptions(afterFilterIndex: number, selectableValues: typeof FiltersValues, usingSelectedValues: typeof FiltersValues) {
        const lastFilterName = getFilterName(FiltersOrder[FiltersOrder.length - 1]);
        let allResultingOptions = [...selectableValues[lastFilterName]];
        for (let i = 0; i < FiltersOrder.length - 1; i++) {
            const thisFilter = FiltersOrder[i];
            const thisFilterName = getFilterName(thisFilter);
            if ( i > afterFilterIndex) {
                selectableValues[thisFilterName] = removeOptionsNotPresent(selectableValues[thisFilterName], allResultingOptions, thisFilter.filters);
            }
            if (thisFilter.multiSelect) {
                if (usingSelectedValues[thisFilterName].length > 0) {
                    allResultingOptions = filterMulti(allResultingOptions, usingSelectedValues[thisFilterName], thisFilter.filters);
                }
            } else {
                for (const value of usingSelectedValues[thisFilterName]) {
                    allResultingOptions = filter(allResultingOptions, value, thisFilter.filters);
                }
            }
        }
        selectableValues[lastFilterName] = allResultingOptions;
    }

    function handleSelectionChange(selectedItems: FilterEntityType[], entityName: string) {
        setSelectedValues(currentSelectedValues => {
            const newSelections = {...currentSelectedValues};
            newSelections[entityName] = selectedItems;
            const filterIndex = FiltersOrder.indexOf(FilterDefinitions[entityName]);
            for (let i = filterIndex + 1; i < FiltersOrder.length; ++i) {
                newSelections[getFilterName(FiltersOrder[i])] = [];
            }
            setValues(currentValues => {
                const newValues = {...currentValues};
                for (let i = filterIndex + 1; i < FiltersOrder.length; ++i) {
                    const filterName = getFilterName(FiltersOrder[i]);
                    newValues[filterName] = [...cachedValues[filterName]];
                }
                if (filterIndex < FiltersOrder.length - 1) {
                    recomputeSelectableOptions(filterIndex, newValues, newSelections);
                }
                return newValues;
            });
            return newSelections;
        });
    }

    function getSelectedID(filterSelection: FilterEntityType[]): number | undefined {
        return filterSelection.length === 1 ? filterSelection[0].id : undefined;
    }

    function getCurrentSelections(filterState: typeof FiltersValues): typeof FiltersSelectionState {
        return {
            country: getSelectedID(filterState.country),
            city: getSelectedID(filterState.city),
            operators: deepClone(filterState.operators),
            site: getSelectedID(filterState.site),
            bodyManufacturer: getSelectedID(filterState.bodyManufacturer),
            bodyModel: getSelectedID(filterState.bodyModel),
            engineManufacturer: getSelectedID(filterState.engineManufacturer),
            engineModel: getSelectedID(filterState.engineModel),
            vehicles: deepClone(filterState.vehicle.map(vehicle => ({
                id: vehicle.id,
            }))),
        };
    }

    function nullIfUndefined(value?: number): number | null {
        return (typeof value === 'undefined') ? null : value;
    }

    function submitFilterValues(valuesToSubmit: typeof FiltersSelectionState) {
        // Store preselections to browser's local storage in submit - Remove from local storage, if selected value does not exist in state or valuesToSubmit is empty:
        // Country
        if (valuesToSubmit.country && selectedValues.country.length > 0) {
            writeItemToLocalStorage('selectedCountry', JSON.stringify(valuesToSubmit.country));
        } else if (!valuesToSubmit.country || !selectedValues) {
            removeItemFromLocalStorage('selectedCountry');
        }
        // City
        if (valuesToSubmit.city && selectedValues.city.length > 0) {
            writeItemToLocalStorage('selectedCity', JSON.stringify(valuesToSubmit.city));
        } else if (!valuesToSubmit.city || !selectedValues) {
            removeItemFromLocalStorage('selectedCity');
        }
        // Operators (multiselect)
        if (valuesToSubmit.operators.length > 0 && selectedValues.operators.length > 0) {
            writeItemToLocalStorage('selectedOperators', JSON.stringify(valuesToSubmit.operators.map(anOperator => anOperator.id)));
        } else if (valuesToSubmit.operators.length === 0 || !selectedValues) {
            removeItemFromLocalStorage('selectedOperators');
        }
        // Site
        if (valuesToSubmit.site && selectedValues.site.length > 0) {
            writeItemToLocalStorage('selectedSite', JSON.stringify(valuesToSubmit.site));
        } else if (!valuesToSubmit.site || !selectedValues) {
            removeItemFromLocalStorage('selectedSite');
        }

        if (context) {
        context.filterSelectionsDispatch({type: actionTypes.SET_FILTERS_SELECTED_VALUES,
                                            countryId: nullIfUndefined(valuesToSubmit.country),
                                            cityId: nullIfUndefined(valuesToSubmit.city),
                                            operators: valuesToSubmit.operators,
                                            siteId: nullIfUndefined(valuesToSubmit.site),
                                            bodyManufacturerId: nullIfUndefined(valuesToSubmit.bodyManufacturer),
                                            bodyModelId: nullIfUndefined(valuesToSubmit.bodyModel),
                                            engineManufacturerId: nullIfUndefined(valuesToSubmit.engineManufacturer),
                                            engineModelId: nullIfUndefined(valuesToSubmit.engineModel),
                                            vehicles: valuesToSubmit.vehicles});
        }
    }

    function submitFilterSelections(startDate: Date, endDate: Date | undefined) {
        submitFilterValues(getCurrentSelections(selectedValues));
        if (context) {
            if (props.showHomeViewTimeRangeSelector) {
                context.timerangeDispatch({type: actionTypes.SET_TIMERANGE,
                                           startDate: startDate,
                                           endDate: endDate});
            } else {
                context.alarmsTimerangeDispatch({type: actionTypes.SET_TIMERANGE,
                                                 startDate: startDate,
                                                 endDate: endDate});
            }
        }
        setDirty(false);
    }

    async function reloadFilters() {
        // When reloading the filters there are few things to consider:
        // 1. values may had changed, for example a country name -> needs to reflect new value
        // 2. values may had been deleted -> reset filters after such filter as it is not selectable anymore
        const loadedValues = await loadEntities();
        // FIXME: list of selectable values needs to be rebuilt, for example if a vehicle was deleted it changes everything
        // setValues(deepClone(loadedValues));
        const valuesToUse = deepClone(loadedValues);
        let selectionsForced = false;
        let preSelectedValuesExist = false;
        let newSelectedValuesCopy: typeof FiltersValues = {...FiltersValues};
        let preSelectedValues: typeof FiltersValues = {...FiltersValues};
        setSelectedValues(currentlySelectedValues => {
            const newSelectedValues = {...currentlySelectedValues};
            let resetSelection = false;
            for (const aFilter of FiltersOrder) {
                const filterName = getFilterName(aFilter);
                const selected = currentlySelectedValues[filterName];
                newSelectedValues[filterName] = loadedValues[filterName].filter(entity => selected.findIndex(selectedEntity => selectedEntity.id === entity.id) !== -1);
                // Some values that are currently selected may had been deleted, reset filters in that case
                if (resetSelection || newSelectedValues[filterName].length !== currentlySelectedValues[filterName].length) {
                    resetSelection = true;
                    newSelectedValues[filterName] = [];
                }
            }
            // FIXME: hack for single operator bounded roles to work with home view, some components assume is always selected
            if (isSingleOperatorRole()) {
                const forcedOperatorId = props.authState.groups.operators[0];
                if (newSelectedValues.operators.length !== 1 || newSelectedValues.operators[0].id !== forcedOperatorId) {
                    const forcedOperator = loadedValues.operators.find(op => op.id === forcedOperatorId);
                    newSelectedValues.operators = (forcedOperator) ? [forcedOperator] : [];
                    selectionsForced = true;
                }
            }
            recomputeSelectableOptions(-1, valuesToUse, newSelectedValues);
            setValues(valuesToUse);
            if (selectionsForced || resetSelection) {
                newSelectedValuesCopy = {...newSelectedValues};
            }

            // read possible preselected values from browser's local storage.
            const [ preSelectedCountry, preSelectedCity, preSelectedOperators, preSelectedSite ] =
                 getItemsFromLocalStorage(['selectedCountry', 'selectedCity', 'selectedOperators', 'selectedSite']);
            const country = preSelectedCountry ? valuesToUse.country.find(aCountry => aCountry.id === parseInt(preSelectedCountry)) : undefined;
            const city = preSelectedCity ? valuesToUse.city.find(aCity => aCity.id === parseInt(preSelectedCity)) : undefined;
            const operators = preSelectedOperators ?
                (JSON.parse(preSelectedOperators) as number[])
                    .map(operatorId => valuesToUse.operators.find(ops => ops.id === operatorId))
                    .filter((anOperator): anOperator is Operator => typeof anOperator !== 'undefined') :
                undefined;
            const site = preSelectedSite ? valuesToUse.site.find(aSite => aSite.id === parseInt(preSelectedSite)) : undefined;

            preSelectedValuesExist = typeof country !== 'undefined'
                || typeof city !== 'undefined'
                || typeof operators !== 'undefined'
                || typeof site !== 'undefined';

            if (preSelectedValuesExist) {
                preSelectedValues = {...newSelectedValues};
                if (country) {
                    preSelectedValues.country = country ? [country] : [];
                }
                if (city) {
                    preSelectedValues.city = city ? [city] : [];
                }
                if (operators) {
                    preSelectedValues.operators = operators;
                }
                if (site) {
                    preSelectedValues.site = site ? [site] : [];
                }
            }

            return newSelectedValues;
        });

        if (selectionsForced) {
            submitFilterValues(getCurrentSelections(newSelectedValuesCopy));
        }
        if (preSelectedValuesExist) {
            submitFilterValues(getCurrentSelections(preSelectedValues));
        }
    }

    useEffect (() => {
        reloadFilters();
    }, [
        props.authState?.groups,
        props.reloadId
    ]);

    useEffect (() => {
        /*
        FIXME: FilterSelections, separate selected values from the other stuff
        should be 1-1 assignable to selected values, submitted values
        */
        const fs = props.filterSelections;
        setSubmittedValues({country: fs.country,
                            city: fs.city,
                            operators: fs.operators ? fs.operators : [],
                            site: fs.site,
                            bodyManufacturer: fs.bodyManufacturer,
                            bodyModel: fs.bodyModel,
                            engineManufacturer: fs.engineManufacturer,
                            engineModel: fs.engineModel,
                            vehicles: fs.vehicles
        });
        setSelectedValues(getSelectedValuesFromProps());
    }, [
        props.filterSelections.operators,
        props.filterSelections.country,
        props.filterSelections.city,
        props.filterSelections.site,
        props.filterSelections.bodyManufacturer,
        props.filterSelections.bodyModel,
        props.filterSelections.engineManufacturer,
        props.filterSelections.engineModel,
        props.filterSelections.vehicles
    ]);

    function filterIsInFixedSelectionMode() {
        return (props.filterSelections.fixedVehiclesSelection?.length ?? 0) > 0;
    }

    // FIXME: this should be 1-1, there should not be a need for conversion
    function getSelectedValuesFromProps() {
        const country = cachedValues.country.find(aCountry => aCountry.id === props.filterSelections.country);
        const city = cachedValues.city.find(value => value.id === props.filterSelections.city);
        const operators: Operator[] = [];
        props.filterSelections.operators?.forEach(anOperator => {
            const op = cachedValues.operators.find(ops => ops.id === anOperator.id);
            if (op) {
                operators.push(op);
            }
        });
        const site = cachedValues.site.find(value => value.id === props.filterSelections.site);
        const bodyManufacturer = cachedValues.bodyManufacturer.find(value => value.id === props.filterSelections.bodyManufacturer);
        const bodyModel = cachedValues.bodyModel.find(value => value.id === props.filterSelections.bodyModel);
        const engineManufacturer = cachedValues.engineManufacturer.find(value => value.id === props.filterSelections.engineManufacturer);
        const engineModel = cachedValues.engineModel.find(value => value.id === props.filterSelections.engineModel);
        const vehicles: Vehicle[] = [];
        props.filterSelections.vehicles.forEach(aVehicle => {
            const v = cachedValues.vehicle.find(vehicle => vehicle.id === aVehicle.id);
            if (v) {
                vehicles.push(v);
            }
        });
        const selectionFromProps = {country: country ? [country] : [],
                                    city: city ? [city] : [],
                                    operators: operators,
                                    site: site ? [site] : [],
                                    bodyManufacturer: bodyManufacturer ? [bodyManufacturer] : [],
                                    bodyModel: bodyModel ? [bodyModel] : [],
                                    engineManufacturer: engineManufacturer ? [engineManufacturer] : [],
                                    engineModel: engineModel ? [engineModel] : [],
                                    vehicle: vehicles ? vehicles : []};
        return selectionFromProps;
    }

    function valuesChanged() {
        if (filterIsInFixedSelectionMode()) {
            return dirty;
        }
        const currentSelection = getCurrentSelections(selectedValues);
        // FIXME: do it programmatically, non multy compares value, multi compares arrays
        // at this moment, the logic is hardcoded for the specific filters but should
        // be dynamic coming from the filters definition
        if (currentSelection.country !== submittedValues.country
            || currentSelection.city !== submittedValues.city
            || currentSelection.operators.length !== submittedValues.operators.length
            || currentSelection.site !== submittedValues.site
            || currentSelection.bodyManufacturer !== submittedValues.bodyManufacturer
            || currentSelection.bodyModel !== submittedValues.bodyModel
            || currentSelection.engineManufacturer !== submittedValues.engineManufacturer
            || currentSelection.engineModel !== submittedValues.engineModel
            || currentSelection.vehicles.length !== submittedValues.vehicles.length
            || dirty
            ) {
                return true;
        }
        for (let i = 0; i < currentSelection.vehicles.length; ++i) {
            if (currentSelection.vehicles[i].id !== submittedValues.vehicles[i].id) {
                    return true;
            }
        }
        for (let i = 0; i < currentSelection.operators.length; ++i) {
            if (currentSelection.operators[i].id !== submittedValues.operators[i].id) {
                return true;
            }
        }
        return false;
    }

    function resetFilters() {
        // fixme not true -> we need to handle the dates too, we don't knwo if they changed and no way to get their values
        setDirty(false);
        // resets selectable values and submit an empty selection -> that takes care of setting the selected values
        setValues(cachedValues);
        // FIXME: pre select op for single operator bounded roles
        // need to be done with selection config! and values to be filtered each time we fix a selection!
        const emptyFilterSelection = deepClone(FiltersSelectionState);
        if (isSingleOperatorRole()) {
            const forcedOperatorId = props.authState.groups.operators[0];
            const forcedOperator = cachedValues.operators.find(op => op.id === forcedOperatorId);
            emptyFilterSelection.operators = (forcedOperator) ? [forcedOperator] : [];
        }
        submitFilterValues(emptyFilterSelection);
    }

    function contentRenderer({props: p, state: s}: {
        props: {
            options: unknown[]
        },
        state: {
            values: unknown[]
        }
    }) {
        return (
            <div>{ t('Selected') } {s?.values?.length} / {p?.options?.length}</div>
        );
    }

    function getBodyAndEngineAndEmissionClassDescription(vehicle) {
        return [
            vehicle.body_manufacturer_name,
            vehicle.body_model_name,
            vehicle.engine_manufacturer_name,
            vehicle.engine_model_name,
            vehicle.emission_class_name,
        ].filter(part => part).join(', ');
    }

    // FIXME: following functions are not the concern of filterselectors, should be refactored away
    function isSingleOperatorRole() {
        return props.authState.groups.expert || props.authState.groups.technician;
    }

    function shouldRenderFilter(aFilter) {
        // FIXME: hack, should be implemented properly
        const filterName = getFilterName(aFilter);
        if (props.authState.groups.regionalEngineer && ["country", "city"].indexOf(filterName) !== -1) {
            return false;
        } else if (["country", "city", "operators"].indexOf(filterName) !== -1 && isSingleOperatorRole()) {
            return false;
        } else {
            return true;
        }
    }
    // end of FIXME

    function createFilterUI(filterName: string) {
        const aFilter = FilterDefinitions[filterName];
        return <div key={'A' + filterName} style={menuControlStyle}>
                    <Select
                        options={values[filterName]}
                        values={selectedValues[filterName]}
                        multi={aFilter.multiSelect === true}
                        clearable={values[filterName].length > 0}
                        disabled={values[filterName].length === 0}
                        placeholder={t(aFilter.label)}
                        valueField="id"
                        labelField={aFilter.displayField ?? "name"}
                        searchBy={aFilter.displayField ?? "name"}
                        onChange={(theValues) => handleSelectionChange(theValues, filterName)}
                        contentRenderer={selectedValues[filterName].length > 3 ? contentRenderer : undefined}
                    />
               </div>;
    }

    function renderFiltersDropdowns() {
        return <>{FiltersOrder.map(aFilter =>
                        shouldRenderFilter(aFilter) && createFilterUI(getFilterName(aFilter)))}
               </>;
    }

    function renderUserSelectableFilters() {
        return (<>
                    {renderFiltersDropdowns()}
                    <button style={{ marginTop: 5 }} className="button is-small is-rounded is-pulled-right" onClick={(e) => {
                        e.preventDefault();
                        resetFilters();
                    }}>
                        {t('Reset filters')}
                    </button>
                </>);
    }

    function renderFixedVehicleSelections() {
        // silly linter doesn't see is not called if is undefined
        if (typeof props.filterSelections.fixedVehiclesSelection === 'undefined') {
            return (<></>);
        }
        return (<div style={{ padding: "5px 12px" }}>
                    {t('Selected vehicle')}:&nbsp;&nbsp;
                    <span style={{ fontWeight: 'bold' }}>
                        {props.filterSelections.fixedVehiclesSelection.map(explicitlySelectedVehicle => {
                            const vehicle = cachedValues.vehicle && cachedValues.vehicle.find(v => v.id === explicitlySelectedVehicle.id);
                            if (vehicle) {
                                const description = vehicle.description !== null ? vehicle.description : explicitlySelectedVehicle.id;
                                return description + ' - ' + getBodyAndEngineAndEmissionClassDescription(vehicle);
                            } else {
                                return '';
                            }
                        })}
                    </span>
                    &nbsp;&nbsp;
                    <button className="delete" aria-label="close" onClick={e => {
                        e.preventDefault();
                        if (context) {
                            context.filterSelectionsDispatch({
                                type: actionTypes.SET_FILTERS_FIXED_VEHICLES_SELECTION,
                                fixedVehiclesSelection: undefined,
                            });
                        }
                    }}></button>
                </div>);
    }

    return (
        <>
        { props.visible &&
            <div className="box">
                <div className="media-content">
                    <div className="content">
                        <form style={{ marginBottom: 5 }}>
                            <div className="is-clearfix">
                                { filterIsInFixedSelectionMode() ?
                                        renderFixedVehicleSelections()
                                    :
                                        renderUserSelectableFilters() }
                            </div>
                        </form>
                        { props.showHomeViewTimeRangeSelector ?
                            <TimeRange
                                startDate={props.startDate}
                                endDate={props.endDate}
                                submitHandler={submitFilterSelections}
                                setDirty={setDirty}
                                hourlyPrecision={true}/>
                        :
                            <TimeRange
                                startDate={props.alarmStartDate}
                                endDate={props.alarmEndDate}
                                submitHandler={submitFilterSelections}
                                setDirty={setDirty}
                                hourlyPrecision={false}/>
                        }
                        {valuesChanged() &&
                            <p style={{ color: 'red', marginTop: '5px' }}>{t('Filter values changed, please click "Submit" to update.')}</p>
                        }
                        {
                            !filterIsInFixedSelectionMode() && selectedValues.vehicle.length === 1 &&
                            <div className="has-text-centered" style={{ marginLeft: '250px' }}>
                                {t('Selected vehicle')}:&nbsp;&nbsp;
                                <span style={{ fontWeight: 'bold' }}>
                                {
                                    getBodyAndEngineAndEmissionClassDescription(selectedValues.vehicle[0])
                                }
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default FilterSelectors;
