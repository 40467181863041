import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getDateFormat } from '../utilities/generic';

export type ScaleOptions = 'day' | 'week' | 'month' | 'year';

export default function ScaleSelectButtons(props: Readonly<{
    selectedScale: ScaleOptions,
    disabledButtons: boolean,
    onScaleSelect: (newSelection: ScaleOptions) => void,
}>) {
    const { t } = useTranslation();

    return <>
        <button
            onClick={() => props.onScaleSelect('day')}
            className={props.selectedScale === "day" ? "button primary is-focused" : "button primary"}
            disabled={props.disabledButtons}
        >{t('Day')}</button>
        <button
            onClick={() => props.onScaleSelect('week')}
            className={props.selectedScale === "week" ? "button primary is-focused" : "button primary"}
            disabled={props.disabledButtons}
        >{t('Week')}</button>
        <button
            onClick={() => props.onScaleSelect('month')}
            className={props.selectedScale === "month" ? "button primary is-focused" : "button primary"}
            disabled={props.disabledButtons}
        >{t('Month')}</button>
        <button
            onClick={() => props.onScaleSelect('year')}
            className={props.selectedScale === "year" ? "button primary is-focused" : "button primary"}
            disabled={props.disabledButtons}
        >{t('Year')}</button>
    </>;
}

export function getScaleDateFormatType(scale: ScaleOptions) {
    let formatType: string;
    if (scale === "day") {
        formatType = getDateFormat("date");
    } else if (scale === "week") {
        formatType = "W";
    } else if (scale === "month") {
        formatType = "MMM";
    } else {
        formatType = "Y";
    }
    return formatType;
}

export function generateTimestamps({ startDate, endDate, scale }: { startDate: Date, endDate: Date, scale: ScaleOptions }) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const numberOfTimestamps = endDateMoment.diff(startDateMoment, scale) + 1;
    const momentForTimestampGeneration = startDateMoment.clone().utc().startOf(scale === 'week' ? 'isoWeek' : scale).subtract(1, scale);
    return Array.from({ length: numberOfTimestamps }, () => moment(momentForTimestampGeneration.add(1, scale)));
}

export function formatTimestamps({ startTimestamps, scale }: { startTimestamps: moment.Moment[], scale: ScaleOptions }) {
    const formatType = getScaleDateFormatType(scale);
    return startTimestamps.map(d => d.format(formatType));
}
