import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as logger from "../utilities/logger";
import { authenticatedFilteredAxiosGet, constructCancelObject } from '../utilities/generic';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';
import { FilterSelections } from '../reducers/filterSelections';
import { constructArrayOfFilterSelections } from './FilterSelectors';

export default function VehicleStatus(props: Readonly<{
    startDate: Date | undefined,
    endDate: Date | undefined,
    authState: AuthState,
    filterSelections: FilterSelections,
    vehicleCount: number | undefined,
    noxReduction: {
        startTimestamp: moment.Moment | null;
        fromPpm: number | null;
        kg: number | null;
        noxOut?: number;
        noxIn?: number;
        runningTime: number | null;
    } | null | undefined,
    onLoadStatusChange: (status: {loading: boolean, error: boolean}) => void,
}>) {
    const { t } = useTranslation();

    useEffect(
        () => {
            getDistance();
        },
        constructArrayOfFilterSelections(props.filterSelections).concat([
            props.startDate,
            props.endDate,
            props.authState.isAuthenticated,
        ])
    );

    const [distanceState, setDistanceState] = useState(
        {
            meters: undefined as number | undefined,
        }
    );

    const [getDistanceCancelObject] = useState(constructCancelObject());
    async function getDistance() {
        if (props.startDate && props.endDate) {
            setDistanceState({ meters: undefined });
            props.onLoadStatusChange({ loading: true, error: false  });
            try {
                const startDate = props.startDate.toISOString();
                const end = props.endDate.toISOString();
                const results = await authenticatedFilteredAxiosGet(`/v10/distance/${startDate}/${end}`, props.authState.isAuthenticated, props.filterSelections, getDistanceCancelObject);
                setDistanceState({
                    meters: results.data && results.data.meters ? results.data.meters : undefined,
                });
                props.onLoadStatusChange({ loading: false, error: false  });
            } catch (err) {
                if (!err.isCancel) {
                    logger.err(err.message || err);
                    setDistanceState({ meters: undefined });
                    props.onLoadStatusChange({ loading: false, error: true  });
                }
            }
        }
    }

    const detailsStyle = { display: 'inline-block', marginRight: 15 };

    function formatRunningTime(runningTime: number) {
        const hours = Math.floor(runningTime / (60 * 60));
        const minutes = Math.round(runningTime % (60 * 60) / 60);
        return <>{hours}&nbsp;{t('h')} { minutes}&nbsp;{t('m')}</>;
    }

    return <>
        <strong>{t('Vehicle Status')}</strong>
        <div className="column is-12 is-paddingless is-centered">
            <div style={detailsStyle}>
                {t('Vehicles')}&nbsp;<b>{props.vehicleCount}</b>
            </div>
            <div style={detailsStyle}>
                {t('NOx Reduction')}&nbsp;<b>{props.noxReduction && props.noxReduction.kg ? Math.round(props.noxReduction.kg) : ''}&nbsp;kg</b>
            </div>
            <div style={detailsStyle}>
                {t('NOx Reduction')}&nbsp;<b>{props.noxReduction && props.noxReduction.fromPpm ? Math.round(props.noxReduction.fromPpm) : ''}&nbsp;%</b>
            </div>
            <br />
            <div style={detailsStyle}>
                {t('Kilometers')}&nbsp;<b>{distanceState.meters ? <>{Math.round(distanceState.meters / 1000)}&nbsp;km</> : ''}</b>
            </div>
            <div style={detailsStyle}>
                {t('Average Speed')}&nbsp;<b>{distanceState.meters && props.noxReduction && props.noxReduction.runningTime ? <>{Math.round(distanceState.meters / props.noxReduction.runningTime * 3.6)}&nbsp;km/h</> : ''}</b>
            </div>
            <div style={detailsStyle}>
                {t('Total Running Time')}&nbsp;<b>{props.noxReduction && props.noxReduction.runningTime ? formatRunningTime(props.noxReduction.runningTime) : ''}</b>
            </div>
        </div>
    </>;
}
