import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeViewSection from './HomeViewSection';
import axios from 'axios';
import { FilterSelections } from '../reducers/filterSelections';
import * as logger from "../utilities/logger";
import { getSectionBackgroundColor, authenticatedFilteredAxiosGet, constructCancelObject } from '../utilities/generic';
import { constructArrayOfFilterSelections } from './FilterSelectors';
import moment from 'moment';
import { AuthState } from '../reducers/authenticated/authenticatedReducer';

function DepotTable(props: Readonly<{
    startDate: Date,
    endDate: Date | undefined,
    authState: AuthState,
    filterSelections: FilterSelections,
}>) {

    const { t } = useTranslation();

    interface SiteSummary {
        vehicleCount: number;
        inUse: number;
        vehicleWithIssueCount: number;
        vehicleWithTankEmptyCount: number;
    }

    const [state, setState] = useState( {
        sites: [] as Array<{ id: number, name?: string }>,
        siteIdToNoxReductions: {} as { [ siteId: string ]: { noxPercentReductionFromPpm: number, }},
        siteIdToInUseAndVehicleCount: {} as { [ siteId: string ]: SiteSummary },
        loading: false,
        error: false
    });

    useEffect(
        () => {
            loadSites();
        },
        constructArrayOfFilterSelections(props.filterSelections)
    );

    const [getDepotNoxReductionCancelObject] = useState(constructCancelObject());
    const [getDepotUtilityCancelObject] = useState(constructCancelObject());

    async function loadSites() {
        setState({sites: [], siteIdToNoxReductions: {}, siteIdToInUseAndVehicleCount: {}, loading: true, error: false});
        const startDate = moment(props.startDate).toISOString();
        const endDate = moment(props.endDate).toISOString();
        try {
            const operatorIds = props.filterSelections.operators?.map(e => e.id);
            const [sitesResult,
                   reductionResult,
                   utilityReport] = await Promise.all([
                axios.get(`/v10/sites?operator_id=${operatorIds?.join(",") || ''}`),
                authenticatedFilteredAxiosGet(`/v10/reduction/${startDate}/${endDate}?groupBySiteId=true`, props.authState.isAuthenticated, props.filterSelections, getDepotNoxReductionCancelObject),
                authenticatedFilteredAxiosGet(`/v10/utility/${startDate}/${endDate}?groupBySiteId=true`, props.authState.isAuthenticated, props.filterSelections, getDepotUtilityCancelObject)
            ]);
            const siteIdToNoxReductions = {};
            if (Array.isArray(reductionResult.data)) {
                reductionResult.data.forEach(siteIdToNoxReduction => {
                  siteIdToNoxReductions[siteIdToNoxReduction.siteId] = siteIdToNoxReduction;
                });
            }
            const siteIdToInUseAndVehicleCount: { [siteId: string ]: SiteSummary } = {};
            if (Array.isArray(utilityReport.data)) {
                utilityReport.data.forEach(inUseAndVehicleCount => {
                    siteIdToInUseAndVehicleCount[inUseAndVehicleCount.siteId] = inUseAndVehicleCount;
                });
            }
            setState({sites: sitesResult.data.result.filter(s => !props.filterSelections.site || s.id === props.filterSelections.site), siteIdToNoxReductions, siteIdToInUseAndVehicleCount, loading: false, error: false});
        } catch (err) {
            if (!err.isCancel) {
                logger.err(err.message || err);
                setState({sites: [], siteIdToNoxReductions: {}, siteIdToInUseAndVehicleCount: {}, loading: false, error: true});
            }
        }
    }

    function isBusy() {
        return state.loading;
    }

    function inError() {
        return state.error;
    }

    return (
        <HomeViewSection
            child={<div className="div-table" style={{ backgroundColor: getSectionBackgroundColor(inError(), isBusy())}}>
                <div className="columns">
                    <div className="column is-fullwidth">
                        <strong>{t('Sites')}</strong>
                    </div>
                </div>
                <table className="table is-hoverable is-fullwidth is-narrow is-bordered">
                    <thead><tr>
                    <th>
                        <p>{t('Site')}</p>
                    </th>
                    <th>
                        <p>{t('Vehicles')}</p>
                    </th>
                    <th>
                        <p>{t('Reduction')} % (ppm)</p>
                    </th>
                    <th>
                        <p>{t('In Use')}</p>
                    </th>
                    <th>
                        <p>{t('Idle')}</p>
                    </th>
                    <th>
                        <p>{t('Have Faults')}</p>
                    </th>
                    <th>
                        <p>{t('Tank Empty')}</p>
                    </th>
                    </tr>
                    </thead>
                    <tbody>
                {state.sites.map((site, i) => {
                    return (
                        <tr key={i}>
                            <td>
                                <p>{site.name ? site.name : site.id}</p>
                            </td>
                            <td>
                                <p>{ state.siteIdToInUseAndVehicleCount[site.id] && state.siteIdToInUseAndVehicleCount[site.id].vehicleCount ?
                                    state.siteIdToInUseAndVehicleCount[site.id].vehicleCount : '' }</p>
                            </td>
                            <td>
                                <p>{state.siteIdToNoxReductions[site.id] ? Math.round(state.siteIdToNoxReductions[site.id].noxPercentReductionFromPpm) : ''}</p>
                            </td>
                            <td>
                                <p>{ state.siteIdToInUseAndVehicleCount[site.id] && state.siteIdToInUseAndVehicleCount[site.id].inUse ?
                                        Math.round(state.siteIdToInUseAndVehicleCount[site.id].inUse * 100) + ' %' : ''}</p>
                            </td>
                            <td>
                                <p>{ state.siteIdToInUseAndVehicleCount[site.id] && state.siteIdToInUseAndVehicleCount[site.id].inUse ?
                                            100 - Math.round(state.siteIdToInUseAndVehicleCount[site.id].inUse * 100) + ' %' : ''}</p>
                            </td>
                            <td>
                                <p>{ state.siteIdToInUseAndVehicleCount[site.id] && state.siteIdToInUseAndVehicleCount[site.id].vehicleWithIssueCount ?
                                        state.siteIdToInUseAndVehicleCount[site.id].vehicleWithIssueCount : '' }</p>
                            </td>
                            <td>
                                <p>{ state.siteIdToInUseAndVehicleCount[site.id] && state.siteIdToInUseAndVehicleCount[site.id].vehicleWithTankEmptyCount ?
                                        state.siteIdToInUseAndVehicleCount[site.id].vehicleWithTankEmptyCount : '' }</p>
                            </td>
                        </tr>
                    );
                })
                }
                </tbody>
                </table>
            </div>}
        />
    );
}

export default DepotTable;
