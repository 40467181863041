import React from "react";
import { useTranslation } from "react-i18next";
import trashDelete from '../resources/trash.svg';

function DeleteDialog(props: Readonly<{
    deleteConfirmText?: string | ((id: number) => boolean) | ((id: number) => Promise<boolean>) | undefined,
    toBeDeletedName?: string | undefined,
    onHandleDeleteConfirm: () => void,
    onHandleDeleteCancel: () => void,
    showDialog: boolean | undefined,
    error?: string | undefined,
}>) {

    const { t } = useTranslation();

    return (
        <>
        <div className={props.showDialog && props.showDialog ? "modal is-active" : "modal"}>
            <div className="modal-background"></div>
            <div className="modal-card" style={{ overflow: 'visible' }}>
                <header className="modal-card-head">
                    <div className="modal-card-title">{t("Delete")}</div>
                    <button className="delete" aria-label="close" onClick={props.onHandleDeleteCancel}></button>
                </header>
                <section className="modal-card-body">
                    <div className="level">
                        <div>
                            <img className="level-left icon is-medium" src={trashDelete} alt={t('Delete')} />
                            <div style={menuControlStyle}>
                                {!props.error
                                    ? <div>{props.deleteConfirmText}</div>
                                    : <div style={{color: 'red'}}>{props.error}</div>
                                }
                                <div><strong>{props.toBeDeletedName}</strong></div>
                            </div>
                        </div>
                    </div>
                </section>
               <footer className="modal-card-foot">
                    <button className="button is-focused" onClick={props.onHandleDeleteCancel}>{t('Cancel')}</button>
                    <button className={!props.error ? "button is-success" : "button is-danger"} onClick={props.onHandleDeleteConfirm}>{t('Delete')}</button>
               </footer>
            </div>
        </div>
        </>
    );
}

const menuControlStyle = { display: "inline-block", marginLeft: 20, marginRight: 10 };

export default DeleteDialog;
